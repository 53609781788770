import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.vcoHandler($event)
        }
      }
    },
    [
      _vm.open
        ? _c(
            VNavigationDrawer,
            {
              style: {
                height: "calc(100vh - 64px)",
                top: _vm.pageYOffset + "px"
              },
              attrs: {
                absolute: "",
                temporary: "",
                right: "",
                value: _vm.open,
                app: "",
                stateless: "",
                id: "bulkupdatepaneldrawer"
              }
            },
            [
              _c(
                VForm,
                { ref: "form", class: _vm.configClass },
                [
                  _c(
                    VLayout,
                    [
                      _vm.showModGroupConfig && _vm.showItemCards
                        ? _c(
                            VFlex,
                            { staticClass: "cardStyle", attrs: { xs6: "" } },
                            [
                              _c(
                                VContainer,
                                {
                                  staticClass: "pb-0 pr-0",
                                  attrs: { "grid-list-xl": "", fluid: "" }
                                },
                                [
                                  _c(
                                    VLayout,
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(VFlex, { attrs: { xs12: "" } }, [
                                        _c(
                                          "div",
                                          [
                                            _c("pagination", {
                                              attrs: {
                                                items: _vm.itemsforPreview,
                                                pageSize: 5
                                              },
                                              on: {
                                                changePage: _vm.onChangePage
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]),
                                      _c(
                                        VFlex,
                                        { attrs: { xs3: "" } },
                                        [
                                          _vm._l(_vm.pageOfItems, function(
                                            item
                                          ) {
                                            return _c("item-card", {
                                              key: item.id,
                                              attrs: { currentItem: item },
                                              on: {
                                                "update:currentItem": function(
                                                  $event
                                                ) {
                                                  item = $event
                                                },
                                                "update:current-item": function(
                                                  $event
                                                ) {
                                                  item = $event
                                                }
                                              }
                                            })
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VFlex,
                        {
                          staticStyle: { "max-width": "800px" },
                          attrs: { shrink: "" }
                        },
                        [
                          _vm.showItemConfigOnly
                            ? _c(
                                VContainer,
                                {
                                  staticClass: "pb-0",
                                  attrs: { "grid-list-xl": "", fluid: "" }
                                },
                                [
                                  _c(
                                    VLayout,
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(VFlex, { attrs: { xs12: "" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "H5-Primary-Left" },
                                          [_vm._v(_vm._s(_vm.updateMessage))]
                                        )
                                      ]),
                                      _c(
                                        VFlex,
                                        { attrs: { xs6: "" } },
                                        [
                                          _c(
                                            VCard,
                                            { attrs: { height: "200" } },
                                            [
                                              _c(
                                                VCardTitle,
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "H6-Primary-Left"
                                                    },
                                                    [_vm._v("Price")]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "v-card-content"
                                                },
                                                [
                                                  _c(
                                                    VLayout,
                                                    [
                                                      _c(
                                                        VFlex,
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "Update price for the selected items."
                                                            )
                                                          ]),
                                                          _vm.open
                                                            ? _c(
                                                                "v-currency-field",
                                                                {
                                                                  attrs: {
                                                                    rules:
                                                                      _vm.priceRules,
                                                                    placeholder:
                                                                      "Price ($)"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .bulkUpdateConfig
                                                                        .price,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.bulkUpdateConfig,
                                                                        "price",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "bulkUpdateConfig.price"
                                                                  }
                                                                }
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.isLocal
                                        ? _c(
                                            VFlex,
                                            { attrs: { xs6: "" } },
                                            [
                                              _c(
                                                VCard,
                                                { attrs: { height: "200" } },
                                                [
                                                  _c(
                                                    VCardTitle,
                                                    {
                                                      attrs: {
                                                        "primary-title": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "H6-Primary-Left"
                                                        },
                                                        [_vm._v("Stock")]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "v-card-content"
                                                    },
                                                    [
                                                      _c(
                                                        VLayout,
                                                        [
                                                          _c(
                                                            VFlex,
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "Set stock of selected items."
                                                                )
                                                              ]),
                                                              _c(
                                                                VRadioGroup,
                                                                {
                                                                  attrs: {
                                                                    row: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .bulkUpdateConfig
                                                                        .stock,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.bulkUpdateConfig,
                                                                        "stock",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "bulkUpdateConfig.stock"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    VRadio,
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "In Stock",
                                                                        value: true
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    VRadio,
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Out of Stock",
                                                                        value: false
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        VFlex,
                                        { attrs: { xs6: "" } },
                                        [
                                          _c(
                                            VCard,
                                            { attrs: { height: "200" } },
                                            [
                                              _c(
                                                VCardTitle,
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  !_vm.isLocal
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "H6-Primary-Left"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Enable/Disable in Local Menu\n                    "
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "H6-Primary-Left"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "In-App Visibilty"
                                                          )
                                                        ]
                                                      )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "v-card-content"
                                                },
                                                [
                                                  _c(
                                                    VLayout,
                                                    [
                                                      _c(
                                                        VFlex,
                                                        [
                                                          !_vm.isLocal
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "\n                          Set the selected items be Enabled/Disabled in local menu?\n                        "
                                                                )
                                                              ])
                                                            : _c("div", [
                                                                _vm._v(
                                                                  "Will the following items be visible in-app?"
                                                                )
                                                              ]),
                                                          _c(
                                                            VRadioGroup,
                                                            {
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .bulkUpdateConfig
                                                                    .appVisibility,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.bulkUpdateConfig,
                                                                    "appVisibility",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "bulkUpdateConfig.appVisibility"
                                                              }
                                                            },
                                                            [
                                                              _c(VRadio, {
                                                                attrs: {
                                                                  label:
                                                                    _vm.getLabelforItemEnabled,
                                                                  value: true
                                                                }
                                                              }),
                                                              _c(VRadio, {
                                                                attrs: {
                                                                  label:
                                                                    _vm.getLabelforItemDisabled,
                                                                  value: false
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      !_vm.isLocal
                                        ? _c(
                                            VFlex,
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                VCard,
                                                { attrs: { height: "200" } },
                                                [
                                                  _c(
                                                    VCardTitle,
                                                    {
                                                      attrs: {
                                                        "primary-title": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "H6-Primary-Left"
                                                        },
                                                        [_vm._v("Tax Tags")]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "v-card-content"
                                                    },
                                                    [
                                                      _c(
                                                        VLayout,
                                                        [
                                                          _c(
                                                            VFlex,
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "Apply tax tags to {# of} items"
                                                                )
                                                              ]),
                                                              _c(VSelect, {
                                                                attrs: {
                                                                  items:
                                                                    _vm.taxoptions,
                                                                  placeholder:
                                                                    "Tax Tags",
                                                                  multiple: "",
                                                                  clearable: "",
                                                                  chips: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .bulkUpdateConfig
                                                                      .taxTags,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.bulkUpdateConfig,
                                                                      "taxTags",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "bulkUpdateConfig.taxTags"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isVersion2
                                        ? _c(
                                            VFlex,
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                VCard,
                                                [
                                                  _c(
                                                    VCardTitle,
                                                    {
                                                      attrs: {
                                                        "primary-title": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "H6-Primary-Left"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Edit Mod Groups in Your Item Selection"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "v-card-content"
                                                    },
                                                    [
                                                      _c(
                                                        "loading-wrapper",
                                                        {
                                                          attrs: {
                                                            loading:
                                                              _vm.isLoadingModifierGroups
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            VLayout,
                                                            [
                                                              _vm
                                                                .existingModGroups
                                                                .length === 0
                                                                ? _c(VFlex, [
                                                                    _c("h2", [
                                                                      _vm._v(
                                                                        "No Mods Available in your selected items"
                                                                      )
                                                                    ])
                                                                  ])
                                                                : _vm._e(),
                                                              _vm
                                                                .existingModGroups
                                                                .length > 0
                                                                ? _c(VFlex, [
                                                                    _vm._v(
                                                                      "\n                          All edits below will be applied only to items where the modifier group\n                          exist.\n                        "
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            VLayout,
                                                            [
                                                              _c(
                                                                VFlex,
                                                                _vm._l(
                                                                  _vm.existingModGroups,
                                                                  function(
                                                                    modGroup,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "modifier-group",
                                                                      {
                                                                        key:
                                                                          modGroup.id +
                                                                          index,
                                                                        attrs: {
                                                                          group: modGroup,
                                                                          isAdd: false
                                                                        },
                                                                        on: {
                                                                          "update:group": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.existingModGroups.splice(
                                                                              index,
                                                                              1,
                                                                              $event
                                                                            )
                                                                          },
                                                                          "modifierGroup:delete": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.deleteModGroup(
                                                                              index,
                                                                              _vm.existingModGroups
                                                                            )
                                                                          },
                                                                          "modifierGroup:edit": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.editModGroup(
                                                                              index,
                                                                              _vm.existingModGroups
                                                                            )
                                                                          },
                                                                          validateform: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.$refs.form.validate()
                                                                          }
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "unique_name",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    VTextField,
                                                                                    {
                                                                                      attrs: {
                                                                                        label:
                                                                                          "Modifier Group:",
                                                                                        placeholder:
                                                                                          modGroup.unique_name,
                                                                                        disabled: true
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isVersion2 && !_vm.isLocal
                                        ? _c(
                                            VFlex,
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                VCard,
                                                [
                                                  _c(
                                                    VCardTitle,
                                                    {
                                                      attrs: {
                                                        "primary-title": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "H6-Primary-Left"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Add New Mod Groups to Your Items"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "v-card-content"
                                                    },
                                                    [
                                                      _c(
                                                        VLayout,
                                                        [
                                                          _c(VFlex, [
                                                            _vm._v(
                                                              "\n                        Add modifier groups to the selected items\n                      "
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        VLayout,
                                                        [
                                                          _c(
                                                            VFlex,
                                                            _vm._l(
                                                              _vm.addedModGroups,
                                                              function(
                                                                modGroup,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "modifier-group",
                                                                  {
                                                                    key:
                                                                      modGroup.id +
                                                                      index,
                                                                    attrs: {
                                                                      group: modGroup
                                                                    },
                                                                    on: {
                                                                      "update:group": function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.addedModGroups.splice(
                                                                          index,
                                                                          1,
                                                                          $event
                                                                        )
                                                                      },
                                                                      "modifierGroup:delete": function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteModGroup(
                                                                          index,
                                                                          _vm.addedModGroups
                                                                        )
                                                                      },
                                                                      "modifierGroup:edit": function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.editModGroup(
                                                                          index,
                                                                          _vm.addedModGroups
                                                                        )
                                                                      },
                                                                      validateform: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$refs.form.validate()
                                                                      }
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "unique_name",
                                                                          fn: function() {
                                                                            return [
                                                                              _c(
                                                                                VSelect,
                                                                                {
                                                                                  ref:
                                                                                    "uniqueName",
                                                                                  refInFor: true,
                                                                                  attrs: {
                                                                                    label:
                                                                                      "Modifier Group:",
                                                                                    disabled:
                                                                                      _vm.readOnlyMode,
                                                                                    items:
                                                                                      _vm.modGroupsAvailableToAdd,
                                                                                    "item-text":
                                                                                      "unique_name",
                                                                                    "item-value":
                                                                                      "id",
                                                                                    placeholder:
                                                                                      modGroup.unique_name
                                                                                  },
                                                                                  on: {
                                                                                    change: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.selectGlobalModForAdd(
                                                                                        index,
                                                                                        $event
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  nativeOn: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      $event.stopPropagation()
                                                                                    }
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          },
                                                                          proxy: true
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      !_vm.isLocal
                                                        ? _c(
                                                            VLayout,
                                                            [
                                                              _c(
                                                                VFlex,
                                                                {
                                                                  attrs: {
                                                                    shrink: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    VBtn,
                                                                    {
                                                                      staticClass:
                                                                        "ml-0 pl-0",
                                                                      attrs: {
                                                                        flat:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                        disabled:
                                                                          _vm
                                                                            .modGroupsAvailableToAdd
                                                                            .length ===
                                                                          0
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.addModifierGroup
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        "Add MODIFIER GROUP\n                        "
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showModGroupConfig
                            ? _c(
                                VContainer,
                                {
                                  staticClass: "pb-0 pl-0",
                                  attrs: { "grid-list-xl": "", fluid: "" }
                                },
                                [
                                  _c(
                                    VLayout,
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        "justify-end": ""
                                      }
                                    },
                                    [
                                      _c(
                                        VFlex,
                                        [
                                          _c("modifier-group-details", {
                                            attrs: {
                                              group: _vm.modifierGroupToEdit,
                                              isCard: false
                                            },
                                            on: {
                                              "update:group":
                                                _vm.updateEditedModGroup,
                                              validateform: function($event) {
                                                return _vm.$refs.form.validate()
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VFlex,
                                        { attrs: { shrink: "", xs8: "" } },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              staticClass: "clickable",
                                              attrs: {
                                                outline: "",
                                                flat: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: _vm.showHideItemCards
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.showHideItemCardButtonLabel
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VLayout,
                { attrs: { row: "", "justify-center": "" } },
                [
                  _vm.showItemConfigOnly
                    ? _c(
                        VFlex,
                        {
                          staticStyle: { position: "sticky", bottom: "0" },
                          attrs: { shrink: "" }
                        },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.saving,
                                disabled: !_vm.isBulkUpdateFormModified
                              },
                              on: { click: _vm.applyUpdate }
                            },
                            [_vm._v("Apply Changes")]
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "clickable",
                              attrs: { flat: "" },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showModGroupConfig
                    ? _c(
                        VFlex,
                        {
                          staticStyle: { position: "sticky", bottom: "0" },
                          attrs: { shrink: "" }
                        },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.saving,
                                disabled: !_vm.modifierModified
                              },
                              on: { click: _vm.preSaveModifierChanges }
                            },
                            [_vm._v("Save Modifiers Changes")]
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "clickable",
                              attrs: { flat: "" },
                              on: { click: _vm.cancelModifier }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }