import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VFlex, { attrs: { xs12: "" } }, [
    _c("div", [
      !_vm.editLabel
        ? _c(
            "span",
            { staticClass: "Body-1-Selected-On-Surface-High-Emphasis-Left" },
            [
              _vm._v(
                "\n      Modifier Group: " +
                  _vm._s(_vm.groupProxy.label.en) +
                  "\n    "
              )
            ]
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "modifiers-list" },
      [
        _c(
          "div",
          {
            staticClass: "Body-1-Black-Medium-Emphasis-Left",
            staticStyle: { height: "20px" }
          },
          [_vm._v("\n      How many items can the customer choose?\n    ")]
        ),
        _c(
          VLayout,
          {
            class: !_vm.isCard ? "size-line" : "modifierGroupCard",
            attrs: { row: "", wrap: "", "align-baseline": "" }
          },
          [
            _c(
              VFlex,
              {
                staticStyle: { "margin-right": "24px" },
                attrs: { xs4: "", shrink: "" }
              },
              [
                _c(VSelect, {
                  attrs: {
                    items: _vm.modifierTypes,
                    disabled: _vm.isDisabled,
                    "success-messages": _vm.modifierTypeChangedMessage
                  },
                  model: {
                    value: _vm.modifierValue,
                    callback: function($$v) {
                      _vm.modifierValue = $$v
                    },
                    expression: "modifierValue"
                  }
                })
              ],
              1
            ),
            _vm.modifierValue === "Range"
              ? [
                  _c(
                    VFlex,
                    { attrs: { xs2: "", shrink: "" } },
                    [
                      _c(VTextField, {
                        ref: "min",
                        attrs: {
                          disabled: _vm.isDisabled,
                          rules: _vm.rangeMinRules,
                          "error-messages": _vm.errorRangeMin,
                          type: "number",
                          "success-messages": _vm.minChangedMessage,
                          min: "0"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            $event.preventDefault()
                            return (function() {})($event)
                          }
                        },
                        model: {
                          value: _vm.minProxy,
                          callback: function($$v) {
                            _vm.minProxy = $$v
                          },
                          expression: "minProxy"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    {
                      staticStyle: { margin: "0px 24px" },
                      attrs: { shrink: "" }
                    },
                    [_vm._v("to")]
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs2: "" } },
                    [
                      _c(VTextField, {
                        ref: "max",
                        attrs: {
                          disabled: _vm.isDisabled,
                          rules: _vm.rangeMaxRules,
                          "error-messages": _vm.errorRangeMax,
                          type: "number",
                          "success-messages": _vm.maxChangedMessage,
                          min: "0"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            $event.preventDefault()
                            return (function() {})($event)
                          }
                        },
                        model: {
                          value: _vm.maxProxy,
                          callback: function($$v) {
                            _vm.maxProxy = $$v
                          },
                          expression: "maxProxy"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.modifierValue === "Minimum"
              ? [
                  _c(
                    VFlex,
                    { attrs: { xs2: "", shrink: "" } },
                    [
                      _c(VTextField, {
                        ref: "min",
                        attrs: {
                          rules: _vm.rangeMinRules,
                          disabled: _vm.isDisabled,
                          type: "number",
                          "success-messages": _vm.minChangedMessage,
                          min: "0"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            $event.preventDefault()
                            return (function() {})($event)
                          }
                        },
                        model: {
                          value: _vm.minProxy,
                          callback: function($$v) {
                            _vm.minProxy = $$v
                          },
                          expression: "minProxy"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.modifierValue === "Maximum"
              ? [
                  _c(
                    VFlex,
                    { attrs: { xs2: "", shrink: "" } },
                    [
                      _c(VTextField, {
                        ref: "max",
                        attrs: {
                          disabled: _vm.isDisabled,
                          rules: _vm.rangeMaxRules,
                          type: "number",
                          "success-messages": _vm.maxChangedMessage,
                          min: "0"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            $event.preventDefault()
                            return (function() {})($event)
                          }
                        },
                        model: {
                          value: _vm.maxProxy,
                          callback: function($$v) {
                            _vm.maxProxy = $$v
                          },
                          expression: "maxProxy"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.modifierValue === "Exactly"
              ? [
                  _c(
                    VFlex,
                    { attrs: { xs2: "", shrink: "" } },
                    [
                      _c(VTextField, {
                        ref: "max",
                        attrs: {
                          disabled: _vm.isDisabled,
                          rules: _vm.rangeMaxRules,
                          type: "number",
                          "success-messages": _vm.maxChangedMessage,
                          min: "0"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            $event.preventDefault()
                            return (function() {})($event)
                          }
                        },
                        model: {
                          value: _vm.exactValue,
                          callback: function($$v) {
                            _vm.exactValue = $$v
                          },
                          expression: "exactValue"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            !_vm.isCard && _vm.isLocal
              ? _c(
                  VBtn,
                  {
                    staticClass: "ma-0",
                    attrs: { flat: "", icon: "" },
                    on: { click: _vm.resetModGroupToGlobalModState }
                  },
                  [
                    _c(VIcon, { attrs: { small: "" } }, [
                      _vm._v("mdi-restart")
                    ])
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        ),
        _c(VTextField, {
          staticClass: "custom-validation",
          attrs: { "error-messages": _vm.modifierMessage }
        }),
        _c(
          VLayout,
          [
            _c(
              VFlex,
              _vm._l(_vm.groupProxy.items, function(modifier, index) {
                return _c("modifier", {
                  key: modifier.id || index,
                  attrs: {
                    modifier: modifier,
                    currentGroup: _vm.groupProxy,
                    isCard: _vm.isCard,
                    originalModifier: _vm.getOriginalModifier(modifier)
                  },
                  on: {
                    "update:modifier": function($event) {
                      return _vm.groupProxy.items.splice(index, 1, $event)
                    },
                    "bulkModifier:delete": function($event) {
                      return _vm.deleteModifier(modifier.id)
                    }
                  }
                })
              }),
              1
            )
          ],
          1
        ),
        !_vm.isLocal && !_vm.isCard
          ? _c(
              VLayout,
              [
                _c(
                  VFlex,
                  { attrs: { shrink: "" } },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "ml-0 pl-0",
                        attrs: {
                          flat: "",
                          color: "primary",
                          disabled: _vm.isAddModifierDisabled
                        },
                        on: { click: _vm.addModifier }
                      },
                      [
                        _c(VIcon, [_vm._v("mdi-plus")]),
                        _vm._v("Add MODIFIER\n        ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }