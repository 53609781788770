<template>
  <v-layout justify-center row>
    <v-flex sm8 lg5>
      <div class="H5-Secondary-Center mt-5">
        Get started with this global menu
        <br />by creating a menu set
      </div>
      <div class="Body-2-Selected-On-Surface-Medium-Emphasis-Center mb-5 mt-3">
        You can also use "+ ADD MENU SET" in the side bar
      </div>
      <v-text-field
        outline
        label="Enter Menu Set Name"
        append-icon="mdi-plus"
        class="mb-4"
        v-model="menu"
        @click:append="$emit('newMenuSet', menu)"
        @keyup.enter="$emit('newMenuSet', menu)"
      />
      <v-img :src="require('./menuhelper.svg')"></v-img>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    menu: '',
  }),
};
</script>

<style></style>
