<template>
  <v-layout
    row
    wrap
    align-center
    class="modifier"
    @mouseover="showActions = true"
    @mouseleave="showActions = false"
    @keydown.enter="disableEdit"
  >
    <v-flex xs8>
      <v-layout>
        <v-flex
          class="d-flex align-center"
          style="margin-right: 10px; width: 18rem; max-width: 18rem;"
          @click="edit"
        >
          <div v-if="isVersion1 || (isVersion2 && isLocal)">
            <span
              class="Body-1-Black-Medium-Emphasis-Left"
              v-if="!editable"
              style="pointer-events: none"
            >
              {{ modifier.label.en }}
            </span>
            <v-text-field
              v-else
              v-model="modifier.label.en"
              ref="label"
              :rules="labelRules"
              append-icon
              label="Item Name"
              :disabled="isModifierLabelDisabled"
            ></v-text-field>
            <div
              v-if="showGlobalModifierName"
              class="Caption-Selected-On-Surface-High-Emphasis-Left secondary-text mt-0 mb-2 display-flex align-center f-wrap"
            >
              <div><strong>Original Name:</strong> {{ globalModifierName }}</div>
              <v-tooltip bottom v-if="!isModifierLabelDisabled">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    flat
                    icon
                    round
                    small
                    color="gray"
                    @click.stop="setModifierName(globalModifierName)"
                    class="clickable tiny-btn"
                  >
                    <v-icon>mdi-restore</v-icon>
                  </v-btn>
                </template>
                <span>Use Original Name</span>
              </v-tooltip>
            </div>
          </div>
          <div v-else>
            <span
              class="Body-1-Black-Medium-Emphasis-Left"
              v-if="!editable"
              style="pointer-events: none"
            >
              {{ modifier.label.en }}
            </span>
            <v-select
              v-else
              :rules="selectRule"
              :placeholder="modifier.label.en"
              :value="modifier"
              @click.native.stop
              :disabled="readOnlyMode"
              ref="label"
              :items="getGlobalModGroupsModifiersToDisplay"
              item-text="label.en"
              item-value="id"
              @focus="getGlobalModItems"
              @change="setGlobalMod"
            ></v-select>
          </div>
        </v-flex>
        <v-flex style="margin-right: 10px;" @click="edit">
          <span
            class="Body-1-Black-Medium-Emphasis-Left"
            v-show="!editable"
            style="pointer-events: none"
            >+ ${{ price && price.toFixed(2) }}</span
          >
          <v-text-field
            label="Price"
            type="number"
            :value="price"
            :rules="priceRules"
            @input="price = parseFloat($event) || 0"
            prefix="+$"
            ref="price"
            single-line
            v-show="editable"
            :disabled="readOnlyMode || (isVersion2 && !isLocal)"
          />
        </v-flex>
        <v-flex style="margin-right: 10px;" @click="edit">
          <span
            class="Body-1-Black-Medium-Emphasis-Left"
            v-show="!editable"
            style="pointer-events: none"
            >Calories:{{ calories }}</span
          >
          <v-text-field
            label="Calories"
            type="number"
            v-model.number="calories"
            :rules="calorieRules"
            :disabled="isModifierCaloriesDisabled"
            v-show="editable"
            ref="calories"
          />
        </v-flex>
        <v-flex v-if="showPluField" @click="edit">
          <span
            class="Body-1-Black-Medium-Emphasis-Left"
            style="pointer-events: none"
            v-show="!editable"
            >PLU:{{ plu }}</span
          >
          <v-text-field
            label="PLU:"
            v-model="plu"
            v-show="editable"
            :disabled="isPluDisabled"
            ref="plu"
            @click="edit"
          />
        </v-flex>
        <v-flex class="pt-3">
          <v-btn flat icon class="ma-0" v-if="editable" @click="disableEdit">
            <v-icon small>mdi-check</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-spacer />
    <v-flex shrink class="display-flex align-center">
      <v-btn
        flat
        icon
        class="ma-0"
        v-show="showActions && !isLocal && isVersion1"
        @click="$emit('modifier:copy')"
        :disabled="readOnlyMode"
      >
        <v-icon small>mdi-content-copy</v-icon>
      </v-btn>
      <v-btn
        flat
        icon
        class="ma-0"
        v-show="showActions && !isLocal"
        @click="$emit('modifier:delete')"
        :disabled="readOnlyMode"
      >
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <div class="mr-1"></div>
      <v-checkbox
        v-if="!isLocal"
        v-model="modifier.is.disabled"
        v-on:change="$emit('modifier:groupaction')"
        hide-details
        color="secondary"
        @click.native.stop
        :false-value="true"
        :true-value="false"
        class="pt-0 mt-0 mr-4 d-inline-block"
        :disabled="readOnlyMode || isVersion2"
      />
      <div v-if="isLocal">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-checkbox
                v-model="modifier.is.hidden"
                v-on:change="$emit('modifier:groupaction')"
                hide-details
                on-icon="mdi-eye-off"
                off-icon="mdi-eye"
                :color="hiddenColor"
                @click.native.stop
                :false-value="false"
                :true-value="true"
                v-on="on"
                class="pt-0 mt-0 mr-2 d-inline-block"
                :disabled="disableEditForModPLU || readOnlyMode"
              />
            </div>
          </template>
          <span v-if="disableEditForModPLU">Requires PLU</span>
          <span v-else>
            <span v-if="modifier.is.hidden">Hidden in App</span>
            <span v-else>Display in App</span>
          </span>
        </v-tooltip>
      </div>
      <v-checkbox
        v-if="isLocal"
        v-model="outOfStockProxy"
        hide-details
        color="secondary"
        @click.native.stop
        :false-value="true"
        :true-value="false"
        class="pt-0 mt-0 mr-4 d-inline-block"
        :disabled="disableEditForModPLU || readOnlyMode"
      />
      <span v-if="showPromoExemptField">
        <v-switch
          inset
          v-model="promoExemptProxy"
          hide-details
          @click.native.stop
          :false-value="false"
          :true-value="true"
          color="green"
          class="pt-0 mt-0 mr-4 d-inline-block"
          v-bind:class="{ switchenabled: !promoExemptProxy }"
          :disabled="isSiteOperator || readOnlyMode"
        />
        <div class="promo_exempt_label">{{ promoExemptProxy ? 'Yes' : 'No' }}</div>
      </span>
      <v-flex class="sort_number">
        <v-text-field
          type="number"
          min="0"
          label
          v-model="sortNumberProxy"
          :rules="sortNumberRules"
          v-on:keydown.enter.stop.prevent="() => {}"
          :disabled="isSortNumberDisabled"
        />
      </v-flex>
    </v-flex>
    <v-flex xs7 style="margin-right: 10px; align-start" @click="edit" v-if="isVersion2">
      <div class="taxable_price">Taxable Price</div>
      <v-text-field
        label="Taxable Price"
        type="number"
        :value="taxablePrice"
        :rules="taxablePriceRules"
        @input="taxablePrice = parseFloat($event) || 0"
        prefix="+$"
        ref="taxablePrice"
        single-line
        :disabled="isTaxablePriceDisabled"
      />
    </v-flex>
    <v-flex xs7 @click="edit" v-if="isVersion2">
      <v-select
        :items="taxoptions"
        label="Tax Tags"
        v-model="modifier.meta.taxes"
        multiple
        clearable
        chips
        :disabled="true"
        class="mt-0 pt-0"
      ></v-select>
      <v-text-field label="Tax Tag (pcc)" v-model="pccTaxTagProxy" clearable :disabled="true" />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import get from 'lodash/get';
import rules from '@/rules';
import { PRICE_RULE } from '../../../../constants';

export default {
  props: {
    modifier: Object,
    isDisabledForPLU: Boolean,
    isCaloriesDisabled: Boolean,
    isLabelDisabled: Boolean,
    currentGroup: Object,
    parentModifier: Object,
  },
  data: () => ({
    showActions: false,
    editLabel: false,
    labelRules: [rules.required('Label is required')],
    selectRule: [rules.requiredObjectField('Required', 'id')],
    calorieRules: [rules.min(0)],
    sortNumberRules: [rules.min(1)],
    editPrice: false,
    editable: false,
    fetchingItems: false,
    search: '',
    items: [],
    globalModGroupModifers: [],
    taxoptions: ['Carbonated Beverage', 'Prepared', 'Sweetened Beverage'],
  }),
  watch: {
    search(val) {
      if (val && val.length > 1 && !this.isLocal) {
        // this.queryItems(val); // commented out while endpoint is not working
      }
      this.modifierProxy.label.en = val;
    },
  },
  computed: {
    ...mapGetters('adminPanel', ['isAdmin', 'isSiteOperator', 'hasSpecificPermissions']),
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('users', ['customPermissions']),
    ...mapState('menus', [
      'isLocal',
      'showPluField',
      'showPromoExemptField',
      'readOnlyMode',
      'menuVersion',
    ]),

    plu: {
      get() {
        return get(this.modifier, 'meta.plu');
      },
      set(v) {
        if (!this.modifier.meta) {
          this.$set(this.modifier, 'meta', { plu: v });
        } else if (!this.modifier.meta.plu) {
          this.$set(this.modifier.meta, 'plu', v);
        } else {
          this.modifier.meta.plu = v;
        }
      },
    },
    canEditPlu() {
      if (this.isAdmin) return true;
      const hasSpecificPermission = this.hasSpecificPermissions([
        this.customPermissions.overridePlu,
      ]);
      return this.isLocal && hasSpecificPermission;
    },
    modifierProxy: {
      get() {
        return this.modifier;
      },
      set(value) {
        this.$emit('update:modifier', value);
      },
    },
    sortNumberProxy: {
      get() {
        return this.modifierProxy.meta.sort_number;
      },
      set(value) {
        this.$set(this.modifierProxy.meta, 'sort_number', parseInt(value, 10) || undefined);
      },
    },
    pccTaxTagProxy: {
      get() {
        return this.modifierProxy.meta?.tax?.tax_tag_code || '';
      },
    },
    isModifierValid: {
      get() {
        const isLabelValid = !this.$refs.label || this.$refs.label.valid || false;
        const isPriceValid = (this.$refs.price && this.$refs.price.valid) || false;
        const isCaloriesValid = (this.$refs.calories && this.$refs.calories.valid) || false;
        const isPluValid = !this.$refs.plu || this.$refs.plu.valid || false;
        return isLabelValid && isPriceValid && isCaloriesValid && isPluValid;
      },
    },
    outOfStockProxy: {
      get() {
        if (this.modifierProxy.is && this.modifierProxy.is.out_of_stock) {
          return this.modifierProxy.is.out_of_stock;
        }
        return false;
      },
      set(v) {
        this.$set(this.modifierProxy.is, 'out_of_stock', v);
        this.$emit('modifier:groupaction');
      },
    },
    promoExemptProxy: {
      get() {
        return (
          Array.isArray(this.modifierProxy.amount_off_exclusions) &&
          this.modifierProxy.amount_off_exclusions.includes('promo')
        );
      },
      set(v) {
        let exclusions = this.modifierProxy.amount_off_exclusions || [];
        if (v === true && !exclusions.find((e) => e === 'promo')) {
          exclusions.push('promo');
        }
        if (v === false) {
          exclusions = exclusions.filter((e) => e !== 'promo');
        }
        this.$set(this.modifierProxy, 'amount_off_exclusions', exclusions);
      },
    },
    calories: {
      get() {
        return (
          this.modifier.nutrition &&
          this.modifier.nutrition.calories &&
          this.modifier.nutrition.calories.amount
        );
      },
      set(value) {
        value = Number.isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);

        if (
          this.modifier.nutrition &&
          this.modifier.nutrition.calories &&
          this.modifier.nutrition.calories.amount
        ) {
          this.modifierProxy.nutrition.calories.amount = value;
        } else {
          this.$set(this.modifierProxy, 'nutrition', {
            calories: { amount: value },
          });
        }
      },
    },
    price: {
      get() {
        if (this.modifierProxy.price && this.modifierProxy.price.amount) {
          return this.modifierProxy.price.amount;
        }
        return '';
      },
      set(value) {
        if (value === '') {
          this.$delete(this.modifierProxy, 'price');
        } else if (!this.modifierProxy.price) {
          this.$set(this.modifierProxy, 'price', { amount: value });
        } else {
          this.modifierProxy.price.amount = value;
        }
      },
    },
    taxablePrice: {
      get() {
        if (
          this.modifierProxy.meta &&
          this.modifierProxy.meta.taxable_price &&
          this.modifierProxy.meta.taxable_price.amount
        ) {
          return this.modifierProxy.meta.taxable_price.amount;
        }
        return '';
      },
      set(value) {
        if (!value) {
          this.$delete(this.modifierProxy.meta, 'taxable_price');
        } else if (!this.modifierProxy.meta.taxable_price) {
          this.$set(this.modifierProxy.meta, 'taxable_price', { amount: value });
        } else {
          this.modifierProxy.meta.taxable_price.amount = value;
        }
      },
    },
    isTaxablePriceEnabled() {
      // 'Carbonated Beverage', 'Prepared', 'Sweetened Beverage'
      if (!this.isModifierCarbonatedAndSweetened) {
        this.$set(this, 'taxablePrice');
      }
      return this.isModifierCarbonatedAndSweetened;
    },
    isModifierCarbonatedAndSweetened() {
      return (
        this.modifierProxy.meta.taxes &&
        this.modifierProxy.meta.taxes.indexOf('Carbonated Beverage') >= 0 &&
        this.modifierProxy.meta.taxes.indexOf('Sweetened Beverage') >= 0
      );
    },
    taxablePriceRules() {
      const required = (v) => {
        if (!this.isModifierCarbonatedAndSweetened) {
          return true;
        }
        if (v === '') return 'Taxable Price cannot be empty.';

        return !!parseFloat(v) || 'Taxable Price cannot be 0.';
      };

      return [required, rules.min(this.price, 'Taxable Price cannot be less than Price.')];
    },
    priceRules() {
      const rulesForPrice = [rules.min(PRICE_RULE.min), rules.max(PRICE_RULE.max)];
      if (this.isModifierCarbonatedAndSweetened) {
        rulesForPrice.push(rules.max(this.taxablePrice, 'Price must be less than Taxable Price'));
      }
      return rulesForPrice;
    },
    hiddenColor() {
      if (this.isDisabledForPLU) return 'gray';
      if (this.modifier.is.hidden) {
        return 'red';
      }
      return 'green';
    },
    disableEditForModPLU() {
      if (this.isLocal && this.isDisabledForPLU) return true;
      if (
        this.isLocal &&
        this.isSiteOperator &&
        this.showPluField &&
        (this.plu === undefined || this.plu === null || this.plu.toString().length === 0)
      ) {
        return true;
      }
      return false;
    },
    isVersion1() {
      return this.menuVersion === 1;
    },
    isVersion2() {
      return this.menuVersion === 2;
    },
    getGlobalModGroupsModifiersToDisplay() {
      if (!this.globalModGroupModifers || this.globalModGroupModifers.length === 0) {
        return [];
      }
      const modifiers = this.globalModGroupModifers.filter(
        (m) => !this.currentGroup.items.find((d) => d.id === m.id),
      );
      const currentMod = this.globalModGroupModifers.find((m) => m.id === this.modifier.id);
      if (currentMod) {
        modifiers.push(currentMod);
      }

      return modifiers.sort((a, b) => (a.label.en < b.label.en ? -1 : 1));
    },
    isPluDisabled() {
      return !this.canEditPlu || this.readOnlyMode || (!this.isLocal && this.isVersion2);
    },
    isSortNumberDisabled() {
      return this.disableEditForModPLU || this.readOnlyMode || (!this.isLocal && this.isVersion2);
    },
    isModifierCaloriesDisabled() {
      return this.isCaloriesDisabled || (!this.isLocal && this.isVersion2);
    },
    isModifierLabelDisabled() {
      return this.isLabelDisabled;
    },
    isTaxablePriceDisabled() {
      return !this.isTaxablePriceEnabled || this.readOnlyMode || (!this.isLocal && this.isVersion2);
    },
    globalModifierName() {
      if (!this.parentModifier) return '';
      return (this.parentModifier.label && this.parentModifier.label.en) || '';
    },
    showGlobalModifierName() {
      if (!this.isLocal || !this.globalModifierName) return false;
      const localModifierName = (this.modifierProxy.label && this.modifierProxy.label.en) || '';
      return this.globalModifierName !== localModifierName;
    },
  },
  async created() {
    if (!this.modifierProxy.label.en) {
      this.editable = true;
      await this.$nextTick();
      this.$refs.label.focus();
    }
    await this.$nextTick();
    if (!this.isModifierValid) {
      this.editable = true;
      this.$emit('modifier:invalid');
    }
  },
  beforeMount() {
    if (!this.modifier.is) {
      this.modifier.is = {
        disabled: false,
        out_of_stock: false,
        hidden: false,
      };
    }
  },
  methods: {
    ...mapActions('menus', ['getMenuItems', 'fetchGlobalModGroup']),
    edit() {
      if (this.disableEditForModPLU) return;
      this.editable = true;
    },
    disableEdit() {
      if (this.isModifierValid) {
        this.editable = false;
      }
    },
    async queryItems(query) {
      try {
        this.fetchingItems = true;
        const items = await this.getMenuItems({ query });
        this.items = items.map((e) => e.label.en);
        this.fetchingItems = false;
      } catch {
        console.error('could not autocomplete items');
      }
    },
    async getGlobalModItems() {
      const modGroup = await this.fetchGlobalModGroup({ id: this.currentGroup.id });
      this.globalModGroupModifers = [...modGroup.items];
    },
    setGlobalMod(id) {
      const currentMod = this.globalModGroupModifers.find((m) => m.id === id);
      this.modifierProxy.id = id;
      this.modifierProxy.is = { ...currentMod.is };
      this.modifierProxy.label = { ...currentMod.label };
      this.modifierProxy.meta = { ...currentMod.meta };
      this.modifierProxy.price = { ...currentMod.price };
      const calories =
        currentMod.nutrition &&
        currentMod.nutrition.calories &&
        currentMod.nutrition.calories.amount;

      if (calories) {
        if (
          this.modifierProxy.nutrition &&
          this.modifierProxy.nutrition.calories &&
          this.modifierProxy.nutrition.calories.amount
        ) {
          this.modifierProxy.nutrition.calories.amount = calories;
        } else {
          this.$set(this.modifierProxy, 'nutrition', {
            calories: { amount: calories },
          });
        }
      }
    },
    setModifierName(name) {
      this.$set(this.modifierProxy.label, 'en', name);
    },
  },
};
</script>

<style scoped>
.modifier {
  padding: 0px 12px 0px 24px;
  min-height: 46px;
}
.modifier:hover {
  background-color: #ececec;
}
.editable:hover {
  cursor: text;
  text-decoration: underline;
}
div >>> .v-text-field__prefix {
  min-width: 28px;
}
.flex.sort_number {
  max-width: 3.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 2.5rem;
  margin-left: auto;
}
.taxable_price {
  color: gray;
}
</style>
