<template>
  <div class="menu-nav">
    <v-layout column fill-height>
      <v-flex v-if="!isSiteOperator && !isImUser">
        <v-btn
          block
          flat
          :disabled="loading"
          :loading="loading"
          class="nav-btn"
          @click.native="goToBrand()"
        >
          <v-icon left>mdi-chevron-left</v-icon>
          {{ isLocal && active_company ? `${active_company.name} (local brands)` : sectorName }}
        </v-btn>
        <v-divider />
      </v-flex>
      <v-flex v-else>
        <v-btn
          block
          flat
          :disabled="loading"
          :loading="loading"
          class="nav-btn"
          @click.native="$router.go(-1)"
        >
          <v-icon left>mdi-chevron-left</v-icon> Back
        </v-btn>
        <v-divider />
      </v-flex>
      <v-flex class="list-item-style" style="margin-top: 40px;" shrink>
        <div class="Subtitle-2-Black-Medium-Emphasis-Left">
          {{ title }} {{ isLocalMenu ? '(Local)' : '(Global)' }}
        </div>
      </v-flex>
      <v-flex class="list-item-style" style="margin-bottom: 32px;">
        <div class="H4-Secondary-Center">{{ companyName }}</div>
      </v-flex>
      <v-form ref="form" v-model="isMenuValid">
        <template v-for="(menu, index) in value">
          <menu-set
            :key="menu.id || index"
            v-model="value[index]"
            :parentMenu="getParentMenu(menu.parent_id)"
            :menunames="menunames"
            :active="active"
            :menu-id="menu.id"
            @menu:duplicate="duplicate(index)"
            @menu:delete="deletes(index)"
            @menu:disable="disable(index)"
            @menu:export="exportToExcel(index)"
            @menu:import="$emit('menu:import', $event)"
            @getMenu="$emit('getMenu', $event)"
            @setView="$emit('setView', $event)"
          />
        </template>
      </v-form>
      <v-flex xs12></v-flex>
      <v-flex style="padding-left: 8px;" v-if="!isLocal">
        <v-btn flat :disabled="readOnlyMode" @click="newMenu()">
          <span class="Button-Primary-Center"> <v-icon>mdi-plus</v-icon>add menu set </span>
        </v-btn>
      </v-flex>
      <v-flex v-if="!isLocal">
        <v-divider />
        <div style="padding: 15px 35px;">
          <v-form ref="form">
            <v-text-field
              outline
              label="Menu ID"
              v-on:keydown.enter.stop.prevent="() => {}"
              v-model="importMenuID"
              :rules="importMenuRules(importMenuID)"
              :disabled="readOnlyMode"
            />
          </v-form>
          <v-btn
            color="primary"
            style="margin: 0px;"
            :disabled="readOnlyMode"
            @click="importMenu"
            :loading="importLoading"
            >IMPORT</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import omitDeep from 'omit-deep-lodash';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import ID from '@compassdigital/id';
import { save } from 'save-file';
import rules from '@/rules';
import menuSet from './menuSet';

export default {
  props: {
    value: {
      required: true,
    },
    active: Object,
    title: String,
    parentMenus: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    menuSet,
  },
  data: () => ({
    importMenuID: '',
    importLoading: false,

    importMenuRules: (id) => [
      rules.required('This field is required'),
      () => (ID(id) && ID(id).provider !== 'cdl') || 'This Menu cannot be imported',
    ],
  }),
  computed: {
    ...mapState('sectors', ['active_sector', 'active_company']),
    ...mapState('menus', ['isLocal', 'readOnlyMode']),
    ...mapGetters('adminPanel', ['isSiteOperator', 'isImUser']),
    ...mapGetters('splitio', ['getFeatureFlags']),
    isPartialCategoriesActive() {
      return this.getFeatureFlags['ap3-menu-partial-categories'];
    },
    isPartialItemsActive() {
      return this.getFeatureFlags['ap3-menu-partial-items'];
    },
    menunames() {
      return this.value.map((e) => e.label && e.label.en);
    },
    sectorName() {
      return (this.active_sector && this.active_sector.name) || '';
    },
    companyName() {
      return (this.active_company && this.active_company.name) || '';
    },
    isLocalMenu() {
      return !!this.$route.params.brand_id;
    },
    isMenuValid: {
      get() {
        return this.$store.state.menus.isMenuValid;
      },
      set(v) {
        this.$store.commit('menus/setIsMenuValid', v);
      },
    },
  },
  methods: {
    ...mapActions('menus', ['fetchMenu', 'fetchMenuGroupItems', 'fetchMenuItemModGroups']),
    ...mapMutations('menus', ['setMenuVersion']),
    goToSector() {
      this.$router.push({
        name: 'menu-sector-info',
        params: {
          sector_id: this.$route.params.sector_id,
        },
      });
    },
    goToCompany() {
      this.$router.push({
        name: 'local-brands',
        params: {
          sector_id: this.active_company.sector,
          company_id: this.active_company.id,
        },
      });
    },
    goToBrand() {
      this.$router.push({
        name: 'brand',
        params: {
          sector_id: this.active_company.sector,
          company_id: this.active_company.id,
        },
      });
    },
    async duplicate(index) {
      let menu = cloneDeep(this.value[index]);
      const basename = menu.label.en;
      menu.label.en += ' copy';
      let count = 0;
      while (this.menunames.includes(menu.label.en)) {
        count += 1;
        menu.label.en = `${basename} copy (${count})`;
      }

      // Load the entire menu
      if (!menu.groups) {
        menu = await this.fetchMenu({ id: menu.id, nocache: true });
      }

      // Load the unloaded groups
      await Promise.all(
        menu.groups.map(async (group) => {
          if (!group.items || !group.items.length) {
            group.items = cloneDeep(
              await this.fetchMenuGroupItems({
                menu_id: menu.id,
                group_id: group.id,
                include_options: true,
              }),
            );
          } else {
            // Load modifier groups for partially loaded groups
            const items_to_load = group.items.filter(
              (item) => !item.options || !item.options.length,
            );
            if (
              !items_to_load.length ||
              !this.isPartialItemsActive ||
              !this.isPartialCategoriesActive
            ) {
              return;
            }
            const loaded_items = await this.fetchMenuItemModGroups({
              menu_id: menu.id,
              group_id: group.id,
              item_id: items_to_load.map((item) => item.id),
            });
            for (const { id, options } of loaded_items) {
              const item_to_load = items_to_load.find((item) => item.id === id);
              item_to_load.options = cloneDeep(options);
            }
          }
        }),
      );

      if (menu.meta && menu.meta.version && menu.meta.version === 1) {
        menu = omitDeep(menu, 'id');
      } else {
        delete menu.id;
        if (menu.groups) {
          menu.groups.forEach((group) => {
            delete group.id;
            if (!group.items) return;
            group.items.forEach((item) => {
              delete item.id;
            });
          });
        }
      }

      this.value.push(menu);
    },
    deletes(index) {
      if (this.value[index].id === this.active.menu) {
        this.$emit('setView', { category: null, menu: null });
      }
      this.value.splice(index, 1);
    },
    async exportToExcel(index) {
      const menuset = this.value[index];
      const now = new Date();
      const timestamp = now.toLocaleString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      try {
        const { data } = await this.api.get(`/menu/${menuset.id}/export`);
        await save(data.file, `${menuset.label.en || 'menu-set'} - ${timestamp}.${data.format}`);
      } catch (e) {
        console.error(e);
        this.$toast.error('Error exporting menu set.');
      }
    },
    disable(index) {
      const disableMenu = this.value[index];
      disableMenu.is.disabled = !disableMenu.is.disabled;
    },
    hideMenu(index) {
      const hideMenu = this.value[index];
      hideMenu.is.hidden = !hideMenu.is.hidden;
    },
    newMenu(a) {
      const menu = {
        label: {
          en: a || '',
        },
        groups: [],
        meta: { version: 2 },
      };
      this.value.push(menu);
    },
    async importMenu() {
      if (this.$refs.form.validate()) {
        this.importLoading = true;
        try {
          let menu = await this.fetchMenu({ id: this.importMenuID, nocache: true });
          menu = omitDeep(menu, 'id');
          menu = JSON.parse(JSON.stringify(menu), (key, value) => (key === 'options' ? [] : value));

          if (!menu.meta) menu.meta = {};
          menu.meta.version = 2;

          if (!menu.label || !menu.label.en) {
            menu.label = { en: '' };
          }
          if (!menu.date) {
            menu.date = {};
          }
          menu.groups.forEach((group) => {
            if (!group.label || !group.label.en) {
              group.label = { en: group.name || '' };
            }
            group.items.forEach((item) => {
              item.is = { disabled: false };
              if (!item.nutrition) {
                item.nutrition = {};
              }
              if (!item.nutrition.calories) {
                item.nutrition.calories = {};
              }
              item.nutrition.calories = Object.assign(item.nutrition.calories, { amount: 0 });
              if (item.options) {
                item.options.forEach((option) => {
                  if (option && option.is) {
                    option.is = { ...option.is, disabled: false };
                  } else {
                    option.is = { disabled: false };
                  }

                  if (option.items) {
                    option.items.forEach((optitem) => {
                      if (optitem && optitem.is) {
                        optitem.is = { ...optitem.is, disabled: false };
                      } else {
                        optitem.is = { disabled: false };
                      }
                    });
                  }
                });
              }
            });
          });

          this.value.push(menu);
          this.importMenuID = '';
          this.$refs.form.resetValidation();
        } catch (err) {
          this.$toast.error('Could not import this menu');
        }

        this.importLoading = false;
      }
    },
    getParentMenu(menu_id) {
      return this.parentMenus.find((menu) => menu.id === menu_id);
    },
  },
};
</script>

<style scoped>
.menu-nav {
  background-color: #f7f7f7;
  height: 100%;
}
.list-item-style {
  padding-left: 36px;
}
.nav-btn {
  border-radius: 0;
  height: 60px;
  letter-spacing: unset !important;
  margin: 0;
  padding-left: 8px;
  text-transform: none;
}
>>> .v-btn__content {
  justify-content: start;
}
</style>
