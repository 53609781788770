<template>
  <v-flex xs12>
    <div>
      <span class="Body-1-Selected-On-Surface-High-Emphasis-Left" v-if="!editLabel">
        Modifier Group: {{ groupProxy.label.en }}
      </span>
    </div>
    <div class="modifiers-list">
      <div class="Body-1-Black-Medium-Emphasis-Left" style="height:20px">
        How many items can the customer choose?
      </div>
      <v-layout row wrap align-baseline :class="!isCard ? 'size-line' : 'modifierGroupCard'">
        <v-flex xs4 style="margin-right: 24px;" shrink>
          <v-select
            :items="modifierTypes"
            v-model="modifierValue"
            style
            :disabled="isDisabled"
            :success-messages="modifierTypeChangedMessage"
          />
        </v-flex>
        <template v-if="modifierValue === 'Range'">
          <v-flex xs2 shrink>
            <v-text-field
              v-model="minProxy"
              :disabled="isDisabled"
              :rules="rangeMinRules"
              :error-messages="errorRangeMin"
              type="number"
              v-on:keydown.enter.stop.prevent="() => {}"
              :success-messages="minChangedMessage"
              min="0"
              ref="min"
            />
          </v-flex>
          <v-flex shrink style="margin: 0px 24px;">to</v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="maxProxy"
              :disabled="isDisabled"
              :rules="rangeMaxRules"
              :error-messages="errorRangeMax"
              type="number"
              v-on:keydown.enter.stop.prevent="() => {}"
              :success-messages="maxChangedMessage"
              min="0"
              ref="max"
            />
          </v-flex>
        </template>
        <template v-if="modifierValue === 'Minimum'">
          <v-flex xs2 shrink>
            <v-text-field
              v-model="minProxy"
              :rules="rangeMinRules"
              :disabled="isDisabled"
              type="number"
              v-on:keydown.enter.stop.prevent="() => {}"
              :success-messages="minChangedMessage"
              min="0"
              ref="min"
            />
          </v-flex>
        </template>
        <template v-if="modifierValue === 'Maximum'">
          <v-flex xs2 shrink>
            <v-text-field
              v-model="maxProxy"
              :disabled="isDisabled"
              :rules="rangeMaxRules"
              type="number"
              v-on:keydown.enter.stop.prevent="() => {}"
              :success-messages="maxChangedMessage"
              min="0"
              ref="max"
            />
          </v-flex>
        </template>
        <template v-if="modifierValue === 'Exactly'">
          <v-flex xs2 shrink>
            <v-text-field
              v-model="exactValue"
              :disabled="isDisabled"
              :rules="rangeMaxRules"
              type="number"
              v-on:keydown.enter.stop.prevent="() => {}"
              :success-messages="maxChangedMessage"
              min="0"
              ref="max"
            />
          </v-flex>
        </template>
        <v-btn
          flat
          icon
          class="ma-0"
          v-if="!isCard && isLocal"
          @click="resetModGroupToGlobalModState"
        >
          <v-icon small>mdi-restart</v-icon>
        </v-btn>
      </v-layout>
      <v-text-field :error-messages="modifierMessage" class="custom-validation" />
      <v-layout>
        <v-flex>
          <modifier
            v-for="(modifier, index) in groupProxy.items"
            :key="modifier.id || index"
            :modifier="modifier"
            v-on:update:modifier="groupProxy.items.splice(index, 1, $event)"
            @bulkModifier:delete="deleteModifier(modifier.id)"
            :currentGroup="groupProxy"
            :isCard="isCard"
            :originalModifier="getOriginalModifier(modifier)"
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="!isLocal && !isCard">
        <v-flex shrink>
          <v-btn
            class="ml-0 pl-0"
            flat
            color="primary"
            @click="addModifier"
            :disabled="isAddModifierDisabled"
          >
            <v-icon>mdi-plus</v-icon>Add MODIFIER
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-flex>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { syncModGroupChanges } from '@/helpers';

import { mapState, mapGetters, mapActions } from 'vuex';

import rules from '@/rules';
import modifier from './modifier';

export default {
  props: {
    group: Object,
    isCard: Boolean,
    currentItem: Object,
  },
  components: {
    modifier,
  },
  data: () => ({
    showActions: false,
    modifierTypes: ['Range', 'Minimum', 'Maximum', 'No limit', 'Exactly'],
    editLabel: false,
    rules: [rules.required('Group name is required')],
    rangeMinRules: [rules.required('Required'), rules.min(0)],
    rangeMaxRules: [rules.required('Required'), rules.min(1)],
    requiredRule: [rules.required('Required')],
    sortNumberRules: [rules.min(1)],
    backupModGroup: {},
  }),
  computed: {
    ...mapGetters('adminPanel', ['isSiteOperator', 'isImUser', 'isAdmin']),
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('menus', ['isLocal', 'showPromoExemptField', 'readOnlyMode', 'menuVersion']),
    originalModGroupInItem() {
      if (!this.isCard) return null;
      const originalItem = this.group.parent_items.find((i) => i.id === this.currentItem.id);
      return cloneDeep(originalItem.options.find((mg) => mg.id === this.group.id));
    },
    groupProxy: {
      get() {
        const { group } = this;
        if (this.isCard) {
          const originalModGroupInItemCopy = cloneDeep(this.originalModGroupInItem);
          syncModGroupChanges(group, originalModGroupInItemCopy);

          return originalModGroupInItemCopy;
        }
        return group;
      },
      set(value) {
        this.$emit('update:group', value);
      },
    },
    minProxy: {
      get() {
        return this.groupProxy.min;
      },
      set(v) {
        this.groupProxy = {
          ...this.groupProxy,
          min: parseInt(v, 10) || 0,
        };
      },
    },
    maxProxy: {
      get() {
        return this.groupProxy.max;
      },
      set(v) {
        this.groupProxy = {
          ...this.groupProxy,
          max: parseInt(v, 10) || 0,
        };
      },
    },
    sortNumberProxy: {
      get() {
        return this.groupProxy.meta && this.groupProxy.meta.sort_number;
      },
      set(v) {
        this.groupProxy = {
          ...this.groupProxy,
          meta: { ...this.groupProxy.meta, sort_number: v },
        };
      },
    },
    exactValue: {
      get() {
        return this.maxProxy;
      },
      set(value) {
        this.groupProxy = {
          ...this.groupProxy,
          max: parseInt(value, 10) || 0,
          min: parseInt(value, 10) || 0,
        };
      },
    },
    errorRangeMin() {
      if (this.group.min > this.group.max) {
        return 'Must be less than max';
      }
      return '';
    },

    errorRangeMax() {
      if (this.group.max < this.group.min) {
        return 'Must be greater than min';
      }
      return '';
    },

    modifierMessage() {
      if (this.groupProxy.min && this.groupProxy.min > this.groupProxy.items.length) {
        return `There must be at least ${this.groupProxy.min} modifiers`;
      }

      if (this.groupProxy.max && this.groupProxy.max > this.groupProxy.items.length) {
        return `There must be at least ${this.groupProxy.max} modifiers`;
      }

      if (this.groupProxy.items.length === 0) {
        return 'You must have at least one modifier';
      }

      return '';
    },
    hiddenColor() {
      if (this.groupProxy.is.hidden) {
        return 'red';
      }
      return 'green';
    },
    isVersion1() {
      return this.menuVersion === 1;
    },
    isVersion2() {
      return this.menuVersion === 2;
    },
    isDisabled() {
      return (
        !(this.isAdmin || this.isSiteOperator || this.isImUser) ||
        this.readOnlyMode ||
        (this.isVersion2 && !this.isLocal) ||
        this.isCard
      );
    },
    isSortNumberDisabled() {
      return this.readOnlyMode || (!this.isLocal && this.isVersion2);
    },
    isAddModifierDisabled() {
      const modifiersShownCount =
        this.groupProxy.globalModGroup &&
        this.groupProxy.globalModGroup.items &&
        this.groupProxy.globalModGroup.items.length;
      const modifiersAvailableCount = this.groupProxy.items && this.groupProxy.items.length;
      return modifiersShownCount === modifiersAvailableCount;
    },
    modifierValue: {
      get() {
        let { min, max } = this.groupProxy;
        if (min === undefined) min = null;
        if (max === undefined) max = null;
        if (min === null && max === null) {
          return 'No limit';
        }
        if (max === null) {
          return 'Minimum';
        }
        if (min === null) {
          return 'Maximum';
        }
        if (min === max) {
          return 'Exactly';
        }
        return 'Range';
      },
      set(value) {
        this.updateMinMax(value);
      },
    },
    modifierTypeChangedMessage() {
      const minValue = this.minProxy;
      const maxValue = this.maxProxy;
      const backupMinValue = this.backupModGroup.min;
      const backupMaxValue = this.backupModGroup.max;
      const isValueSame =
        (this.isCard && minValue === backupMinValue && maxValue === backupMaxValue) ||
        (minValue === undefined && maxValue === undefined);
      return this.isLocal && !isValueSame ? ['Modified'] : [];
    },
    minChangedMessage() {
      const minValue = this.minProxy;
      const backupMinValue = this.backupModGroup.min;
      const isValueSame = (this.isCard && minValue === backupMinValue) || minValue === undefined;
      const isFieldValid = this.$refs.min && this.$refs.min.valid;
      return this.isLocal && isFieldValid && !isValueSame ? ['Modified'] : [];
    },
    maxChangedMessage() {
      const maxValue = this.maxProxy;
      const backupMaxValue = this.backupModGroup.max;
      const isValueSame = (this.isCard && maxValue === backupMaxValue) || maxValue === undefined;
      const isFieldValid = this.$refs.max && this.$refs.max.valid;
      return this.isLocal && isFieldValid && !isValueSame ? ['Modified'] : [];
    },
  },
  methods: {
    ...mapActions('menus', ['fetchGlobalModGroup']),
    deleteModifier(id) {
      this.groupProxy = {
        ...this.groupProxy,
        items: this.groupProxy.items.filter((i) => i.id !== id),
      };
    },
    addModifier() {
      this.groupProxy.items.push({
        is: {
          disabled: false,
        },
        meta: {},
        label: { en: '' },
        price: { amount: 0 },
      });
    },
    async updateMinMax(v) {
      switch (v) {
        case 'Exactly':
          if (!this.groupProxy.min) {
            this.$set(this.groupProxy, 'min', 1);
          }
          this.$set(this.groupProxy, 'max', this.groupProxy.min);
          break;
        case 'No limit':
          this.$set(this.groupProxy, 'max', null);
          this.$set(this.groupProxy, 'min', null);
          break;
        case 'Minimum':
          if (!this.groupProxy.min) {
            this.$set(this.groupProxy, 'min', 1);
          }
          this.$set(this.groupProxy, 'max', null);
          break;
        case 'Maximum':
          if (!this.groupProxy.max) {
            this.$set(this.groupProxy, 'max', 1);
          }
          this.$set(this.groupProxy, 'min', null);
          break;
        case 'Range':
          if (!this.groupProxy.max) {
            this.$set(this.groupProxy, 'max', 1);
          }
          if (!this.groupProxy.min || this.groupProxy.min === this.groupProxy.max) {
            this.$set(this.groupProxy, 'min', 0);
          }
          break;
        default:
          break;
      }
      await this.$nextTick();
      this.$emit('validateform');
    },
    toggleModifierGroup(e, property) {
      this.$set(this.groupProxy.is, property, e);
      // set all the modifiers to the same value
      if (this.group && this.group.items && this.group.items.length > 0) {
        this.group.items.forEach((i) => {
          if (!i.is) i.is = {};
          this.$set(i.is, property, e);
        });
      }
    },
    async startEdit() {
      this.editLabel = true;
      await this.$nextTick();
      if (this.isVersion1) this.$refs.labelinput.focus();
      if (this.isVersion2) this.$refs.uniqueName.focus();
    },
    updateSorting(event) {
      if (this.readOnlyMode || !this.isLocal) return; // global menus don't need menu_sort_number, since just modify json
      this.setMenuSortNumber(event.newIndex, this.group.items);
    },
    setMenuSortNumber(moved_record_index, records = []) {
      let last_record_with_sort_number_index = null;
      for (let i = records.length - 1; i >= 0; i -= 1) {
        const record = records[i];
        if (record && record.meta && record.meta.menu_sort_number) {
          last_record_with_sort_number_index = i;
          break;
        }
      }
      if (moved_record_index > last_record_with_sort_number_index) {
        last_record_with_sort_number_index = moved_record_index;
      }

      for (let i = 0; i <= last_record_with_sort_number_index; i += 1) {
        const record = records[i];
        this.$set(record, 'meta', { ...record.meta, menu_sort_number: i + 1 });
      }
    },
    resetModGroupToGlobalModState() {
      this.$set(this.groupProxy, 'min', undefined);
      this.$set(this.groupProxy, 'max', undefined);
    },
    getOriginalModifier(_modifier) {
      if (!this.isCard || !this.originalModGroupInItem) return _modifier;
      return this.originalModGroupInItem.items.find((i) => i.id === _modifier.id) || {};
    },
  },
  mounted() {
    this.backupModGroup = this.isCard
      ? cloneDeep(this.originalModGroupInItem)
      : cloneDeep(this.groupProxy);
  },
};
</script>

<style scoped>
.modifier-group {
  cursor: pointer;
  padding-right: 12px;
}

.modifier-group:hover {
  background-color: #f5f5f5;
}

.icon {
  margin: 10px 24px;
}

.modifiers-list {
  padding: 10px 0px;
  background-color: #fbfbfb;
}

.modifiers-list > * {
  padding: 0px 12px 0px 24px;
}

div >>> .size-line .v-text-field__slot input {
  padding-left: 24px;
}
.flex.sort_number {
  max-width: 3.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}

.modifierGroupCard {
  max-height: 60px;
}
</style>
