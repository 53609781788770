<template>
  <v-flex :class="flexClass" v-ripple xs12>
    <v-layout align-center>
      <v-icon v-if="icon" @click="iconAction">{{ icon }}</v-icon>
      <slot name="prefix"></slot>

      <span v-if="!edit" class="listspan">
        <div :class="titleClass" :style="titleStyle">
          {{ title }}
          <slot name="inlineActions" v-if="!edit"></slot>
        </div>
        <slot name="secondaryData"></slot>
        <div
          class="Caption-Selected-On-Surface-Medium-Emphasis-Left"
          :style="titleStyle"
          v-if="secondary"
        >
          {{ secondary }}
        </div>
      </span>
      <v-layout align-center v-else>
        <v-text-field
          ref="input"
          :value="title"
          @input="$emit('update:title', $event)"
          @blur="editDone"
          v-on:keyup.enter="editDone"
          @click.stop
          @mousedown.stop
          v-on:keydown.enter.stop.prevent="() => {}"
          :label="label"
          single-line
          :rules="rules"
          :error-messages="errors"
          :disabled="readOnlyMode"
        />
        <slot name="edit"></slot>
      </v-layout>
      <v-spacer></v-spacer>
      <slot name="actions" v-if="!edit"></slot>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    fontSize: {
      type: String,
      default: 'small',
      validator(v) {
        return ['small', 'large'].includes(v);
      },
    },
    fontClass: String,
    icon: String,
    active: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    label: { type: String, default: '' },
    secondary: { type: String, default: '' },
    rules: Array,
    errors: String,
    iconAction: { type: Function, default: () => ({}) },
  },
  computed: {
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('menus', ['isLocal', 'readOnlyMode']),
    titleClass() {
      const domClass = {};
      if (this.fontClass) {
        domClass[this.fontClass] = true;
        return domClass;
      }
      if (this.fontSize === 'small') {
        domClass['Body-2-Selected-On-Surface-Medium-Emphasis-Left'] = true;
      } else {
        domClass['Subtitle-1-Selected-On-Surface-High-Emphasis-Left'] = true;
      }
      return domClass;
    },
    titleStyle() {
      const style = {};
      if (this.icon) {
        style['padding-left'] = '4px';
      }
      return style;
    },
    flexClass() {
      const domClass = {
        'list-item': true,
      };

      if (this.fontSize === 'large') {
        domClass['list-item-large'] = true;
      }
      if (!this.icon) {
        domClass['no-icon'] = true;
      }
      if (this.active) {
        domClass.active = true;
      }
      return domClass;
    },
  },
  methods: {
    editDone() {
      // if (this.$refs.form && this.$refs.form.validate() && !this.errors) {
      if (this.$store.state.menus.isMenuValid && !this.errors) {
        this.$emit('update:edit', false);
      }
    },
  },
  watch: {
    async edit(value) {
      if (value === true) {
        await this.$nextTick();
        this.$refs.input.focus();
      }
    },
  },
};
</script>

<style scoped>
.list-item {
  padding: 8px;
  cursor: pointer;
}
.list-item-large {
  padding-top: 16px;
  padding-bottom: 16px;
}
.list-item:hover {
  background-color: #ececec;
}

.active {
  background-color: #e3e3e3;
}
.no-icon {
  padding-left: 36px;
}
.listspan {
  overflow: hidden;
}
</style>
