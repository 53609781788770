import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { "justify-center": "", row: "" } },
    [
      _c(
        VFlex,
        { attrs: { sm8: "", lg5: "" } },
        [
          _c("div", { staticClass: "H5-Secondary-Center mt-5" }, [
            _vm._v("\n      Get started with this global menu\n      "),
            _c("br"),
            _vm._v("by creating a menu set\n    ")
          ]),
          _c(
            "div",
            {
              staticClass:
                "Body-2-Selected-On-Surface-Medium-Emphasis-Center mb-5 mt-3"
            },
            [
              _vm._v(
                '\n      You can also use "+ ADD MENU SET" in the side bar\n    '
              )
            ]
          ),
          _c(VTextField, {
            staticClass: "mb-4",
            attrs: {
              outline: "",
              label: "Enter Menu Set Name",
              "append-icon": "mdi-plus"
            },
            on: {
              "click:append": function($event) {
                return _vm.$emit("newMenuSet", _vm.menu)
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$emit("newMenuSet", _vm.menu)
              }
            },
            model: {
              value: _vm.menu,
              callback: function($$v) {
                _vm.menu = $$v
              },
              expression: "menu"
            }
          }),
          _c(VImg, { attrs: { src: require("./menuhelper.svg") } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }