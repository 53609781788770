import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VFlex,
    { attrs: { xs12: "" } },
    [
      _c(
        VLayout,
        {
          staticClass: "display-flex modifier-group",
          attrs: { "align-center": "" }
        },
        [
          _c(
            "div",
            {
              staticClass: "Body-1-Selected-On-Surface-High-Emphasis-Left fg1"
            },
            [_vm._t("unique_name")],
            2
          ),
          _c("div", { staticClass: "display-flex align-center fg1 ml-4" }, [
            _c(
              "span",
              { staticClass: "Body-1-Selected-On-Surface-High-Emphasis-right" },
              [
                _c(VTextField, {
                  attrs: {
                    label: "Label in App:",
                    value: _vm.groupProxy.label.en,
                    disabled: true
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "display-flex align-center fg1" },
            [
              [
                _c(
                  VBtn,
                  {
                    staticClass: "ma-0",
                    attrs: { flat: "", icon: "", disabled: !_vm.group.id },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("modifierGroup:edit")
                      },
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.stopPropagation()
                        $event.preventDefault()
                        return (function() {})($event)
                      }
                    }
                  },
                  [
                    _c(VIcon, { attrs: { small: "" } }, [
                      _vm._v("mdi-pencil")
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "mr-1" })
              ],
              !_vm.isLocal
                ? [
                    _c(
                      VTooltip,
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        staticClass: "ma-0",
                                        attrs: { flat: "", icon: "" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.$emit(
                                              "modifierGroup:delete"
                                            )
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c(VIcon, { attrs: { small: "" } }, [
                                        _vm._v("mdi-delete")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "mr-1" })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3166685857
                        )
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "tooltipClass" },
                          [
                            _c("div", [
                              _vm._v(
                                "You will be removing this Modifier Group from following items:"
                              )
                            ]),
                            _c(VDivider),
                            _vm._l(_vm.group.parent_items, function(item) {
                              return _c("div", { key: item.id }, [
                                _vm._v("- " + _vm._s(item.label.en))
                              ])
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _vm.isLocal
                ? _c(
                    "div",
                    [
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "div",
                                      _vm._g({}, on),
                                      [
                                        _vm.isLocal
                                          ? _c(
                                              VCheckbox,
                                              _vm._g(
                                                {
                                                  staticClass: "pt-0 mt-0 mr-2",
                                                  class:
                                                    _vm.isHiddenProxy ===
                                                      undefined &&
                                                    "black-color",
                                                  attrs: {
                                                    "hide-details": "",
                                                    "on-icon": "mdi-eye-off",
                                                    "off-icon": "mdi-eye",
                                                    color: _vm.hiddenColor,
                                                    "false-value": false,
                                                    "true-value": true
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.isHiddenProxy,
                                                    callback: function($$v) {
                                                      _vm.isHiddenProxy = $$v
                                                    },
                                                    expression: "isHiddenProxy"
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            15058616
                          )
                        },
                        [
                          _c("span", [
                            _vm.groupProxy.is.hidden
                              ? _c("span", [_vm._v("Hidden in App")])
                              : _vm.groupProxy.is.hidden === undefined
                              ? _c("span", [_vm._v("No changes")])
                              : _c("span", [_vm._v("Display in App")])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VFlex,
                { staticClass: "sort_number" },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "Sequence On Ticket",
                      type: "number",
                      min: "0",
                      value: _vm.sortNumberProxy,
                      rules: _vm.sortNumberRules,
                      disabled: !_vm.isLocal,
                      "success-messages": _vm.sortNumberChangedMessage
                    },
                    on: {
                      input: function($event) {
                        _vm.sortNumberProxy = parseInt($event, 10) || undefined
                      },
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.stopPropagation()
                        $event.preventDefault()
                        return (function() {})($event)
                      },
                      keypress: _vm.blockDecimals
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VIcon,
                                _vm._g(
                                  { attrs: { color: "primary", dark: "" } },
                                  on
                                ),
                                [_vm._v("mdi-help-circle")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "tooltipClass" },
                        [
                          _c("div", [_vm._v("Your Items Selected:")]),
                          _c(VDivider),
                          _vm._l(_vm.group.parent_items, function(item) {
                            return _c("div", { key: item.id }, [
                              _vm._v("- " + _vm._s(item.label.en))
                            ])
                          })
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }