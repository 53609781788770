import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { width: "800" },
      model: {
        value: _vm.openProxy,
        callback: function($$v) {
          _vm.openProxy = $$v
        },
        expression: "openProxy"
      }
    },
    [
      _c(
        VCard,
        [
          _c(
            VCardTitle,
            {
              staticClass: "H4-Secondary-Center",
              attrs: { "primary-title": "" }
            },
            [_vm._v("\n      Edit Category Name\n    ")]
          ),
          _c(
            VCardText,
            [
              _c(
                VForm,
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c(
                    VLayout,
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs10: "" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: "Category Name",
                              rules: _vm.nameRules,
                              disabled: _vm.useGlobalCategoryNameProxy
                            },
                            model: {
                              value: _vm.name,
                              callback: function($$v) {
                                _vm.name = $$v
                              },
                              expression: "name"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "justify-content": "space-between"
                              }
                            },
                            [
                              _c(VCheckbox, {
                                staticClass: "pt-0 mt-0 mr-4",
                                attrs: {
                                  "hide-details": "",
                                  color: "secondary",
                                  label: "Use original name"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                },
                                model: {
                                  value: _vm.useGlobalCategoryNameProxy,
                                  callback: function($$v) {
                                    _vm.useGlobalCategoryNameProxy = $$v
                                  },
                                  expression: "useGlobalCategoryNameProxy"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(VDivider),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  attrs: { color: "primary", flat: "" },
                  on: { click: _vm.clear }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                VBtn,
                {
                  attrs: {
                    color: "primary",
                    flat: "",
                    disabled: _vm.isSaveDisabled
                  },
                  on: { click: _vm.save }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }