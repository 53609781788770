import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { "justify-center": "", row: "" } },
    [
      _c(
        VFlex,
        { attrs: { sm8: "", lg5: "" } },
        [
          _c("div", { staticClass: "H5-Secondary-Center mt-5" }, [
            _vm._v(
              "\n      There are no menus have been set up for this location.\n    "
            )
          ]),
          _c("construction", { attrs: { message: "Contact menu management" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }