<template>
  <v-flex xs12>
    <div
      class="modifier-group"
      @mouseover="showActions = true"
      @mouseleave="showActions = false"
      @click="expandAndStartEdit"
    >
      <v-layout class="display-flex justify-space-between" align-center>
        <div class="display-flex align-center f-column align-items-start" v-if="isVersion1">
          <div class="display-flex align-center">
            <v-icon class="icon">{{ expandIcon }}</v-icon>
            <!-- pointer-events: none; prevents the item config from randomly closing when clicking on the modifier groups  -->
            <span
              class="Body-1-Selected-On-Surface-High-Emphasis-Left"
              v-if="!editLabel && !editLocalLabel"
              style="pointer-events: none;"
            >
              {{ groupProxy.label.en }}
            </span>
            <v-text-field
              single-line
              v-model="groupProxy.label.en"
              @blur="doneEdit"
              @keydown.enter="doneEdit"
              ref="labelinput"
              :rules="rules"
              :disabled="readOnlyMode"
              v-on:keydown.enter.stop.prevent="() => {}"
              v-else
              @click.native.stop
            />
            <v-tooltip bottom v-if="!isModGroupLabelDisabled">
              <template v-slot:activator="{ on }">
                <v-btn
                  flat
                  small
                  icon
                  @click.stop="editLocalLabel = !editLocalLabel"
                  @mousedown.stop
                  class="edit-btn mb-0"
                  v-on="on"
                  :disabled="readOnlyMode"
                >
                  <v-icon>{{ editLocalLabel ? 'mdi-check' : 'mdi-pencil' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ editLocalLabel ? 'Save changes' : 'Edit Modifier Group Name' }}</span>
            </v-tooltip>
          </div>
          <div
            v-if="showGlobalModGroupName"
            class="Caption-Selected-On-Surface-High-Emphasis-Left secondary-text mt-0 display-flex justify-space-between align-center f-wrap"
            style="margin-left: 5.2rem;"
          >
            <div><strong>Original Name:</strong> {{ globalModGroupName }}</div>
            <v-tooltip bottom v-if="!isModGroupLabelDisabled">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  flat
                  icon
                  round
                  small
                  color="gray"
                  @click.stop="setModGroupName(globalModGroupName)"
                  class="clickable tiny-btn"
                >
                  <v-icon>mdi-restore</v-icon>
                </v-btn>
              </template>
              <span>Use Original Name</span>
            </v-tooltip>
          </div>
        </div>
        <div class="display-flex align-center" v-if="isVersion2">
          <v-icon class="icon">{{ expandIcon }}</v-icon>
          <span
            class="Body-1-Selected-On-Surface-High-Emphasis-Left"
            v-if="!editLabel"
            style="pointer-events: none;"
          >
            {{ groupProxy.unique_name }}
          </span>
          <v-select
            v-else
            @click.native.stop
            :disabled="readOnlyMode"
            ref="uniqueName"
            :items="getGlobalModGroupsToDisplay"
            :value="groupProxy"
            item-text="unique_name"
            item-value="id"
            :rules="selectRule"
            @change="selectGlobalMod"
            :placeholder="groupProxy.unique_name"
          ></v-select>
        </div>
        <div class="display-flex align-center f-wrap" style="max-width: 27rem" v-if="isVersion2">
          <div class="display-flex align-center">
            <v-text-field
              v-if="editLocalLabel && !isModGroupLabelDisabled"
              label="Label In App"
              :value="groupProxy.label.en"
              @input="setModGroupName($event)"
              @click.stop
              :rules="rules"
            />
            <span class="Body-1-Selected-On-Surface-High-Emphasis-right" v-else>
              Label in App: {{ groupProxy.label.en }}
            </span>
            <v-tooltip bottom v-if="!isModGroupLabelDisabled">
              <template v-slot:activator="{ on }">
                <v-btn
                  flat
                  small
                  icon
                  @click.stop="editLocalLabel = !editLocalLabel"
                  @mousedown.stop
                  class="edit-btn mb-0"
                  v-on="on"
                  :disabled="readOnlyMode"
                >
                  <v-icon>{{ editLocalLabel ? 'mdi-check' : 'mdi-pencil' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ editLocalLabel ? 'Save changes' : 'Edit Modifier Group Name' }}</span>
            </v-tooltip>
          </div>
          <div
            v-if="showGlobalModGroupName"
            class="Caption-Selected-On-Surface-High-Emphasis-Left secondary-text mt-0 display-flex justify-space-between align-center f-wrap"
          >
            <div><strong>Original Name:</strong> {{ globalModGroupName }}</div>
            <v-tooltip bottom v-if="!isModGroupLabelDisabled">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  flat
                  icon
                  round
                  small
                  color="gray"
                  @click.stop="setModGroupName(globalModGroupName)"
                  class="clickable tiny-btn"
                >
                  <v-icon>mdi-restore</v-icon>
                </v-btn>
              </template>
              <span>Use Original Name</span>
            </v-tooltip>
          </div>
        </div>
        <div class="display-flex align-center">
          <template v-if="!isLocal">
            <v-btn
              flat
              icon
              class="ma-0"
              v-show="showActions"
              @click.stop="$emit('modifierGroup:copy')"
              v-on:keydown.enter.stop.prevent="() => {}"
              :disabled="readOnlyMode"
              v-if="isVersion1"
            >
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
            <v-btn
              flat
              icon
              class="ma-0"
              v-show="showActions"
              @click.stop="$emit('modifierGroup:delete')"
              v-on:keydown.enter.stop.prevent="() => {}"
              :disabled="readOnlyMode"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
            <div class="mr-1"></div>
          </template>
          <v-checkbox
            v-if="!isLocal"
            v-on:change="(v) => toggleModifierGroup(v, 'disabled')"
            v-model="groupProxy.is.disabled"
            hide-details
            color="secondary"
            @click.native.stop
            :false-value="true"
            :true-value="false"
            :disabled="readOnlyMode || isVersion2"
            class="pt-0 mt-0 mr-4"
          />
          <div v-if="isLocal">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-checkbox
                    v-if="isLocal"
                    v-on:change="(v) => toggleModifierGroup(v, 'hidden')"
                    v-model="groupProxy.is.hidden"
                    hide-details
                    on-icon="mdi-eye-off"
                    off-icon="mdi-eye"
                    :color="hiddenColor"
                    @click.native.stop
                    :false-value="false"
                    :true-value="true"
                    class="pt-0 mt-0 mr-2"
                    v-on="on"
                    :disabled="readOnlyMode || isDisabledForPLU"
                  />
                </div>
              </template>
              <span v-if="isDisabledForPLU">Requires PLU</span>
              <span v-else>
                <span v-if="groupProxy.is.hidden">Hidden in App</span>
                <span v-else>Display in App</span>
              </span>
            </v-tooltip>
          </div>
          <v-checkbox
            v-if="isLocal"
            v-model="outOfStockProxy"
            hide-details
            color="secondary"
            @click.native.stop
            v-on:change="(v) => toggleModifierGroup(v, 'out_of_stock')"
            :false-value="true"
            :true-value="false"
            class="pt-0 mt-0 mr-4"
            :disabled="readOnlyMode || isDisabledForPLU"
          />
          <span v-if="showPromoExemptField">
            <v-switch
              inset
              v-model="promoExemptProxy"
              hide-details
              @click.native.stop
              :false-value="false"
              :true-value="true"
              class="pt-0 mt-0 mr-4"
              color="green"
              v-bind:class="{ switchenabled: !promoExemptProxy }"
              :disabled="readOnlyMode || isSiteOperator"
            />
            <div class="promo_exempt_label">{{ promoExemptProxy ? 'Yes' : 'No' }}</div>
          </span>
          <v-flex class="sort_number">
            <v-text-field
              type="number"
              min="0"
              label
              :value="sortNumberProxy"
              @input="sortNumberProxy = parseInt($event, 10) || undefined"
              :rules="sortNumberRules"
              v-on:keydown.enter.stop.prevent="() => {}"
              :disabled="isSortNumberDisabled"
            />
          </v-flex>
        </div>
      </v-layout>
    </div>
    <div class="modifiers-list" v-show="expand">
      <div class="Body-1-Black-Medium-Emphasis-Left">How many items can the customer choose?</div>
      <v-layout class="size-line" row wrap align-baseline>
        <v-flex xs4 style="margin-right: 24px;" shrink>
          <v-select
            :items="modifierTypes"
            v-model="modifierValue"
            style
            @change="updateMinMax"
            :disabled="isDisabled"
          />
        </v-flex>
        <template v-if="modifierValue === 'Range'">
          <v-flex xs2 shrink>
            <v-text-field
              v-model="minProxy"
              :disabled="isDisabled"
              :rules="rangeMinRules"
              :error-messages="errorRangeMin"
              type="number"
              v-on:keydown.enter.stop.prevent="() => {}"
            />
          </v-flex>
          <v-flex shrink style="margin: 0px 24px;">to</v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="maxProxy"
              :disabled="isDisabled"
              :rules="rangeMaxRules"
              :error-messages="errorRangeMax"
              type="number"
              v-on:keydown.enter.stop.prevent="() => {}"
            />
          </v-flex>
        </template>
        <template v-if="modifierValue === 'Minimum'">
          <v-flex xs2 shrink>
            <v-text-field
              v-model="minProxy"
              :rules="requiredRule"
              :disabled="isDisabled"
              type="number"
              v-on:keydown.enter.stop.prevent="() => {}"
            />
          </v-flex>
        </template>
        <template v-if="modifierValue === 'Maximum'">
          <v-flex xs2 shrink>
            <v-text-field
              v-model="maxProxy"
              :disabled="isDisabled"
              :rules="requiredRule"
              type="number"
              v-on:keydown.enter.stop.prevent="() => {}"
            />
          </v-flex>
        </template>
        <template v-if="modifierValue === 'Exactly'">
          <v-flex xs2 shrink>
            <v-text-field
              v-model="exactValue"
              :disabled="isDisabled"
              :rules="requiredRule"
              type="number"
              v-on:keydown.enter.stop.prevent="() => {}"
            />
          </v-flex>
        </template>
      </v-layout>
      <v-text-field
        :rules="modifierMessageRule"
        :error-messages="modifierMessage"
        class="custom-validation"
      />
      <draggable
        v-model="group.items"
        v-if="group.items"
        sort="false"
        @end="updateSorting"
        class="pa-0"
      >
        <modifier
          v-for="(modifier, index) in group.items"
          :key="modifier.id || index"
          :modifier.sync="modifier"
          @modifier:groupaction="modifyGroupAction()"
          @modifier:delete="deleteModifier(index)"
          @modifier:copy="copyModifier(index)"
          @modifier:invalid="expandModifierGroup()"
          :isDisabledForPLU="isDisabledForPLU"
          :isCaloriesDisabled="isCaloriesDisabled"
          :isLabelDisabled="isLabelDisabled"
          :currentGroup="groupProxy"
          :parentModifier="getParentModifier(modifier.id)"
        />
      </draggable>
      <v-layout v-if="!isLocal && !readOnlyMode">
        <v-flex shrink>
          <v-btn class="ml-0 pl-0" flat color="primary" @click="addModifier">
            <v-icon>mdi-plus</v-icon>Add MODIFIER
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-flex>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import rules from '@/rules';
import modifier from './modifier';

export default {
  props: {
    group: Object,
    isDisabledForPLU: Boolean,
    isCaloriesDisabled: Boolean,
    isLabelDisabled: Boolean,
    companyGlobalModGroups: Array,
    currentItem: Object,
    parentModGroup: Object,
  },
  components: {
    modifier,
    draggable,
  },
  data: () => ({
    expand: false,
    showActions: false,
    modifierTypes: ['Range', 'Minimum', 'Maximum', 'No limit', 'Exactly'],
    modifierValue: 'Range',
    editLabel: false,
    editLocalLabel: false,
    rules: [rules.required('Group name is required')],
    rangeMinRules: [rules.required('Required')],
    rangeMaxRules: [rules.required('Required')],
    requiredRule: [rules.required('Required')],
    selectRule: [rules.requiredObjectField('Required', 'id')],
    sortNumberRules: [rules.min(1)],
    modifierMessageRule: [],
  }),
  watch: {
    modifierMessage: {
      handler(val) {
        if (val) {
          this.modifierMessageRule = [rules.required(val)];
        } else {
          this.modifierMessageRule = [];
        }
      },
    },
  },
  computed: {
    ...mapGetters('adminPanel', ['isSiteOperator', 'isImUser', 'isAdmin']),
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('menus', [
      'isLocal',
      'showPromoExemptField',
      'readOnlyMode',
      'menuVersion',
      'showPluField',
    ]),
    expandIcon() {
      return this.expand ? 'mdi-chevron-down' : 'mdi-chevron-up';
    },
    outOfStockProxy: {
      get() {
        if (this.groupProxy.is && this.groupProxy.is.out_of_stock) {
          return this.groupProxy.is.out_of_stock;
        }
        return false;
      },
      set(v) {
        this.groupProxy = {
          ...this.groupProxy,
          is: {
            ...this.groupProxy.is,
            out_of_stock: v,
          },
        };
      },
    },
    promoExemptProxy: {
      get() {
        const are_all_items_exempt =
          Array.isArray(this.groupProxy.items) &&
          this.groupProxy.items.every(
            (i) =>
              Array.isArray(i.amount_off_exclusions) && i.amount_off_exclusions.includes('promo'),
          );
        return are_all_items_exempt;
      },
      set(v) {
        const group_copy = cloneDeep(this.groupProxy);
        if (Array.isArray(group_copy.items)) {
          group_copy.items.forEach((i) => {
            i.amount_off_exclusions = i.amount_off_exclusions || [];
            if (v === true && !i.amount_off_exclusions.find((e) => e === 'promo')) {
              i.amount_off_exclusions.push('promo');
            }
            if (v === false) {
              i.amount_off_exclusions = i.amount_off_exclusions.filter((e) => e !== 'promo');
            }
          });
        }
        this.groupProxy = {
          ...this.groupProxy,
          items: group_copy.items,
        };
      },
    },
    groupProxy: {
      get() {
        return this.group;
      },
      set(value) {
        this.$emit('update:group', value);
      },
    },
    minProxy: {
      get() {
        return this.groupProxy.min;
      },
      set(v) {
        this.groupProxy = {
          ...this.groupProxy,
          min: parseInt(v, 10),
        };
      },
    },
    maxProxy: {
      get() {
        return this.groupProxy.max;
      },
      set(v) {
        this.groupProxy = {
          ...this.groupProxy,
          max: parseInt(v, 10),
        };
      },
    },
    sortNumberProxy: {
      get() {
        return this.groupProxy.meta && this.groupProxy.meta.sort_number;
      },
      set(v) {
        this.groupProxy = {
          ...this.groupProxy,
          meta: { ...this.groupProxy.meta, sort_number: v },
        };
      },
    },
    exactValue: {
      get() {
        return this.maxProxy;
      },
      set(value) {
        this.groupProxy = {
          ...this.groupProxy,
          max: parseInt(value, 10),
          min: parseInt(value, 10),
        };
      },
    },
    errorRangeMin() {
      if (this.group.min > this.group.max) {
        return 'Must be less than max';
      }
      return '';
    },

    errorRangeMax() {
      if (this.group.max < this.group.min) {
        return 'Must be greater than min';
      }
      return '';
    },

    modifierMessage() {
      if (this.groupProxy.min && this.groupProxy.min > this.groupProxy.items.length) {
        return `There must be at least ${this.groupProxy.min} modifiers`;
      }

      if (this.groupProxy.max && this.groupProxy.max > this.groupProxy.items.length) {
        return `There must be at least ${this.groupProxy.max} modifiers`;
      }

      if (this.groupProxy.items.length === 0) {
        return 'You must have at least one modifier';
      }

      return '';
    },
    hiddenColor() {
      if (this.isDisabledForPLU) return 'gray';
      if (this.groupProxy.is.hidden) {
        return 'red';
      }
      return 'green';
    },
    isVersion1() {
      return this.menuVersion === 1;
    },
    isVersion2() {
      return this.menuVersion === 2;
    },
    getGlobalModGroupsToDisplay() {
      if (!this.companyGlobalModGroups || this.companyGlobalModGroups.length === 0) return [];

      const modGroups = this.companyGlobalModGroups.filter(
        (mg) => !this.currentItem.options.find((d) => d.id === mg.id) && !mg.is.disabled,
      );
      const currentModGroup = this.companyGlobalModGroups.find((m) => m.id === this.groupProxy.id);
      if (currentModGroup) {
        modGroups.push(currentModGroup);
      }

      return modGroups.sort((a, b) => (a.unique_name < b.unique_name ? -1 : 1));
    },
    isDisabled() {
      return (
        !(this.isAdmin || this.isSiteOperator || this.isImUser) ||
        this.isDisabledForPLU ||
        this.readOnlyMode ||
        (this.isVersion2 && !this.isLocal)
      );
    },
    globalModGroupModifers() {
      if (this.isVersion1) return [];
      const currentModGroup = this.companyGlobalModGroups.find((m) => m.id === this.groupProxy.id);
      if (!currentModGroup) return [];
      return currentModGroup.items ? currentModGroup.items : [];
    },
    isSortNumberDisabled() {
      return this.readOnlyMode || this.isDisabledForPLU || (!this.isLocal && this.isVersion2);
    },
    isModGroupLabelDisabled() {
      return this.isLabelDisabled || !this.isLocal;
    },
    globalModGroupName() {
      if (!this.parentModGroup) return '';
      return (this.parentModGroup.label && this.parentModGroup.label.en) || '';
    },
    showGlobalModGroupName() {
      if (!this.isLocal || !this.globalModGroupName) return false;
      const localModGroupName = (this.groupProxy.label && this.groupProxy.label.en) || '';
      return this.globalModGroupName !== localModGroupName;
    },
  },
  methods: {
    ...mapActions('menus', ['fetchGlobalModGroup']),
    deleteModifier(index) {
      this.groupProxy.items.splice(index, 1);
    },
    addModifier() {
      this.groupProxy.items.push({
        is: {
          disabled: false,
        },
        meta: {},
        label: { en: '' },
        price: { amount: 0 },
      });
    },
    copyModifier(index) {
      const cloneItem = cloneDeep(this.groupProxy.items[index]);
      cloneItem.label.en += ' copy';
      delete cloneItem.id;
      this.groupProxy.items.push(cloneItem);
    },
    async updateMinMax(v) {
      switch (v) {
        case 'Exactly':
          this.groupProxy.max = null;
          this.groupProxy.min = this.groupProxy.max;
          break;
        case 'No limit':
          this.groupProxy.min = null;
          this.groupProxy.max = null;
          break;
        case 'Minimum':
          this.groupProxy.max = null;
          break;
        case 'Maximum':
          this.groupProxy.min = null;
          break;
        default:
          break;
      }
      await this.$nextTick();
      this.$emit('validateform');
    },
    doneEdit() {
      if (this.groupProxy.label.en.length > 0) {
        this.editLabel = false;
        this.expand = true;
        if (this.groupProxy.items.length === 0) {
          this.addModifier();
        }
      }
    },
    modifyGroupAction() {
      const items = this.group && this.group.items;
      const isGroupOutOfStock = items.every((i) => i.is.out_of_stock);
      const isGroupHidden = items.every((i) => i.is.hidden);
      const isGroupDisabled = !items.some((i) => !i.is.disabled);
      this.groupProxy = {
        ...this.groupProxy,
        is: {
          ...this.groupProxy.is,
          out_of_stock: isGroupOutOfStock,
          hidden: isGroupHidden,
          disabled: isGroupDisabled,
        },
      };
    },
    expandModifierGroup() {
      this.expand = true;
    },
    toggleModifierGroup(e, property) {
      this.$set(this.groupProxy.is, property, e);
      // set all the modifiers to the same value
      if (this.group && this.group.items && this.group.items.length > 0) {
        this.group.items.forEach((i) => {
          if (!i.is) this.$set(i, 'is', {});
          const isOverrideAllowed =
            property !== 'hidden' || (property === 'hidden' && (e || this.isModifierPluExist(i)));

          if (!isOverrideAllowed) return;
          this.$set(i.is, property, e);
        });
      }
    },
    async expandAndStartEdit() {
      this.expand = !this.expand;
      if (this.expand) {
        if (this.isDisabledForPLU || this.isLocal) return;
        this.startEdit();
      } else {
        this.editLabel = false;
      }
    },
    async startEdit() {
      this.editLabel = true;
      await this.$nextTick();
      if (this.isVersion1) this.$refs.labelinput.focus();
      if (this.isVersion2) this.$refs.uniqueName.focus();
    },
    updateSorting(event) {
      if (this.readOnlyMode) return;
      this.setMenuSortNumber(event.newIndex, this.group.items);
    },
    setModGroupName(name) {
      if (this.readOnlyMode || !this.isLocal) return;
      this.groupProxy = {
        ...this.groupProxy,
        label: { ...this.groupProxy.label, en: name },
      };
    },
    setMenuSortNumber(moved_record_index, records = []) {
      let last_record_with_sort_number_index = null;
      for (let i = records.length - 1; i >= 0; i -= 1) {
        const record = records[i];
        if (record && record.meta && record.meta.menu_sort_number) {
          last_record_with_sort_number_index = i;
          break;
        }
      }
      if (moved_record_index > last_record_with_sort_number_index) {
        last_record_with_sort_number_index = moved_record_index;
      }

      for (let i = 0; i <= last_record_with_sort_number_index; i += 1) {
        const record = records[i];
        this.$set(record, 'meta', { ...record.meta, menu_sort_number: i + 1 });
      }
    },
    async selectGlobalMod(id) {
      const selectedModGroup = await this.fetchGlobalModGroup({ id });
      this.groupProxy = { ...selectedModGroup };
      await this.$nextTick();
    },
    setMinMax(min, max) {
      if (min === undefined) min = null;
      if (max === undefined) max = null;
      if (min === null && max === null) {
        this.modifierValue = 'No limit';
      } else if (max === null) {
        this.modifierValue = 'Minimum';
      } else if (min === null) {
        this.modifierValue = 'Maximum';
      } else if (min === max) {
        this.modifierValue = 'Exactly';
      } else {
        this.modifierValue = 'Range';
      }
    },
    getParentModifier(id) {
      if (!this.parentModGroup || !this.parentModGroup.items) return null;
      return this.parentModGroup.items.find((i) => i.id === id);
    },
    isModifierPluExist(mod) {
      if (this.isLocal && this.isDisabledForPLU) return false;
      if (
        this.isLocal &&
        this.showPluField &&
        ((mod.meta && mod.meta.plu === undefined) ||
          mod.meta.plu === null ||
          mod.meta.plu.toString().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  beforeMount() {
    if (!this.groupProxy.is) {
      this.groupProxy.is = {
        disabled: false,
        out_of_stock: false,
        hidden: false,
      };
    }
  },
  mounted() {
    // determine selection type
    const { min, max } = this.groupProxy;
    this.setMinMax(min, max);
    if (!this.groupProxy.label.en) {
      this.startEdit();
    }
  },
};
</script>

<style scoped>
.modifier-group {
  cursor: pointer;
  padding-right: 12px;
}

.modifier-group:hover {
  background-color: #f5f5f5;
}

.icon {
  margin: 10px 24px;
}

.modifiers-list {
  padding: 10px 0px;
  background-color: #fbfbfb;
}

.modifiers-list > * {
  padding: 0px 12px 0px 24px;
}

div >>> .size-line .v-text-field__slot input {
  padding-left: 24px;
}
.flex.sort_number {
  max-width: 3.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 2.5rem;
  margin-left: auto;
}
</style>
