<template>
  <div @keydown.esc="vcoConfig.handler()">
    <v-navigation-drawer
      absolute
      temporary
      right
      v-if="open"
      :value="open"
      app
      :width="900"
      stateless
      v-click-outside="vcoConfig"
      id="itemconfigpaneldrawer"
      v-bind:style="{ height: 'calc(100vh - 64px)', top: `${pageYOffset}px` }"
    >
      <v-layout style="margin: 3em 0 0 3.3em;">
        <v-layout
          @click="setActiveTab('info')"
          style="flex-grow: unset; margin-right: 3.285em;"
          xs4
        >
          <div :class="['tab', isInfoTab ? 'tab-active-color tab-active-border' : 'tab-inactive']">
            Item Information
          </div>
        </v-layout>
        <v-layout column>
          <v-layout v-if="showItemImageTab" @click="setActiveTab('image')" style="flex-grow: unset">
            <div :class="['tab', isImageTab ? 'tab-active-color' : 'tab-inactive']">
              Item Image
            </div>
            <div style="width: 1.5em; margin: 0.75em 0 0 0.857142em;">
              <v-img
                v-if="isFirstMenuSet"
                :src="
                  isImageTab
                    ? require('@/assets/image-lock.svg')
                    : require('@/assets/image-lock-grey.svg')
                "
              ></v-img>
            </div>
          </v-layout>
          <div
            :style="{
              borderBottom: isImageTab ? '0.225em solid var(--v-primary-base)' : '',
              width: this.isFirstMenuSet ? '12em' : '10em',
            }"
          />
        </v-layout>
      </v-layout>
      <v-layout
        style="margin-top: -0.09em; border-bottom: 0.125em solid var(--v-light-grey-base)"
      />
      <v-form
        v-if="isInfoTab"
        ref="form"
        :class="configClass"
        style="background-color: var(--v-grey-white-base);"
        eager
      >
        <v-layout column>
          <v-flex shrink>
            <v-container grid-list-xl class="pb-0" fluid v-if="itemClone.label">
              <v-layout row wrap>
                <v-flex xs11>
                  <v-combobox
                    v-model="itemClone.label.en"
                    :loading="!isSiteOperator && fetchingItems"
                    :items="items"
                    :search-input.sync="search"
                    cache-items
                    hide-no-data
                    label="Item Name"
                    :rules="itemNameRules"
                    :disabled="isLabelDisabled"
                  ></v-combobox>
                  <div
                    v-if="showGlobalItemName"
                    class="Caption-Selected-On-Surface-High-Emphasis-Left secondary-text mt-0 display-flex align-center"
                  >
                    <div><strong>Original Name:</strong> {{ globalItemName }}</div>
                    <v-tooltip bottom v-if="!isLabelDisabled">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          flat
                          icon
                          round
                          small
                          color="gray"
                          @click.stop="setItemName(globalItemName)"
                          class="clickable tiny-btn"
                        >
                          <v-icon>mdi-restore</v-icon>
                        </v-btn>
                      </template>
                      <span>Use Original Name</span>
                    </v-tooltip>
                  </div>
                </v-flex>
                <v-flex class="sort_number">
                  <v-text-field
                    type="number"
                    label="Sequence On Ticket"
                    v-model.number="sortNumberProxy"
                    :rules="sortNumberRules"
                    v-on:keydown.enter.stop.prevent="() => {}"
                    :disabled="readOnlyMode || isDisabledForPLU"
                    @keypress="blockDecimals"
                  />
                </v-flex>
                <v-layout column>
                  <v-flex d-flex>
                    <v-flex xs5>
                      <v-currency-field
                        v-if="open"
                        label="Price"
                        v-model="itemClone.price.amount"
                        :rules="priceRules"
                        :disabled="readOnlyMode || isDisabledForPLU"
                      />
                    </v-flex>
                    <!-- mx price -->
                    <v-flex v-if="isLocal" xs5>
                      <v-text-field
                        v-model="itemClone.meal_value"
                        :rules="mealValueRules"
                        :disabled="readOnlyMode"
                        @input="itemClone.meal_value = parseInt($event, 10) || null"
                        label="Meal Value"
                      />
                    </v-flex>
                  </v-flex>
                  <v-flex d-flex>
                    <v-flex xs6>
                      <v-text-field
                        type="number"
                        label="Calories"
                        v-model="calories"
                        :disabled="isCaloriesDisabled"
                        :rules="calorieRules"
                        v-on:keydown.enter.stop.prevent="() => {}"
                        @keypress="blockDecimals"
                      />
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        label="Units"
                        v-model.number="unit"
                        :disabled="readOnlyMode || isLocal"
                        :rules="unitRules"
                        type="number"
                      />
                    </v-flex>
                  </v-flex>
                  <v-flex d-flex>
                    <v-flex xs6>
                      <v-text-field
                        label="Barcode"
                        v-model="barcode"
                        :disabled="readOnlyMode || isLocal"
                      />
                    </v-flex>
                    <v-flex v-if="showPluField" xs6>
                      <v-text-field
                        label="PLU ID"
                        v-model="plu"
                        :disabled="readOnlyMode || !canEditPlu"
                        @keypress="blockDecimals"
                      />
                    </v-flex>
                  </v-flex>
                </v-layout>
                <v-flex xs12>
                  <v-textarea
                    auto-grow
                    label="Description"
                    rows="1"
                    v-model="itemClone.description.en"
                    :rules="descriptionRules"
                    :disabled="isItemDescDisabled"
                  />
                </v-flex>
                <v-flex xs12 v-if="isLocal && isUSLocationBrand">
                  <v-checkbox
                    label="Tax Exempt"
                    v-model="isTaxExempted"
                    hide-details
                    color="secondary"
                    @click.native.stop
                    class="pt-0 mt-0 mr-4 d-inline-block"
                    :disabled="readOnlyMode"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-select
                    :items="taxOptions"
                    label="Tax Tags"
                    v-model="itemClone.meta.taxes"
                    multiple
                    clearable
                    :disabled="readOnlyMode || isLocal"
                    chips
                  ></v-select>
                  <v-text-field
                    label="Tax Tag (pcc)"
                    v-model="pccTaxTagProxy"
                    clearable
                    :disabled="readOnlyMode || isLocal"
                  />
                </v-flex>
                <v-flex xs12 class="display-flex">
                  <div class="H6-Secondary-Left flex xs9">Modifiers</div>
                  <div
                    class="display-flex align-center justify-end text--secondary flex xs3 pr-0"
                    v-if="!isLocal"
                  >
                    Item Enabled
                  </div>
                  <div
                    class="display-flex align-center justify-end text--secondary flex xs3"
                    v-if="isLocal"
                  >
                    In Stock
                  </div>
                  <div
                    class="display-flex align-center justify-end text--secondary flex xs1 mr-0 ml-2"
                    v-if="showPromoExemptField"
                  >
                    P.Exempt
                  </div>
                  <div
                    class="display-flex align-center justify-end text--secondary flex xs1 modifier_sort_number"
                  >
                    Sequence On Ticket
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
          <v-flex shrink>
            <loading-wrapper :loading="loadingModifierGroups" :size="30" mt="2vh">
              <v-layout row wrap>
                <draggable
                  v-model="itemClone.options"
                  v-if="itemClone.options"
                  sort="false"
                  @end="updateSorting"
                  class="flex xs12"
                >
                  <modifier-group
                    v-for="(option, index) in itemClone.options"
                    :key="option._created || option.id"
                    v-bind:group="option"
                    v-on:update:group="updateModifierGroup(index, $event)"
                    @modifierGroup:delete="deleteModifierGroup(index)"
                    @modifierGroup:copy="copyModifierGroup(index)"
                    :isDisabledForPLU="isDisabledForPLU"
                    :isCaloriesDisabled="isCaloriesDisabled"
                    :isLabelDisabled="isLabelDisabled"
                    @validateform="$refs.form.validate()"
                    :companyGlobalModGroups="companyGlobalModGroups"
                    :currentItem="itemClone"
                    :parentModGroup="getParentModGroup(option.id)"
                  />
                </draggable>
              </v-layout>
            </loading-wrapper>
            <v-layout row justify-center v-if="!isLocal">
              <v-flex>
                <v-btn
                  flat
                  color="primary"
                  :disabled="readOnlyMode || !getIsModifierGroupAvailable()"
                  @click="addModifierGroup"
                >
                  <v-icon>mdi-plus</v-icon>Add modifier group
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
      <v-layout
        v-if="isImageTab && !isFirstMenuSet"
        style="background-color: var(--v-grey-white-base);"
        column
      >
        <v-card class="d-flex ma-4" style="flex-direction: row;">
          <v-layout column class="mt-4">
            <v-layout column>
              <v-card-text
                class="pb-2"
                style="color: var(--v-primary-base); font-size: 1.5em; font-weight: 400;"
              >
                Add Image
              </v-card-text>
              <v-card-text style="font-size: 1.2em; opacity: 0.9">
                Accompany this item with an inviting image
              </v-card-text>
            </v-layout>
            <v-layout style="margin-bottom: 2em;">
              <v-flex>
                <v-btn
                  @click="selectImage"
                  :disabled="isImageUploadDisabled"
                  color="primary"
                  class="ml-4"
                  style="width: 54.5%;"
                >
                  <v-img
                    :src="
                      isImageUploadDisabled
                        ? require('@/assets/file-image-plus-outline-disabled.svg')
                        : require('@/assets/file-image-plus-outline.svg')
                    "
                    class="mr-1 mb-1"
                  />
                  Upload Image
                </v-btn>
                <v-card-text class="mt-3 phone-display-title">Image Requirements</v-card-text>
                <v-flex class="d-flex">
                  <v-card-text class="phone-display-title" style=" width: 50%">Size:</v-card-text>
                  <v-card-text style="margin-left: -8.5em;  font-weight: 400; opacity: 0.8;"
                    >1200px by 800px (recommended)
                  </v-card-text>
                </v-flex>
                <v-flex class="d-flex" style="margin-top: -1em;">
                  <v-card-text class="phone-display-title" style="width: 50%">Format: </v-card-text>
                  <v-card-text style="margin-left: -6em; font-weight: 400; opacity: 0.8;"
                    >JPG, JPEG or PNGs only
                  </v-card-text>
                </v-flex>
              </v-flex>
              <v-layout column align-end>
                <v-img
                  :src="imageSrcProxy || require('@/assets/item-image-placeholder.svg')"
                  width="350"
                  height="100"
                  class="mr-4"
                  :style="{
                    border: this.imageSrcProxy ? '' : '0.125em dashed var(--v-dark-grey-lighten1)',
                  }"
                />
                <image-uploader
                  :imageUrl.sync="imageSrcProxy"
                  :width="324"
                  :height="143"
                  :placeholder="isLocal ? 'Apply available image' : 'Upload item image'"
                  :disabled="isImageUploadDisabled"
                  :showRemoveButton="isAdmin"
                  :uploadConfig="uploadConfig"
                  :s3SubDir="`${getStage}:${activeMenuId}:${item.id}/sizes`"
                  s3Folder="menu_item_images"
                  ref="itemImage"
                  imageTypeSaved="jpeg"
                  accept=".png,.jpg,.jpeg"
                  storeOriginal
                  disableCropping
                  showPreviews
                  customControls
                />
              </v-layout>
            </v-layout>
            <v-layout class="mr-4" style="margin-bottom: 2em; " justify-end>
              <div v-if="!isImageUploadDisabled" @click="removeImage" class="mr-3 remove-button">
                REMOVE IMAGE
              </div>
            </v-layout>
          </v-layout>
        </v-card>
        <v-card class="d-flex mx-4" style="flex-direction: row;">
          <v-layout class="mt-2" column>
            <v-layout class="mt-4" column>
              <v-card-text
                class="pb-2"
                style="color: var(--v-primary-base); font-size: 1.5em; font-weight: 400;"
              >
                Preview Image
              </v-card-text>
              <v-card-text style="font-size: 1.2em; opacity: 0.9">
                See how the item image will display in app
              </v-card-text>
            </v-layout>
            <v-layout>
              <v-layout class="mt-1" style="width: 50%" column>
                <v-card-text
                  class="pb-2"
                  style="color: var(--v-dark-blue-base); font-size: 1.5em; font-weight: 500;"
                >
                  Menu Level
                </v-card-text>
                <v-card-text class="py-0 phone-display-title-dark">
                  A thumbnail image will display next
                </v-card-text>
                <v-card-text class="py-0 phone-display-title-dark">
                  to item on Menu screen
                </v-card-text>
              </v-layout>
              <v-layout
                :style="{
                  backgroundImage: `url(${require('@/assets/i-phone-large.svg')})`,
                  marginLeft: '12%',
                  width: '86%',
                  height: '19em',
                  position: 'relative',
                }"
                class="phonepreview"
                column
                wrap
                align-center
              >
                <v-layout
                  class="mx-2"
                  style="max-width: 25em; min-width: 25em; margin-top: 7em; position: absolute;"
                  align-center
                >
                  <v-layout style="width: 75%; padding-left: 1em;" column>
                    <div class="mb-1" style="font-weight: 600; word-break: break-word;">
                      {{ thumbnailLabel || 'Item Name' }}
                    </div>
                    <v-layout style="word-break: break-word;" column>
                      <div style="width: 90%; font-size: 0.8em;">
                        {{ thumbnailDescription || 'Description' }}
                      </div>
                    </v-layout>
                    <v-layout
                      class="justify-space-between"
                      :style="{ width: '95%', marginTop: isTextLong ? '1em' : '3.5em' }"
                    >
                      <div style="color: var(--v-turqouise-base)">${{ floatingPrice }}</div>
                      <div style="font-size: 0.8em">
                        {{ calories ? `${calories} cals` : 'Calories' }}
                      </div>
                    </v-layout>
                  </v-layout>
                  <v-layout style="margin-top: 2em;">
                    <v-img
                      :src="getThumbnailImage || require('@/assets/item-image-placeholder.svg')"
                      contain
                      width="80"
                      height="80"
                      style="border-radius: 0.5em;"
                    />
                  </v-layout>
                </v-layout>
                <div
                  class="thumbnail-image-border"
                  :style="{ top: isTextLong ? '19em' : '16.5em' }"
                ></div>
              </v-layout>
            </v-layout>
            <v-layout>
              <v-layout class="mt-1" style="width: 50%" column>
                <v-card-text
                  class="pb-2"
                  style="color: var(--v-dark-blue-base); font-size: 1.5em; font-weight: 500;"
                >
                  Item Level
                </v-card-text>
                <v-card-text class="py-0" style="font-size: 1em; opacity: 0.9">
                  A large image will display in the
                </v-card-text>
                <v-card-text class="py-0" style="font-size: 1em; opacity: 0.9">
                  header of Item Modifiers screen
                </v-card-text>
              </v-layout>
              <v-layout
                :style="{
                  backgroundImage: `url(${this.iPhoneBackgroundHero})`,
                  marginLeft: '12%',
                  width: '86%',
                }"
                class="phonepreview"
                column
                wrap
                align-center
              >
                <v-layout column>
                  <v-img
                    :src="imageSrcProxy || require('@/assets/item-image-placeholder.svg')"
                    :class="[imageSrcProxy ? 'hero-image' : 'hero-image-placeholder']"
                    width="374"
                    height="256"
                  />
                </v-layout>

                <v-img :src="require('@/assets/chevron-left.svg')" class="chevron-left" />
                <div style="width: 17.8em; position: relative; bottom: 23.4em;">
                  <v-img :src="require('@/assets/i-phone-frame.svg')" />
                </div>
                <v-layout style="margin-top: -8em; text-align: center; max-width: 65%" column>
                  <div class="mt-3" style="font-weight: 600; word-break: break-word;">
                    {{ itemClone.label.en || 'Item Name' }}
                  </div>
                  <div class="my-3" style="font-size: 0.8em ">
                    ({{ calories ? `${calories} cals` : 'Calories' }})
                  </div>
                  <div style="font-size: 0.8em; word-break: break-word;">
                    {{ itemClone.description.en || 'Description' }}
                  </div>
                  <div class="mt-2 item-quantity-border d-flex align-self-center">
                    <v-layout style="margin-top: 0.45em;" justify-center>
                      <div class="mr-2" style="width: 1.25em;">
                        <v-img :src="require('@/assets/quantity-minus.svg')" />
                      </div>
                      <div class="mx-2 d-flex align-center" style="margin-top: -0.5em;">
                        1
                      </div>
                      <div class="ml-2" style="width: 1.25em;">
                        <v-img :src="require('@/assets/quantity-plus.svg')" />
                      </div>
                    </v-layout>
                  </div>
                </v-layout>
              </v-layout>
            </v-layout>
          </v-layout>
        </v-card>
      </v-layout>
      <v-layout
        v-if="isImageTab && isFirstMenuSet"
        style="background-color: var(--v-grey-white-base);"
        column
      >
        <div class="image-tab-locked">
          <v-img :src="require('@/assets/item-image-locked.svg')" />
        </div>
        <v-layout
          style="width: 50%; margin: 1.75em 0 20em 0;"
          column
          align-center
          align-self-center
        >
          <div class="mb-3" style="font-size: 1.6em; font-weight: 500; letter-spacing: 0.25px;">
            Item image disabled
          </div>
          <div style="font-size: 18px; letter-spacing: 0.5px; opacity: 0.6">
            To upload an item image, publish your menu first
          </div>
        </v-layout>
      </v-layout>

      <v-layout class="cta-container" row justify-center>
        <div
          v-if="!isFormValid && !isNewItem"
          style="position: absolute; bottom: 8px; left: 16px; color: #ff5252"
        >
          Form has errors. Please check your values.
        </div>
        <v-flex shrink>
          <v-btn
            color="primary"
            :loading="saving"
            @click="save"
            :disabled="
              !(
                modified &&
                isFormValid &&
                !isDescriptionLengthExceeded &&
                getIsModifierGroupsValid()
              )
            "
            >Apply Changes</v-btn
          >
          <v-btn flat @click="cancel" class="clickable">Cancel</v-btn>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import ID from '@compassdigital/id';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import rules from '@/rules';
import imageUploader from '@/components/imageUploader';
import FileHelperModule from '@/helpers/file-helper-module';
import { IMAGE_TEMPLATE, IMAGE_ENTITY_TYPES, PRICE_RULE } from '@/constants';
import modifierGroup from './modifierGroup';

export default {
  name: 'itemConfig',
  props: {
    item: Object,
    parentItem: Object,
    showItemImageTab: Promise,
    menu: String,
    hasDescEditPermission: Boolean,
    hasCaloriesEditPermissions: Boolean,
    hasLabelEditPermissions: Boolean,
    isUSLocationBrand: Boolean,
    taxOptions: Array,
    activeMenuId: String,
  },
  components: {
    modifierGroup,
    draggable,
    imageUploader,
  },
  data() {
    return {
      valid: false,
      itemClone: {},
      isFormValid: false,
      open: false,
      tax: [],
      itemNameRules: [
        rules.required('Required'),
        (v) => {
          this.isLabelLong = this.checkTextLength(v);
          return true;
        },
      ],
      priceRules: [rules.min(PRICE_RULE.min), rules.max(PRICE_RULE.max)],
      mealValueRules: [rules.wholeNumber()],
      calorieRules: [rules.min(0)],
      unitRules: [rules.min(1)],
      sortNumberRules: [rules.min(1)],
      descriptionRules: [
        (v) => {
          const isOverWordMax = v?.split(' ').length > 50;
          const isOverCharMax = v?.split('').length > 300;
          this.isDescriptionLong = this.checkTextLength(v);
          if (!isOverWordMax && isOverCharMax) {
            this.isDescriptionLengthExceeded = true;
            return 'Must be less than 300 characters';
          }
          if (isOverWordMax) {
            this.isDescriptionLengthExceeded = true;
            return 'Must be less than 50 words';
          }
          this.isDescriptionLengthExceeded = false;
          return true;
        },
      ],
      taxoptions: [
        'Alcohol',
        'Baked Goods',
        'Candy',
        'Carbonated Beverage',
        'Tax Exempt',
        'Pop',
        'Prepared',
        'Sweetened Beverage',
        'Unprepared',
        'Water',
        'Meal',
        'Good',
      ],
      isDescriptionLengthExceeded: false,
      isLabelLong: false,
      isDescriptionLong: false,
      fetchingItems: false,
      search: '',
      items: [],
      vcoConfig: {
        handler: async () => {
          if (this.open) {
            let shouldLeave = true;
            if (this.modified) {
              shouldLeave = await this.$confirm({
                title: 'You have unsaved changes',
                message: 'Are you sure you want to leave?',
                buttonTrueText: 'Yes',
                buttonFalseText: 'Cancel',
              });
            }
            if (shouldLeave) {
              this.open = false;
              this.vcoConfig.isActive = false;
            }
          }
        },
        events: ['dblclick', 'click'],
        middleware(event, el) {
          if (el.id === 'itemconfigpaneldrawer') {
            if (event.x < window.innerWidth - 600) {
              return true;
            }
          }
          return false;
        },
        isActive: false,
      },
      saving: false,
      uploadConfig: {
        hero_1200_800: { width: 1200, height: 800 },
        thumbnail_80_80: { width: 80, height: 80 },
      },
      activeTab: 'info',
      itemCloneBackup: {},
      loadingModifierGroups: false,
    };
  },
  watch: {
    search(val) {
      if (val && val.length > 1 && !this.isLocal) {
        // this.queryItems(val); //commenting for now since endpoint is broken
      }
      this.itemClone.label.en = val === null ? '' : val;
    },
    itemClone: {
      async handler() {
        if (this.isInfoTab) {
          // don't validate on new item, yet if it was set as valid we need to start validating
          if (this.isNewItem && !this.isFormValid) return;
          // Ensures we validate on item mount incase there are incompatible values
          const validate = debounce(() => {
            // Use debounce so we dont have to chain await nextTick()
            if (!this.$refs?.form) {
              validate();
            } else {
              this.isFormValid = this.$refs?.form?.validate();
            }
          }, 10);
          validate();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    document.addEventListener('keyup', this.handleEscape);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEscape);
  },
  methods: {
    ...mapMutations('file', ['setNewImageMap', 'setOriginalImageObjectMap']),
    ...mapActions('file', ['cleanImages']),
    ...mapActions('menus', ['getMenuItems']),
    ...FileHelperModule,
    handleEscape(e) {
      if (e.keyCode === 27 && this.vcoConfig.handler) {
        this.vcoConfig.handler();
      }
    },
    addModifierGroup() {
      if (this.itemClone.options === undefined) {
        this.$set(this.itemClone, 'options', []);
      }

      const option = {
        label: { en: '' },
        unique_name: '',
        _created: Math.random()
          .toString(36)
          .substr(2, 9),
        is: {
          disabled: false,
        },
        items: [],
        meta: {},
        min: null,
        max: null,
      };

      const options = [...this.itemClone.options];

      options.push(option);

      this.$set(this.itemClone, 'options', options);
    },
    deleteModifierGroup(index) {
      this.itemClone.options.splice(index, 1);
    },
    copyModifierGroup(index) {
      const cloneItem = cloneDeep(this.itemClone.options[index]);
      cloneItem.label.en += ' copy';
      delete cloneItem.id;
      if (Array.isArray(cloneItem.items)) {
        cloneItem.items.forEach((item) => delete item.id);
      }
      this.itemClone.options.push(cloneItem);
    },
    async save() {
      this.saving = true;
      if (this.getIsMenuItemValid()) {
        if (this.showItemImageTab && this.isImageTab && !this.isLocal) {
          await this.$refs.itemImage.save();
        }
        /* because of reactive render lag on image set during edge case, if an image was updated, we will use the itemCloneBackup to emit to parent for api storage */
        const backupHasSomeProperties = Object.keys(this.itemCloneBackup).length;
        if (backupHasSomeProperties) {
          const { image: _, ...item } = this.itemClone;
          this.itemCloneBackup = { ...this.itemCloneBackup, ...item };
        }
        this.$emit(
          'update:item',
          backupHasSomeProperties ? cloneDeep(this.itemCloneBackup) : this.itemClone,
        );

        this.$toast('Item configuration has been updated');
        this.open = false;
      } else {
        this.$toast.error('Please correct the errors');
      }
      this.itemClone = null;
      this.itemCloneBackup = {};
      this.setActiveTab('info');
      this.saving = false;
    },
    cancel() {
      this.itemClone = cloneDeep(this.item);
      this.itemCloneBackup = {};
      this.open = false;
      this.setActiveTab('info');
      this.$emit('cancelEditing');
    },
    async openConfig() {
      await this.$nextTick();
      this.vcoConfig.isActive = true;
      this.itemCloneBackup = {};
      this.itemClone = cloneDeep(this.item);
      await this.$nextTick();
      this.open = true;
      await this.getIsMenuItemValid();
    },
    async setModifierGroups(options = []) {
      this.itemClone.options = cloneDeep(options);
    },
    async setLoadingModifierGroups(isLoading = false) {
      this.loadingModifierGroups = isLoading;
    },
    async queryItems(query) {
      try {
        this.fetchingItems = true;
        const items = await this.getMenuItems({ query });
        this.items = items.map((e) => e.label.en);
        this.fetchingItems = false;
      } catch {
        console.error('could not autocomplete items');
      }
    },
    updateSorting(event) {
      if (this.readOnlyMode) return;
      this.setMenuSortNumber(event.newIndex, this.itemClone.options);
    },
    setMenuSortNumber(moved_record_index, records = []) {
      let last_record_with_sort_number_index = null;
      for (let i = records.length - 1; i >= 0; i -= 1) {
        const record = records[i];
        if (record && record.meta && record.meta.menu_sort_number) {
          last_record_with_sort_number_index = i;
          break;
        }
      }
      if (moved_record_index > last_record_with_sort_number_index) {
        last_record_with_sort_number_index = moved_record_index;
      }

      for (let i = 0; i <= last_record_with_sort_number_index; i += 1) {
        const record = records[i];
        this.$set(record, 'meta', { ...record.meta, menu_sort_number: i + 1 });
      }
    },
    updateModifierGroup(index, event) {
      this.$set(this.itemClone.options, index, event);
    },
    applyGlobalImage() {
      if (!this.isLocal || !this.isAdmin || this.imageSrcProxy || !this.parentItemImageSrc) return;
      this.imageSrcProxy = this.parentItemImageSrc;
    },
    setItemName(name) {
      this.$set(this.itemClone.label, 'en', name);
    },
    getParentModGroup(id) {
      if (!this.parentItem || !this.parentItem.options) return null;
      return this.parentItem.options.find((o) => o.id === id);
    },
    blockDecimals(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode === 46) {
        event.preventDefault();
      }
    },
    setActiveTab(v) {
      this.activeTab = v;
    },
    selectImage() {
      this.applyGlobalImage();
      this.$refs.itemImage.$refs.mycroppa.chooseFile();
    },
    removeImage() {
      this.$refs.itemImage.handleCustomRemoveImage();
    },
    setOriginalImageObject() {
      const originalImageObject =
        this?.item?.image?.src &&
        this?.item?.image?.sizes?.original &&
        this?.item?.image?.sizes?.thumbnail_80_80
          ? this.buildImageMapUrls(IMAGE_ENTITY_TYPES.menu, this.item.image)
          : cloneDeep(IMAGE_TEMPLATE.menu);
      this.setOriginalImageObjectMap({
        ...this.originalImageObjectMap,
        ...this.buildImageMapEntry(this.item.id, originalImageObject),
      });
    },
    truncateText(text, charMax) {
      const ellipsis = text.split('').length > charMax ? '...' : '';
      return `${text.substr(0, charMax)}${ellipsis}`;
    },
    getIsModifierGroupAvailable() {
      if (this.menuVersion === 1) return true;
      if (!this.companyGlobalModGroups || this.companyGlobalModGroups.length === 0) return false;
      return (
        this.companyGlobalModGroups.filter(
          // eslint-disable-next-line no-unused-expressions
          (mg) => !this.itemClone?.options?.find((d) => d.id === mg.id) && !mg.is.disabled,
        ).length > 0
      );
    },
    getIsModifierGroupsValid() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs?.form?.validate();
      // eslint-disable-next-line no-unused-expressions
      const option = this.itemClone?.options?.some((d) => d.id === undefined);
      if (this.menuVersion === 1) {
        if (!option && this.isFormValid && this.modified) return !option;
        return !!option;
      }
      return !option;

      // const areOptionsValid = !this.itemClone?.options?.some(d => d.id === undefined);
      // if (this.menuVersion === 1) {
      //   return areOptionsValid && this.isFormValid && this.modified;
      // }
      // return areOptionsValid;
    },
    async getIsMenuItemValid() {
      await this.$nextTick();
      return this.isFormValid;
    },
    async setDefaultItemImageObject() {
      this.itemClone.image = cloneDeep(IMAGE_TEMPLATE.menu);
      this.itemCloneBackup = cloneDeep(this.itemClone);
    },
    checkTextLength(text) {
      return Boolean(text?.split(' ').filter((word) => word.split('').length > 5).length > 3);
    },
    syncModTaxablePrice(clonedItem, originalItem) {
      /*
       * this check handles cloned item mod meta.taxable_price value being set to undefined when drawer opens;
       * syncs cloned item mod taxable_price with that of original item mod if original's is defined and clone's is undefined
       */
      // eslint-disable-next-line no-unused-expressions
      clonedItem.options?.forEach((option, optionIndex) => {
        // eslint-disable-next-line no-unused-expressions
        option?.items?.forEach((mod, modIndex) => {
          const originalMod = originalItem.options?.[optionIndex]?.items[modIndex];
          if (
            mod.meta &&
            mod.meta?.taxable_price === undefined &&
            originalMod?.meta?.taxable_price
          ) {
            mod.meta.taxable_price = originalMod.meta.taxable_price;
          }
        });
      });
    },
  },
  computed: {
    ...mapGetters('adminPanel', [
      'isAdmin',
      'isSiteOperator',
      'hasSpecificPermissions',
      'getStage',
    ]),
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('users', ['customPermissions']),
    ...mapState('menus', [
      'isLocal',
      'showPluField',
      'showPromoExemptField',
      'readOnlyMode',
      'user',
      'companyGlobalModGroups',
      'menuVersion',
      'pageYOffset',
    ]),
    ...mapState('file', ['newImageMap', 'originalImageObjectMap']),
    canEditPlu() {
      if (this.isAdmin) return true;
      const hasSpecificPermission = this.hasSpecificPermissions([
        this.customPermissions.overridePlu,
      ]);
      return this.isLocal && hasSpecificPermission;
    },
    plu: {
      get() {
        return get(this.itemClone, 'meta.plu');
      },
      set(v) {
        if (!this.itemClone.meta) {
          this.$set(this.itemClone, 'meta', { plu: v });
        } else if (!this.itemClone.meta.plu) {
          this.$set(this.itemClone.meta, 'plu', v);
        } else {
          this.itemClone.meta.plu = v;
        }
      },
    },
    barcode: {
      get() {
        return get(this.itemClone, 'meta.barcodes', []).join(',');
      },
      set(barcodes = '') {
        this.$set(this.itemClone, 'meta', {
          ...this.itemClone.meta,
          barcodes: barcodes
            .split(',')
            .map((v) => v.trim())
            .filter(Boolean),
        });
      },
    },
    unit: {
      get() {
        return get(this.itemClone, 'unit');
      },
      set(v) {
        if (v === '') v = undefined;
        this.$set(this.itemClone, 'unit', v);
      },
    },
    sortNumberProxy: {
      get() {
        return this.itemClone.meta.sort_number;
      },
      set(value) {
        this.$set(this.itemClone.meta, 'sort_number', parseInt(value, 10) || undefined);
      },
    },
    pccTaxTagProxy: {
      get() {
        return this.itemClone.meta?.tax?.tax_tag_code || '';
      },
      set(value) {
        this.$set(this.itemClone.meta, 'tax', { tax_tag_code: value });
      },
    },
    modified() {
      if (Object.keys(this.itemCloneBackup).length) {
        this.syncModTaxablePrice(this.itemCloneBackup, this.item);
        return !isEqual(this.itemCloneBackup, this.item);
      }
      this.syncModTaxablePrice(this.itemClone, this.item);
      return !isEqual(this.itemClone, this.item);
    },
    configClass() {
      return { saveable: true };
    },
    calories: {
      get() {
        return (
          this.itemClone.nutrition &&
          this.itemClone.nutrition.calories &&
          this.itemClone.nutrition.calories.amount
        );
      },
      set(v) {
        v = Number.isNaN(parseInt(v, 10)) ? null : parseInt(v, 10);
        if (!this.itemClone.nutrition || !this.itemClone.nutrition.calories) {
          this.$set(this.itemClone, 'nutrition', { calories: {} });
        }
        this.$set(this.itemClone.nutrition.calories, 'amount', v);
      },
    },
    isDisabledForPLU() {
      if (!this.isSiteOperator || (this.isSiteOperator && !this.showPluField)) return false;
      if (
        this.showPluField &&
        this.item.meta &&
        this.item.meta.plu !== undefined &&
        this.item.meta.plu !== null &&
        this.item.meta.plu.toString().length > 0
      ) {
        return false;
      }
      return true;
    },
    imageSrcProxy: {
      get() {
        return this.itemClone?.image?.src || this.itemCloneBackup?.image?.src;
      },
      set(v) {
        if (!this?.item?.id) {
          const error = 'could not update image';
          this.$toast.error(error);
          throw new Error(error);
        }
        if (!this.originalImageObjectMap[this.item.id]) this.setOriginalImageObject();
        if (v.original && v.hero_1200_800 && v.thumbnail_80_80) {
          /* 
              for backwards compatibility, we are storing hero_1200_800 in the 'src' property instead of putting it in the 'sizes' property of 'itemClone.image' in menu api
            */
          this.itemClone.image.src = v.hero_1200_800;
          if (!this.itemClone.image.sizes)
            this.itemClone.image.sizes = cloneDeep(IMAGE_TEMPLATE.menu.sizes);
          this.itemClone.image.sizes.original = v.original;
          this.itemClone.image.sizes.thumbnail_80_80 = v.thumbnail_80_80;
          if (!v.isPreview) {
            /* when image updated and saved to s3, we need to set it to the newImageMap, indexed with unique string consisting of item.id and timestamped src value, compare to api update response and ensure old image + all new unused images added in session are deleted from s3 if publish is successful or delete new unused images from s3 if publish is cancelled via refresh or route leave */
            this.setNewImageMap({
              ...this.newImageMap,
              ...this.buildImageMapEntry(`${this.item.id}:${this.itemClone.image.src}`, {
                urls: this.buildImageMapUrls(IMAGE_ENTITY_TYPES.menu, this.itemClone.image),
                menu: this.activeMenuId,
              }),
            });
          }
          /* setting itemCloneBackup to handle lag in reactive rendering of imageSrcProxy getter during edge case where image does not exist */
          this.itemCloneBackup = cloneDeep(this.itemClone);
        }
        if (v.delete) {
          if (this?.itemClone?.image?.src) this.setDefaultItemImageObject();
          if (!v.isPreview) {
            /* set empty image object in file store's newImageMap to trigger deletion of old image from s3 */
            this.setNewImageMap({
              ...this.newImageMap,
              ...this.buildImageMapEntry(`${this.item.id}:${this.itemClone.image.src}`, {
                urls: this.buildImageMapUrls(IMAGE_ENTITY_TYPES.menu, this.itemClone.image),
                menu: this.activeMenuId,
              }),
            });
          }
        }
        if (!this?.itemClone?.image?.src) {
          /* set empty image template on menu api */
          this.setDefaultItemImageObject();
        }
      },
    },
    getThumbnailImage() {
      return (
        this.itemClone?.image?.sizes?.thumbnail_80_80 ||
        this.itemClone?.image?.src ||
        this.itemCloneBackup?.image?.sizes?.thumbnail_80_80
      );
    },
    parentItemImageSrc() {
      if (!this.parentItem || !this.parentItem.image) return undefined;
      return this.parentItem.image.src;
    },
    isItemDescDisabled() {
      return (
        this.readOnlyMode ||
        this.isDisabledForPLU ||
        ((this.isSiteOperator || this.isLocal) && !this.hasDescEditPermission)
      );
    },
    isCaloriesDisabled() {
      return (
        this.readOnlyMode ||
        this.isDisabledForPLU ||
        ((this.isSiteOperator || this.isLocal) && !this.hasCaloriesEditPermissions)
      );
    },
    isLabelDisabled() {
      return (
        this.readOnlyMode ||
        this.isDisabledForPLU ||
        (this.isLocal && !this.hasLabelEditPermissions)
      );
    },
    isImageUploadDisabled() {
      return this.readOnlyMode || this.isLocal || !this.isAdmin;
    },
    globalItemName() {
      if (!this.parentItem) return '';
      return (this.parentItem.label && this.parentItem.label.en) || '';
    },
    showGlobalItemName() {
      if (!this.isLocal || !this.globalItemName) return false;
      const localItemName = (this.itemClone.label && this.itemClone.label.en) || '';
      return this.globalItemName !== localItemName;
    },
    isTaxExempted: {
      get() {
        if (this.itemClone.meta.taxes) {
          return this.itemClone.meta.taxes.find((t) => t === 'Tax Exempt');
        }
        return false;
      },
      set(checked) {
        if (!this.itemClone.meta.taxes) this.itemClone.meta.taxes = [];
        const taxes = [...this.itemClone.meta.taxes];
        const taxExemptedIndex = taxes.findIndex((t) => t === 'Tax Exempt');
        if (checked && taxExemptedIndex) {
          taxes.push('Tax Exempt');
        } else {
          taxes.splice(taxExemptedIndex, 1);
        }
        if (taxes.length === 0 && !this.item.meta.taxes) {
          this.$delete(this.itemClone.meta, 'taxes');
        } else {
          this.$set(this.itemClone, 'meta', {
            ...this.itemClone.meta,
            taxes: [...taxes],
          });
        }
        return checked;
      },
    },
    iPhoneBackgroundHero() {
      if (!this.imageSrcProxy) {
        // eslint-disable-next-line global-require
        return require('@/assets/i-phone-cropped.svg');
      }
      // eslint-disable-next-line global-require
      return require('@/assets/i-phone-large.svg');
    },
    thumbnailLabel() {
      const charMax = 45;
      if (!this?.itemClone?.label?.en) return null;
      if (
        this.itemClone.label.en.split('').length < charMax &&
        this.itemClone.label.en.split(' ').length === 1
      )
        return this.itemClone.label.en;
      return `${this.truncateText(this.itemClone.label.en, charMax)}`;
    },
    thumbnailDescription() {
      if (!this?.itemClone?.description?.en) return null;
      return `${this.truncateText(this.itemClone.description.en, 70)}`;
    },
    floatingPrice() {
      return this?.itemClone?.price?.amount && this?.itemClone?.price?.amount > 0
        ? this?.itemClone?.price?.amount.toFixed(2)
        : '0';
    },
    isFirstMenuSet() {
      return !ID(this.activeMenuId)?.id;
    },
    isInfoTab() {
      return this.activeTab === 'info';
    },
    isImageTab() {
      return this.activeTab === 'image';
    },
    isTextLong() {
      return this.isLabelLong || this.isDescriptionLong;
    },
    isNewItem() {
      return !(
        this.itemClone.label?.en ||
        this.itemClone.price?.amount ||
        this.itemClone.options?.length
      );
    },
  },
};
</script>

<style scoped>
.cta-container {
  position: sticky;
  bottom: 0;
  padding: 22px 0px 38px 0px;
  background: var(--v-light-base);
  z-index: 2;
}
.saveable {
  height: auto;
}
.flex.sort_number {
  max-width: 3.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}
.flex.modifier_sort_number {
  max-width: 5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}
div >>> .secondary-text .mdi:before {
  font-size: 20px;
}
div >>> .secondary-text .v-label {
  font-size: 13px;
}
.phonepreview {
  background-position: center top;
  margin-top: 10px;
}
.tab {
  width: fit-content;
  font-size: 27px;
}
.tab:hover {
  cursor: pointer;
}
.tab-active-color {
  color: var(--v-primary-base);
}
.tab-active-border {
  border-bottom: 0.125em solid var(--v-primary-base);
}
.tab-inactive {
  color: var(--v-dark-grey-base);
  opacity: 0.8;
  border-bottom: '';
}
.thumbnail-image-border {
  width: 70%;
  margin: 0 0 3em 3%;
  border-top: 0.08em solid var(--v-light-grey-darken1);
  position: absolute;
}
.hero-image {
  margin-top: 2.25em;
  border: 0.08em solid #989899;
  border-bottom: unset;
  border-top-left-radius: 3.4em;
  border-top-right-radius: 3.4em;
}
.hero-image-placeholder {
  margin-top: 2.75em;
  border: 0.08em solid #989899;
  border-bottom: unset;
  border-top-left-radius: 2.9em;
  border-top-right-radius: 2.9em;
}
.chevron-left {
  width: 4em;
  height: 4em;
  position: relative;
  bottom: 15.5em;
  right: 10em;
}
.item-quantity-border {
  width: 8em;
  height: 2.5em;
  margin-bottom: 1em;
  border: 0.125em solid var(--v-dark-grey-darken4);
  border-radius: 5em;
}
.phone-display-title {
  font-size: 1em;
  font-weight: 800;
  opacity: 0.8;
}
.phone-display-title-dark {
  font-size: 1em;
  opacity: 0.9;
}
.remove-button {
  font-size: 15.6px;
  letter-spacing: 1.3px;
  color: var(--v-primary-base);
  cursor: pointer;
}
.image-tab-locked {
  width: 50%;
  margin: 6em 0 0 25%;
}
</style>
