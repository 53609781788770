import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "clickable" }, [
    _c(
      "td",
      { attrs: { nowrap: "" } },
      [
        _c(VCheckbox, {
          attrs: {
            "false-value": false,
            "true-value": true,
            "hide-details": "",
            disabled: _vm.readOnlyMode
          },
          nativeOn: {
            click: function($event) {
              $event.stopPropagation()
            }
          },
          model: {
            value: _vm.itemSelectedProxy,
            callback: function($$v) {
              _vm.itemSelectedProxy = $$v
            },
            expression: "itemSelectedProxy"
          }
        })
      ],
      1
    ),
    _c(
      "td",
      [
        _c(
          VLayout,
          { attrs: { "align-left": "" } },
          [
            !_vm.showChitEditTextBoxes
              ? _c(VFlex, [
                  _vm._v(
                    _vm._s((_vm.item.meta && _vm.item.meta.sort_number) || "")
                  )
                ])
              : _vm._e(),
            _vm.showChitEditTextBoxes
              ? _c(
                  VFlex,
                  { staticClass: "sort_number" },
                  [
                    _c(VTextField, {
                      attrs: {
                        type: "number",
                        min: "0",
                        rules: _vm.sortNumberRules,
                        disabled: _vm.isDisabledForPLU
                      },
                      on: {
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.stopPropagation()
                          $event.preventDefault()
                          return (function() {})($event)
                        }
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      },
                      model: {
                        value: _vm.sortNumberProxy,
                        callback: function($$v) {
                          _vm.sortNumberProxy = _vm._n($$v)
                        },
                        expression: "sortNumberProxy"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(VSpacer)
          ],
          1
        )
      ],
      1
    ),
    _c(
      "td",
      { attrs: { nowrap: "" } },
      [
        _c(
          VLayout,
          { attrs: { "align-center": "" } },
          [_c(VFlex, [_vm._v(_vm._s(_vm.item.label.en))]), _c(VSpacer)],
          1
        )
      ],
      1
    ),
    _vm.isLocal
      ? _c(
          "td",
          { attrs: { nowrap: "" } },
          [
            _c(
              VTooltip,
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "div",
                            _vm._g({}, on),
                            [
                              _c(VCheckbox, {
                                attrs: {
                                  "hide-details": "",
                                  "on-icon": "mdi-eye-off",
                                  "off-icon": "mdi-eye",
                                  color: _vm.hiddenColor,
                                  "false-value": false,
                                  "true-value": true,
                                  disabled:
                                    _vm.readOnlyMode || _vm.isDisabledForPLU,
                                  tabindex: "-1"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$emit("item:groupaction")
                                  }
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                },
                                model: {
                                  value: _vm.itemProxy.is.hidden,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemProxy.is, "hidden", $$v)
                                  },
                                  expression: "itemProxy.is.hidden"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  842677696
                )
              },
              [
                _vm.isDisabledForPLU
                  ? _c("span", [_vm._v("Requires PLU")])
                  : _c("span", [
                      _vm.itemProxy.is.hidden
                        ? _c("span", [_vm._v("Hidden in App")])
                        : _c("span", [_vm._v("Display in App")])
                    ])
              ]
            )
          ],
          1
        )
      : _c("td", { attrs: { nowrap: "" } }, [
          _vm.itemProxy.is
            ? _c(
                "span",
                [
                  _c(VCheckbox, {
                    attrs: {
                      "hide-details": "",
                      color: "secondary",
                      "false-value": true,
                      "true-value": false,
                      disabled: _vm.readOnlyMode,
                      tabindex: "-1"
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    },
                    model: {
                      value: _vm.itemProxy.is.disabled,
                      callback: function($$v) {
                        _vm.$set(_vm.itemProxy.is, "disabled", $$v)
                      },
                      expression: "itemProxy.is.disabled"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
    _c("td", { attrs: { nowrap: "" } }, [
      _vm._v(_vm._s(_vm.item.price.amount))
    ]),
    _vm.showPluField
      ? _c("td", { attrs: { nowrap: "" } }, [_vm._v(_vm._s(_vm.pluValue))])
      : _vm._e(),
    _c("td", { attrs: { nowrap: "" } }, [_vm._v(_vm._s(_vm.barcodeValue))]),
    _c("td", { attrs: { nowrap: "" } }, [
      _vm.item.nutrition &&
      _vm.item.nutrition.calories &&
      _vm.item.nutrition.calories.amount >= 0
        ? _c("span", [_vm._v(_vm._s(_vm.item.nutrition.calories.amount))])
        : _vm._e()
    ]),
    _vm.isLocal && _vm.showMEQField
      ? _c(
          "td",
          {
            attrs: { nowrap: "" },
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c(VCheckbox, {
              staticStyle: { "padding-left": "15%" },
              attrs: {
                "false-value": false,
                "true-value": true,
                disabled: _vm.readOnlyMode,
                color: "secondary",
                "hide-details": ""
              },
              nativeOn: {
                click: function($event) {
                  $event.stopPropagation()
                }
              },
              model: {
                value: _vm.meqEligibleProxy,
                callback: function($$v) {
                  _vm.meqEligibleProxy = $$v
                },
                expression: "meqEligibleProxy"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.isLocal
      ? _c(
          "td",
          {
            attrs: { nowrap: "" },
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c(VCheckbox, {
              staticStyle: { "padding-left": "15%" },
              attrs: {
                "false-value": true,
                "true-value": false,
                disabled: _vm.readOnlyMode || _vm.isDisabledForPLU,
                color: "secondary",
                "hide-details": ""
              },
              nativeOn: {
                click: function($event) {
                  $event.stopPropagation()
                }
              },
              model: {
                value: _vm.outOfStockProxy,
                callback: function($$v) {
                  _vm.outOfStockProxy = $$v
                },
                expression: "outOfStockProxy"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.showPromoExemptField
      ? _c(
          "td",
          {
            attrs: { nowrap: "" },
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c(
              "span",
              [
                _c(VSwitch, {
                  class: { switchenabled: !_vm.promoExemptProxy },
                  staticStyle: { "padding-left": "15%" },
                  attrs: {
                    inset: "",
                    "hide-details": "",
                    "false-value": false,
                    "true-value": true,
                    color: "green",
                    disabled: _vm.readOnlyMode || _vm.isSiteOperator
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  },
                  model: {
                    value: _vm.promoExemptProxy,
                    callback: function($$v) {
                      _vm.promoExemptProxy = $$v
                    },
                    expression: "promoExemptProxy"
                  }
                }),
                _c("div", { staticStyle: { "padding-left": "25px" } }, [
                  _vm._v(_vm._s(_vm.promoExemptProxy ? "Yes" : "No"))
                ])
              ],
              1
            )
          ]
        )
      : _c(
          "td",
          {
            attrs: { nowrap: "" },
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            !_vm.isLocal
              ? _c(
                  VBtn,
                  {
                    attrs: {
                      small: "",
                      flat: "",
                      icon: "",
                      disabled: _vm.readOnlyMode,
                      tabindex: "-1"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("item:copy")
                      }
                    }
                  },
                  [
                    _c(VIcon, { attrs: { small: "" } }, [
                      _vm._v("mdi-content-copy")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isLocal
              ? _c(
                  VBtn,
                  {
                    attrs: {
                      small: "",
                      flat: "",
                      icon: "",
                      disabled: _vm.readOnlyMode,
                      tabindex: "-1"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("item:delete")
                      }
                    }
                  },
                  [
                    _c(VIcon, { attrs: { small: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }