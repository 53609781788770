import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.vcoConfig.handler()
        }
      }
    },
    [
      _vm.open
        ? _c(
            VNavigationDrawer,
            {
              directives: [
                {
                  def: ClickOutside,
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.vcoConfig,
                  expression: "vcoConfig"
                }
              ],
              style: {
                height: "calc(100vh - 64px)",
                top: _vm.pageYOffset + "px"
              },
              attrs: {
                absolute: "",
                temporary: "",
                right: "",
                value: _vm.open,
                app: "",
                width: 900,
                stateless: "",
                id: "itemconfigpaneldrawer"
              }
            },
            [
              _c(
                VLayout,
                { staticStyle: { margin: "3em 0 0 3.3em" } },
                [
                  _c(
                    VLayout,
                    {
                      staticStyle: {
                        "flex-grow": "unset",
                        "margin-right": "3.285em"
                      },
                      attrs: { xs4: "" },
                      on: {
                        click: function($event) {
                          return _vm.setActiveTab("info")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            "tab",
                            _vm.isInfoTab
                              ? "tab-active-color tab-active-border"
                              : "tab-inactive"
                          ]
                        },
                        [_vm._v("\n          Item Information\n        ")]
                      )
                    ]
                  ),
                  _c(
                    VLayout,
                    { attrs: { column: "" } },
                    [
                      _vm.showItemImageTab
                        ? _c(
                            VLayout,
                            {
                              staticStyle: { "flex-grow": "unset" },
                              on: {
                                click: function($event) {
                                  return _vm.setActiveTab("image")
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    "tab",
                                    _vm.isImageTab
                                      ? "tab-active-color"
                                      : "tab-inactive"
                                  ]
                                },
                                [_vm._v("\n            Item Image\n          ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "1.5em",
                                    margin: "0.75em 0 0 0.857142em"
                                  }
                                },
                                [
                                  _vm.isFirstMenuSet
                                    ? _c(VImg, {
                                        attrs: {
                                          src: _vm.isImageTab
                                            ? require("@/assets/image-lock.svg")
                                            : require("@/assets/image-lock-grey.svg")
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("div", {
                        style: {
                          borderBottom: _vm.isImageTab
                            ? "0.225em solid var(--v-primary-base)"
                            : "",
                          width: this.isFirstMenuSet ? "12em" : "10em"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(VLayout, {
                staticStyle: {
                  "margin-top": "-0.09em",
                  "border-bottom": "0.125em solid var(--v-light-grey-base)"
                }
              }),
              _vm.isInfoTab
                ? _c(
                    VForm,
                    {
                      ref: "form",
                      class: _vm.configClass,
                      staticStyle: {
                        "background-color": "var(--v-grey-white-base)"
                      },
                      attrs: { eager: "" }
                    },
                    [
                      _c(
                        VLayout,
                        { attrs: { column: "" } },
                        [
                          _c(
                            VFlex,
                            { attrs: { shrink: "" } },
                            [
                              _vm.itemClone.label
                                ? _c(
                                    VContainer,
                                    {
                                      staticClass: "pb-0",
                                      attrs: { "grid-list-xl": "", fluid: "" }
                                    },
                                    [
                                      _c(
                                        VLayout,
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            VFlex,
                                            { attrs: { xs11: "" } },
                                            [
                                              _c(VCombobox, {
                                                attrs: {
                                                  loading:
                                                    !_vm.isSiteOperator &&
                                                    _vm.fetchingItems,
                                                  items: _vm.items,
                                                  "search-input": _vm.search,
                                                  "cache-items": "",
                                                  "hide-no-data": "",
                                                  label: "Item Name",
                                                  rules: _vm.itemNameRules,
                                                  disabled: _vm.isLabelDisabled
                                                },
                                                on: {
                                                  "update:searchInput": function(
                                                    $event
                                                  ) {
                                                    _vm.search = $event
                                                  },
                                                  "update:search-input": function(
                                                    $event
                                                  ) {
                                                    _vm.search = $event
                                                  }
                                                },
                                                model: {
                                                  value: _vm.itemClone.label.en,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.itemClone.label,
                                                      "en",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "itemClone.label.en"
                                                }
                                              }),
                                              _vm.showGlobalItemName
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "Caption-Selected-On-Surface-High-Emphasis-Left secondary-text mt-0 display-flex align-center"
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            "Original Name:"
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.globalItemName
                                                            )
                                                        )
                                                      ]),
                                                      !_vm.isLabelDisabled
                                                        ? _c(
                                                            VTooltip,
                                                            {
                                                              attrs: {
                                                                bottom: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          VBtn,
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "clickable tiny-btn",
                                                                              attrs: {
                                                                                flat:
                                                                                  "",
                                                                                icon:
                                                                                  "",
                                                                                round:
                                                                                  "",
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "gray"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.setItemName(
                                                                                    _vm.globalItemName
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              VIcon,
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-restore"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                1121761230
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Use Original Name"
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            VFlex,
                                            { staticClass: "sort_number" },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  type: "number",
                                                  label: "Sequence On Ticket",
                                                  rules: _vm.sortNumberRules,
                                                  disabled:
                                                    _vm.readOnlyMode ||
                                                    _vm.isDisabledForPLU
                                                },
                                                on: {
                                                  keydown: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return (function() {})(
                                                      $event
                                                    )
                                                  },
                                                  keypress: _vm.blockDecimals
                                                },
                                                model: {
                                                  value: _vm.sortNumberProxy,
                                                  callback: function($$v) {
                                                    _vm.sortNumberProxy = _vm._n(
                                                      $$v
                                                    )
                                                  },
                                                  expression: "sortNumberProxy"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            VLayout,
                                            { attrs: { column: "" } },
                                            [
                                              _c(
                                                VFlex,
                                                { attrs: { "d-flex": "" } },
                                                [
                                                  _c(
                                                    VFlex,
                                                    { attrs: { xs5: "" } },
                                                    [
                                                      _vm.open
                                                        ? _c(
                                                            "v-currency-field",
                                                            {
                                                              attrs: {
                                                                label: "Price",
                                                                rules:
                                                                  _vm.priceRules,
                                                                disabled:
                                                                  _vm.readOnlyMode ||
                                                                  _vm.isDisabledForPLU
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemClone
                                                                    .price
                                                                    .amount,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .itemClone
                                                                      .price,
                                                                    "amount",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "itemClone.price.amount"
                                                              }
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _vm.isLocal
                                                    ? _c(
                                                        VFlex,
                                                        { attrs: { xs5: "" } },
                                                        [
                                                          _c(VTextField, {
                                                            attrs: {
                                                              rules:
                                                                _vm.mealValueRules,
                                                              disabled:
                                                                _vm.readOnlyMode,
                                                              label:
                                                                "Meal Value"
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                _vm.itemClone.meal_value =
                                                                  parseInt(
                                                                    $event,
                                                                    10
                                                                  ) || null
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemClone
                                                                  .meal_value,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemClone,
                                                                  "meal_value",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemClone.meal_value"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                VFlex,
                                                { attrs: { "d-flex": "" } },
                                                [
                                                  _c(
                                                    VFlex,
                                                    { attrs: { xs6: "" } },
                                                    [
                                                      _c(VTextField, {
                                                        attrs: {
                                                          type: "number",
                                                          label: "Calories",
                                                          disabled:
                                                            _vm.isCaloriesDisabled,
                                                          rules:
                                                            _vm.calorieRules
                                                        },
                                                        on: {
                                                          keydown: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            return (function() {})(
                                                              $event
                                                            )
                                                          },
                                                          keypress:
                                                            _vm.blockDecimals
                                                        },
                                                        model: {
                                                          value: _vm.calories,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.calories = $$v
                                                          },
                                                          expression: "calories"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VFlex,
                                                    { attrs: { xs6: "" } },
                                                    [
                                                      _c(VTextField, {
                                                        attrs: {
                                                          label: "Units",
                                                          disabled:
                                                            _vm.readOnlyMode ||
                                                            _vm.isLocal,
                                                          rules: _vm.unitRules,
                                                          type: "number"
                                                        },
                                                        model: {
                                                          value: _vm.unit,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.unit = _vm._n(
                                                              $$v
                                                            )
                                                          },
                                                          expression: "unit"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                VFlex,
                                                { attrs: { "d-flex": "" } },
                                                [
                                                  _c(
                                                    VFlex,
                                                    { attrs: { xs6: "" } },
                                                    [
                                                      _c(VTextField, {
                                                        attrs: {
                                                          label: "Barcode",
                                                          disabled:
                                                            _vm.readOnlyMode ||
                                                            _vm.isLocal
                                                        },
                                                        model: {
                                                          value: _vm.barcode,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.barcode = $$v
                                                          },
                                                          expression: "barcode"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.showPluField
                                                    ? _c(
                                                        VFlex,
                                                        { attrs: { xs6: "" } },
                                                        [
                                                          _c(VTextField, {
                                                            attrs: {
                                                              label: "PLU ID",
                                                              disabled:
                                                                _vm.readOnlyMode ||
                                                                !_vm.canEditPlu
                                                            },
                                                            on: {
                                                              keypress:
                                                                _vm.blockDecimals
                                                            },
                                                            model: {
                                                              value: _vm.plu,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.plu = $$v
                                                              },
                                                              expression: "plu"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            VFlex,
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(VTextarea, {
                                                attrs: {
                                                  "auto-grow": "",
                                                  label: "Description",
                                                  rows: "1",
                                                  rules: _vm.descriptionRules,
                                                  disabled:
                                                    _vm.isItemDescDisabled
                                                },
                                                model: {
                                                  value:
                                                    _vm.itemClone.description
                                                      .en,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.itemClone.description,
                                                      "en",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "itemClone.description.en"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.isLocal && _vm.isUSLocationBrand
                                            ? _c(
                                                VFlex,
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(VCheckbox, {
                                                    staticClass:
                                                      "pt-0 mt-0 mr-4 d-inline-block",
                                                    attrs: {
                                                      label: "Tax Exempt",
                                                      "hide-details": "",
                                                      color: "secondary",
                                                      disabled: _vm.readOnlyMode
                                                    },
                                                    nativeOn: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.isTaxExempted,
                                                      callback: function($$v) {
                                                        _vm.isTaxExempted = $$v
                                                      },
                                                      expression:
                                                        "isTaxExempted"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            VFlex,
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(VSelect, {
                                                attrs: {
                                                  items: _vm.taxOptions,
                                                  label: "Tax Tags",
                                                  multiple: "",
                                                  clearable: "",
                                                  disabled:
                                                    _vm.readOnlyMode ||
                                                    _vm.isLocal,
                                                  chips: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.itemClone.meta.taxes,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.itemClone.meta,
                                                      "taxes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "itemClone.meta.taxes"
                                                }
                                              }),
                                              _c(VTextField, {
                                                attrs: {
                                                  label: "Tax Tag (pcc)",
                                                  clearable: "",
                                                  disabled:
                                                    _vm.readOnlyMode ||
                                                    _vm.isLocal
                                                },
                                                model: {
                                                  value: _vm.pccTaxTagProxy,
                                                  callback: function($$v) {
                                                    _vm.pccTaxTagProxy = $$v
                                                  },
                                                  expression: "pccTaxTagProxy"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            VFlex,
                                            {
                                              staticClass: "display-flex",
                                              attrs: { xs12: "" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "H6-Secondary-Left flex xs9"
                                                },
                                                [_vm._v("Modifiers")]
                                              ),
                                              !_vm.isLocal
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "display-flex align-center justify-end text--secondary flex xs3 pr-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  Item Enabled\n                "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.isLocal
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "display-flex align-center justify-end text--secondary flex xs3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  In Stock\n                "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.showPromoExemptField
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "display-flex align-center justify-end text--secondary flex xs1 mr-0 ml-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  P.Exempt\n                "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "display-flex align-center justify-end text--secondary flex xs1 modifier_sort_number"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Sequence On Ticket\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { shrink: "" } },
                            [
                              _c(
                                "loading-wrapper",
                                {
                                  attrs: {
                                    loading: _vm.loadingModifierGroups,
                                    size: 30,
                                    mt: "2vh"
                                  }
                                },
                                [
                                  _c(
                                    VLayout,
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _vm.itemClone.options
                                        ? _c(
                                            "draggable",
                                            {
                                              staticClass: "flex xs12",
                                              attrs: { sort: "false" },
                                              on: { end: _vm.updateSorting },
                                              model: {
                                                value: _vm.itemClone.options,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.itemClone,
                                                    "options",
                                                    $$v
                                                  )
                                                },
                                                expression: "itemClone.options"
                                              }
                                            },
                                            _vm._l(
                                              _vm.itemClone.options,
                                              function(option, index) {
                                                return _c("modifier-group", {
                                                  key:
                                                    option._created ||
                                                    option.id,
                                                  attrs: {
                                                    group: option,
                                                    isDisabledForPLU:
                                                      _vm.isDisabledForPLU,
                                                    isCaloriesDisabled:
                                                      _vm.isCaloriesDisabled,
                                                    isLabelDisabled:
                                                      _vm.isLabelDisabled,
                                                    companyGlobalModGroups:
                                                      _vm.companyGlobalModGroups,
                                                    currentItem: _vm.itemClone,
                                                    parentModGroup: _vm.getParentModGroup(
                                                      option.id
                                                    )
                                                  },
                                                  on: {
                                                    "update:group": function(
                                                      $event
                                                    ) {
                                                      return _vm.updateModifierGroup(
                                                        index,
                                                        $event
                                                      )
                                                    },
                                                    "modifierGroup:delete": function(
                                                      $event
                                                    ) {
                                                      return _vm.deleteModifierGroup(
                                                        index
                                                      )
                                                    },
                                                    "modifierGroup:copy": function(
                                                      $event
                                                    ) {
                                                      return _vm.copyModifierGroup(
                                                        index
                                                      )
                                                    },
                                                    validateform: function(
                                                      $event
                                                    ) {
                                                      return _vm.$refs.form.validate()
                                                    }
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              !_vm.isLocal
                                ? _c(
                                    VLayout,
                                    {
                                      attrs: { row: "", "justify-center": "" }
                                    },
                                    [
                                      _c(
                                        VFlex,
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                flat: "",
                                                color: "primary",
                                                disabled:
                                                  _vm.readOnlyMode ||
                                                  !_vm.getIsModifierGroupAvailable()
                                              },
                                              on: {
                                                click: _vm.addModifierGroup
                                              }
                                            },
                                            [
                                              _c(VIcon, [
                                                _vm._v("mdi-plus")
                                              ]),
                                              _vm._v(
                                                "Add modifier group\n              "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isImageTab && !_vm.isFirstMenuSet
                ? _c(
                    VLayout,
                    {
                      staticStyle: {
                        "background-color": "var(--v-grey-white-base)"
                      },
                      attrs: { column: "" }
                    },
                    [
                      _c(
                        VCard,
                        {
                          staticClass: "d-flex ma-4",
                          staticStyle: { "flex-direction": "row" }
                        },
                        [
                          _c(
                            VLayout,
                            { staticClass: "mt-4", attrs: { column: "" } },
                            [
                              _c(
                                VLayout,
                                { attrs: { column: "" } },
                                [
                                  _c(
                                    VCardText,
                                    {
                                      staticClass: "pb-2",
                                      staticStyle: {
                                        color: "var(--v-primary-base)",
                                        "font-size": "1.5em",
                                        "font-weight": "400"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Add Image\n            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    VCardText,
                                    {
                                      staticStyle: {
                                        "font-size": "1.2em",
                                        opacity: "0.9"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Accompany this item with an inviting image\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                { staticStyle: { "margin-bottom": "2em" } },
                                [
                                  _c(
                                    VFlex,
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "ml-4",
                                          staticStyle: { width: "54.5%" },
                                          attrs: {
                                            disabled: _vm.isImageUploadDisabled,
                                            color: "primary"
                                          },
                                          on: { click: _vm.selectImage }
                                        },
                                        [
                                          _c(VImg, {
                                            staticClass: "mr-1 mb-1",
                                            attrs: {
                                              src: _vm.isImageUploadDisabled
                                                ? require("@/assets/file-image-plus-outline-disabled.svg")
                                                : require("@/assets/file-image-plus-outline.svg")
                                            }
                                          }),
                                          _vm._v(
                                            "\n                Upload Image\n              "
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCardText,
                                        {
                                          staticClass:
                                            "mt-3 phone-display-title"
                                        },
                                        [_vm._v("Image Requirements")]
                                      ),
                                      _c(
                                        VFlex,
                                        { staticClass: "d-flex" },
                                        [
                                          _c(
                                            VCardText,
                                            {
                                              staticClass:
                                                "phone-display-title",
                                              staticStyle: { width: "50%" }
                                            },
                                            [_vm._v("Size:")]
                                          ),
                                          _c(
                                            VCardText,
                                            {
                                              staticStyle: {
                                                "margin-left": "-8.5em",
                                                "font-weight": "400",
                                                opacity: "0.8"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "1200px by 800px (recommended)\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VFlex,
                                        {
                                          staticClass: "d-flex",
                                          staticStyle: { "margin-top": "-1em" }
                                        },
                                        [
                                          _c(
                                            VCardText,
                                            {
                                              staticClass:
                                                "phone-display-title",
                                              staticStyle: { width: "50%" }
                                            },
                                            [_vm._v("Format: ")]
                                          ),
                                          _c(
                                            VCardText,
                                            {
                                              staticStyle: {
                                                "margin-left": "-6em",
                                                "font-weight": "400",
                                                opacity: "0.8"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "JPG, JPEG or PNGs only\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    { attrs: { column: "", "align-end": "" } },
                                    [
                                      _c(VImg, {
                                        staticClass: "mr-4",
                                        style: {
                                          border: this.imageSrcProxy
                                            ? ""
                                            : "0.125em dashed var(--v-dark-grey-lighten1)"
                                        },
                                        attrs: {
                                          src:
                                            _vm.imageSrcProxy ||
                                            require("@/assets/item-image-placeholder.svg"),
                                          width: "350",
                                          height: "100"
                                        }
                                      }),
                                      _c("image-uploader", {
                                        ref: "itemImage",
                                        attrs: {
                                          imageUrl: _vm.imageSrcProxy,
                                          width: 324,
                                          height: 143,
                                          placeholder: _vm.isLocal
                                            ? "Apply available image"
                                            : "Upload item image",
                                          disabled: _vm.isImageUploadDisabled,
                                          showRemoveButton: _vm.isAdmin,
                                          uploadConfig: _vm.uploadConfig,
                                          s3SubDir:
                                            _vm.getStage +
                                            ":" +
                                            _vm.activeMenuId +
                                            ":" +
                                            _vm.item.id +
                                            "/sizes",
                                          s3Folder: "menu_item_images",
                                          imageTypeSaved: "jpeg",
                                          accept: ".png,.jpg,.jpeg",
                                          storeOriginal: "",
                                          disableCropping: "",
                                          showPreviews: "",
                                          customControls: ""
                                        },
                                        on: {
                                          "update:imageUrl": function($event) {
                                            _vm.imageSrcProxy = $event
                                          },
                                          "update:image-url": function($event) {
                                            _vm.imageSrcProxy = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                {
                                  staticClass: "mr-4",
                                  staticStyle: { "margin-bottom": "2em" },
                                  attrs: { "justify-end": "" }
                                },
                                [
                                  !_vm.isImageUploadDisabled
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "mr-3 remove-button",
                                          on: { click: _vm.removeImage }
                                        },
                                        [
                                          _vm._v(
                                            "\n              REMOVE IMAGE\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCard,
                        {
                          staticClass: "d-flex mx-4",
                          staticStyle: { "flex-direction": "row" }
                        },
                        [
                          _c(
                            VLayout,
                            { staticClass: "mt-2", attrs: { column: "" } },
                            [
                              _c(
                                VLayout,
                                { staticClass: "mt-4", attrs: { column: "" } },
                                [
                                  _c(
                                    VCardText,
                                    {
                                      staticClass: "pb-2",
                                      staticStyle: {
                                        color: "var(--v-primary-base)",
                                        "font-size": "1.5em",
                                        "font-weight": "400"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Preview Image\n            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    VCardText,
                                    {
                                      staticStyle: {
                                        "font-size": "1.2em",
                                        opacity: "0.9"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              See how the item image will display in app\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                [
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "mt-1",
                                      staticStyle: { width: "50%" },
                                      attrs: { column: "" }
                                    },
                                    [
                                      _c(
                                        VCardText,
                                        {
                                          staticClass: "pb-2",
                                          staticStyle: {
                                            color: "var(--v-dark-blue-base)",
                                            "font-size": "1.5em",
                                            "font-weight": "500"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Menu Level\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        VCardText,
                                        {
                                          staticClass:
                                            "py-0 phone-display-title-dark"
                                        },
                                        [
                                          _vm._v(
                                            "\n                A thumbnail image will display next\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        VCardText,
                                        {
                                          staticClass:
                                            "py-0 phone-display-title-dark"
                                        },
                                        [
                                          _vm._v(
                                            "\n                to item on Menu screen\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "phonepreview",
                                      style: {
                                        backgroundImage:
                                          "url(" +
                                          require("@/assets/i-phone-large.svg") +
                                          ")",
                                        marginLeft: "12%",
                                        width: "86%",
                                        height: "19em",
                                        position: "relative"
                                      },
                                      attrs: {
                                        column: "",
                                        wrap: "",
                                        "align-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        VLayout,
                                        {
                                          staticClass: "mx-2",
                                          staticStyle: {
                                            "max-width": "25em",
                                            "min-width": "25em",
                                            "margin-top": "7em",
                                            position: "absolute"
                                          },
                                          attrs: { "align-center": "" }
                                        },
                                        [
                                          _c(
                                            VLayout,
                                            {
                                              staticStyle: {
                                                width: "75%",
                                                "padding-left": "1em"
                                              },
                                              attrs: { column: "" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "mb-1",
                                                  staticStyle: {
                                                    "font-weight": "600",
                                                    "word-break": "break-word"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.thumbnailLabel ||
                                                          "Item Name"
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                VLayout,
                                                {
                                                  staticStyle: {
                                                    "word-break": "break-word"
                                                  },
                                                  attrs: { column: "" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "90%",
                                                        "font-size": "0.8em"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.thumbnailDescription ||
                                                              "Description"
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                VLayout,
                                                {
                                                  staticClass:
                                                    "justify-space-between",
                                                  style: {
                                                    width: "95%",
                                                    marginTop: _vm.isTextLong
                                                      ? "1em"
                                                      : "3.5em"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color:
                                                          "var(--v-turqouise-base)"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "$" +
                                                          _vm._s(
                                                            _vm.floatingPrice
                                                          )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "0.8em"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.calories
                                                              ? _vm.calories +
                                                                  " cals"
                                                              : "Calories"
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            VLayout,
                                            {
                                              staticStyle: {
                                                "margin-top": "2em"
                                              }
                                            },
                                            [
                                              _c(VImg, {
                                                staticStyle: {
                                                  "border-radius": "0.5em"
                                                },
                                                attrs: {
                                                  src:
                                                    _vm.getThumbnailImage ||
                                                    require("@/assets/item-image-placeholder.svg"),
                                                  contain: "",
                                                  width: "80",
                                                  height: "80"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticClass: "thumbnail-image-border",
                                        style: {
                                          top: _vm.isTextLong
                                            ? "19em"
                                            : "16.5em"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VLayout,
                                [
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "mt-1",
                                      staticStyle: { width: "50%" },
                                      attrs: { column: "" }
                                    },
                                    [
                                      _c(
                                        VCardText,
                                        {
                                          staticClass: "pb-2",
                                          staticStyle: {
                                            color: "var(--v-dark-blue-base)",
                                            "font-size": "1.5em",
                                            "font-weight": "500"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Item Level\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        VCardText,
                                        {
                                          staticClass: "py-0",
                                          staticStyle: {
                                            "font-size": "1em",
                                            opacity: "0.9"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                A large image will display in the\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        VCardText,
                                        {
                                          staticClass: "py-0",
                                          staticStyle: {
                                            "font-size": "1em",
                                            opacity: "0.9"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                header of Item Modifiers screen\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VLayout,
                                    {
                                      staticClass: "phonepreview",
                                      style: {
                                        backgroundImage:
                                          "url(" +
                                          this.iPhoneBackgroundHero +
                                          ")",
                                        marginLeft: "12%",
                                        width: "86%"
                                      },
                                      attrs: {
                                        column: "",
                                        wrap: "",
                                        "align-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        VLayout,
                                        { attrs: { column: "" } },
                                        [
                                          _c(VImg, {
                                            class: [
                                              _vm.imageSrcProxy
                                                ? "hero-image"
                                                : "hero-image-placeholder"
                                            ],
                                            attrs: {
                                              src:
                                                _vm.imageSrcProxy ||
                                                require("@/assets/item-image-placeholder.svg"),
                                              width: "374",
                                              height: "256"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(VImg, {
                                        staticClass: "chevron-left",
                                        attrs: {
                                          src: require("@/assets/chevron-left.svg")
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "17.8em",
                                            position: "relative",
                                            bottom: "23.4em"
                                          }
                                        },
                                        [
                                          _c(VImg, {
                                            attrs: {
                                              src: require("@/assets/i-phone-frame.svg")
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VLayout,
                                        {
                                          staticStyle: {
                                            "margin-top": "-8em",
                                            "text-align": "center",
                                            "max-width": "65%"
                                          },
                                          attrs: { column: "" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mt-3",
                                              staticStyle: {
                                                "font-weight": "600",
                                                "word-break": "break-word"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.itemClone.label.en ||
                                                      "Item Name"
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "my-3",
                                              staticStyle: {
                                                "font-size": "0.8em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  (" +
                                                  _vm._s(
                                                    _vm.calories
                                                      ? _vm.calories + " cals"
                                                      : "Calories"
                                                  ) +
                                                  ")\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "0.8em",
                                                "word-break": "break-word"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.itemClone.description
                                                      .en || "Description"
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-2 item-quantity-border d-flex align-self-center"
                                            },
                                            [
                                              _c(
                                                VLayout,
                                                {
                                                  staticStyle: {
                                                    "margin-top": "0.45em"
                                                  },
                                                  attrs: {
                                                    "justify-center": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mr-2",
                                                      staticStyle: {
                                                        width: "1.25em"
                                                      }
                                                    },
                                                    [
                                                      _c(VImg, {
                                                        attrs: {
                                                          src: require("@/assets/quantity-minus.svg")
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mx-2 d-flex align-center",
                                                      staticStyle: {
                                                        "margin-top": "-0.5em"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      1\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "ml-2",
                                                      staticStyle: {
                                                        width: "1.25em"
                                                      }
                                                    },
                                                    [
                                                      _c(VImg, {
                                                        attrs: {
                                                          src: require("@/assets/quantity-plus.svg")
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isImageTab && _vm.isFirstMenuSet
                ? _c(
                    VLayout,
                    {
                      staticStyle: {
                        "background-color": "var(--v-grey-white-base)"
                      },
                      attrs: { column: "" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "image-tab-locked" },
                        [
                          _c(VImg, {
                            attrs: {
                              src: require("@/assets/item-image-locked.svg")
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        {
                          staticStyle: {
                            width: "50%",
                            margin: "1.75em 0 20em 0"
                          },
                          attrs: {
                            column: "",
                            "align-center": "",
                            "align-self-center": ""
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mb-3",
                              staticStyle: {
                                "font-size": "1.6em",
                                "font-weight": "500",
                                "letter-spacing": "0.25px"
                              }
                            },
                            [
                              _vm._v(
                                "\n          Item image disabled\n        "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "18px",
                                "letter-spacing": "0.5px",
                                opacity: "0.6"
                              }
                            },
                            [
                              _vm._v(
                                "\n          To upload an item image, publish your menu first\n        "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VLayout,
                {
                  staticClass: "cta-container",
                  attrs: { row: "", "justify-center": "" }
                },
                [
                  !_vm.isFormValid && !_vm.isNewItem
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            bottom: "8px",
                            left: "16px",
                            color: "#ff5252"
                          }
                        },
                        [
                          _vm._v(
                            "\n        Form has errors. Please check your values.\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    VFlex,
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.saving,
                            disabled: !(
                              _vm.modified &&
                              _vm.isFormValid &&
                              !_vm.isDescriptionLengthExceeded &&
                              _vm.getIsModifierGroupsValid()
                            )
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v("Apply Changes")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "clickable",
                          attrs: { flat: "" },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }