<template>
  <v-flex>
    <list-item
      :title.sync="menu.label.en"
      fontSize="large"
      :icon="icon"
      :edit="edit"
      @update:edit="checkCategories"
      :rules="rules"
      :errors="errors"
      :secondary="secondary"
      @click.native="expandAction"
      @mouseover.native="showActions = true"
      @mouseleave.native="showActions = false"
    >
      <template v-slot:actions>
        <template v-if="isLocal">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-flex shrink>
                <v-checkbox
                  v-model="menu.is.hidden"
                  hide-details
                  on-icon="mdi-eye-off"
                  off-icon="mdi-eye"
                  color="red"
                  :false-value="false"
                  :true-value="true"
                  v-on="on"
                  :disabled="readOnlyMode"
                />
              </v-flex>
            </template>
            <span v-if="menu.is.hidden">Hidden in App</span>
            <span v-else>Display in App</span>
          </v-tooltip>
        </template>
        <template v-if="!isLocal">
          <v-btn
            flat
            small
            icon
            @click.stop="addCategory"
            @mousedown.stop
            style="margin: 0px; margin-right: 8px;"
            :disabled="readOnlyMode"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn flat small icon @click.stop @mousedown.stop style="margin: 0px;">
            <v-menu offset-y z-index="3" ref="menu">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-show="showActions" @click="checkMenuForGroups()"
                  >mdi-dots-vertical</v-icon
                >
              </template>
              <v-list>
                <v-list-tile @click="rename" :disabled="readOnlyMode">
                  <v-list-tile-title>Rename</v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="duplicate" :disabled="readOnlyMode" v-if="isVersion2">
                  <v-list-tile-title>Duplicate</v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="disable" :disabled="readOnlyMode">
                  <v-list-tile-title>{{ isDisabled ? 'Enable' : 'Disable' }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="deletes" :disabled="readOnlyMode">
                  <v-list-tile-title>Delete</v-list-tile-title>
                </v-list-tile>
                <v-divider></v-divider>
                <input
                  :id="getImportInputId()"
                  v-show="false"
                  type="file"
                  accept=".xlsx"
                  :ref="getImportInputId()"
                  @change="onMenuSetImport"
                />
                <v-list-tile
                  @click="$refs[getImportInputId()].click()"
                  :disabled="!readOnlyMode"
                  v-if="isVersion2"
                >
                  <v-list-tile-title>Import</v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="exportMenuSet" :disabled="!readOnlyMode" v-if="isVersion2">
                  <v-list-tile-title>Export</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-btn>
        </template>
      </template>
      <template v-slot:edit>
        <v-btn
          flat
          small
          icon
          @click.stop="deletes"
          v-if="menu.label.en.length === 0"
          :disabled="readOnlyMode"
        >
          <v-icon @click="deletes" :disabled="readOnlyMode">mdi-delete-forever</v-icon>
        </v-btn>
      </template>
      <template v-slot:secondaryData>
        <div
          v-if="showGlobalMenuName"
          class="Caption-Selected-On-Surface-High-Emphasis-Left secondary-text"
        >
          <strong>Original Name:</strong> {{ globalMenuName }}
        </div>
      </template>
      <template v-slot:inlineActions>
        <v-tooltip bottom v-if="showEditLocalMenuNameIcon">
          <template v-slot:activator="{ on }">
            <v-btn
              flat
              small
              icon
              @click.stop="openEditMenuNameDialog"
              @mousedown.stop
              class="edit-btn"
              v-on="on"
              :disabled="readOnlyMode"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit Menu Set Name</span>
        </v-tooltip>
      </template>
    </list-item>
    <div v-if="expanded">
      <loading-wrapper :loading="!menu.groups" :size="30" mt="2vh">
        <draggable v-model="menu.groups" v-if="menu.groups" sort="false" @end="updateSorting">
          <div v-for="(category, index) in menu.groups" :key="index">
            <category
              :name.sync="category.label.en"
              :key="category.id || index"
              :items="category.items"
              :meta="category.meta || {}"
              :active="checkActiveCategory(index)"
              :menuid="value.id || value.label.en"
              :properties="category.is"
              :categories="menu.groups.map((e) => e.label.en)"
              sort="false"
              @category:disable="disableCategory(index, $event)"
              @category:hide="hideCategory(index, $event)"
              @category:delete="deleteCategory(index)"
              @category:duplicate="duplicateCategory(index)"
              @category:setSortNumber="setSortNumber(index, $event)"
              @setCategory="
                $emit('setView', {
                  category: index,
                  menu: value.id || value.label.en,
                })
              "
            ></category>
          </div>
        </draggable>
        <loading-wrapper :loading="isDuplicating" :size="30" mt="2vh" />
      </loading-wrapper>
      <list-item title="No categories found" v-if="menu.groups && menu.groups.length === 0" />
    </div>
    <menu-name-dialog :open.sync="openMenuDialog" :menu="menu" :parentMenu="parentMenu" />
  </v-flex>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import omitDeep from 'omit-deep-lodash';
import draggable from 'vuedraggable';
import { generateCdlId } from '@/helpers';
import rules from '@/rules';
import listItem from './listItem';
import category from './category';
import menuNameDialog from './menuNameDialog';

export default {
  props: {
    value: Object,
    menunames: Array,
    active: Object,
    parentMenu: Object,
  },
  components: {
    listItem,
    category,
    draggable,
    menuNameDialog,
  },
  data: () => ({
    expanded: false,
    edit: false,
    duplicating: {},
    rules: [rules.required('Menu Set name is required')],
    showActions: false,
    openMenuDialog: false,
  }),
  computed: {
    ...mapState('menus', ['isLocal', 'readOnlyMode']),
    ...mapState('adminPanel', ['active_user_id', 'user']),
    ...mapGetters('adminPanel', {
      hasRole: 'hasRole',
    }),
    ...mapGetters('splitio', ['getFeatureFlags']),
    isPartialCategoriesActive() {
      return this.getFeatureFlags['ap3-menu-partial-categories'];
    },
    isPartialItemsActive() {
      return this.getFeatureFlags['ap3-menu-partial-items'];
    },
    isDuplicating() {
      return Object.values(this.duplicating)?.some((i) => i);
    },
    icon() {
      if (this.expanded) {
        return 'mdi-chevron-up';
      }
      return 'mdi-chevron-down';
    },
    showIcons() {
      if (this.$refs.menu) {
        return this.showActions || this.$refs.menu.isActive;
      }
      return this.showActions;
    },
    groupNames() {
      return this.menu.groups.map((e) => e.label.en);
    },
    menu: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    errors() {
      if (!this.menunames) return '';
      const test = [...this.menunames];
      test.splice(test.indexOf(this.menu.label.en), 1);
      if (test.includes(this.menu.label.en)) {
        return 'Menu name must be unique';
      }
      return '';
    },
    isDisabled() {
      return this.menu.is && this.menu.is.disabled;
    },
    secondary() {
      if (this.isDisabled) {
        return '(disabled)';
      }
      return '';
    },
    showGlobalMenuName() {
      if (!this.isLocal || !this.globalMenuName) return false;
      const localMenuName = (this.menu && this.menu.label && this.menu.label.en) || '';
      return this.globalMenuName !== localMenuName;
    },
    globalMenuName() {
      if (!this.parentMenu) return '';
      return (this.parentMenu.label && this.parentMenu.label.en) || '';
    },
    showEditLocalMenuNameIcon() {
      try {
        const isAdmin = this.hasRole('admin');
        const userHasSpecificPermission = this.user.permissions.scopes.includes(
          'write_label:menu:*',
        );
        return this.isLocal && (isAdmin || userHasSpecificPermission);
      } catch (err) {
        console.error('Error checking permissions for menu renaming', err);
        return false;
      }
    },
    isVersion2() {
      return this.menu.meta && this.menu.meta.version && this.menu.meta.version === 2;
    },
  },
  mounted() {
    if (this.value.label.en.length === 0) {
      this.edit = true;
    } else if (!this.isLocal) {
      this.checkCategories();
    }
  },
  methods: {
    ...mapActions('menus', ['fetchMenu', 'fetchFullCategory']),
    checkActiveCategory(index) {
      if (
        this.active.category === index &&
        (this.value.id === this.active.menu || this.value.label.en === this.active.menu)
      ) {
        this.expanded = true;
        return true;
      }
      return false;
    },
    getImportInputId() {
      return `menuSetImportInput-${this.menu.id}`;
    },
    onMenuSetImport(event) {
      const file = event.target.files.length > 0 && event.target.files[0];
      // allows input@change (this function) to trigger after editing and reuploading the same file
      this.$refs[this.getImportInputId()].value = null;
      if (!file || !file.name) {
        this.$toast.error('Error uploading menu set file.');
        return;
      }
      this.expanded = true;
      this.$emit('menu:import', { menuId: this.menu.id, file });
    },
    exportMenuSet() {
      this.$emit('menu:export');
    },
    rename() {
      this.edit = true;
    },
    duplicate() {
      this.$emit('menu:duplicate');
    },
    deletes() {
      this.$emit('menu:delete');
    },
    disable() {
      this.$emit('menu:disable');
      if (!this.menu.is.disabled) {
        if (this.menu.groups.length > 0) {
          const group = this.menu.groups[0];
          if (!group.is) this.$set(group, 'is', {});
          this.$set(group.is, 'disabled', this.menu.is.disabled);
        }
      } else {
        this.menu.groups.forEach((group) => {
          if (!group.is) this.$set(group, 'is', {});
          this.$set(group.is, 'disabled', this.menu.is.disabled);
        });
      }
    },
    hideMenu() {
      this.$emit('menu:hideMenu');
    },
    disableCategory(index, v) {
      this.$set(this.menu.groups[index].is, 'disabled', v);
      this.menu.is.disabled = this.menu.groups.filter((m) => !m.is.disabled).length === 0;
    },
    setSortNumber(index, v) {
      const sort_number = v && v > 0 ? v : undefined;
      this.$set(this.menu.groups[index], 'meta', { ...this.menu.groups[index].meta, sort_number });
    },
    expandAction() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.$emit('setView', {
          menu: this.menu.id,
          category: null,
          expandAction: true,
        });
      }
    },
    async addCategory() {
      this.expanded = true;
      if (!this.menu.groups) {
        const newMenu = await this.fetchMenu({
          id: this.menu.id,
          nocache: true,
          partial: this.isPartialCategoriesActive,
        });
        this.$set(this.menu, 'groups', [...newMenu.groups]);
      }
      this.menu.groups.push({
        id: generateCdlId('menu', 'cdl', 'group'),
        items: [],
        label: {
          en: '',
        },
        meta: {},
        is: {
          disabled: false,
          hidden: false,
          ...this.menu.is,
        },
      });
    },
    hideCategory(index, v) {
      this.$set(this.menu.groups[index].is, 'hidden', v);
      this.menu.is.hidden = this.menu.groups.filter((m) => !m.is.hidden).length === 0;
    },
    deleteCategory(index) {
      this.menu.groups.splice(index, 1);
    },
    async duplicateCategory(index) {
      this.$set(this.duplicating, this.menu.groups[index], true);
      let newCategory = this.isPartialCategoriesActive
        ? await this.fetchFullCategory({
            menu_id: this.menu.id,
            category: this.menu.groups[index],
            is_partial_items: this.isPartialItemsActive,
          })
        : cloneDeep(this.menu.groups[index]);
      const baseName = newCategory.label.en;
      const count = this.groupNames.filter((e) => e.includes(baseName)).length;
      newCategory.label.en += ` (${count})`;
      if (this.menu.meta && this.menu.meta.version && this.menu.meta.version === 1) {
        newCategory = omitDeep(newCategory, 'id');
      } else {
        delete newCategory.id;
        if (!newCategory.items) return;
        newCategory.items.forEach((item) => {
          delete item.id;
        });
      }

      this.menu.groups.push(newCategory);
      this.$set(this.duplicating, this.menu.groups[index], false);
    },
    checkCategories(edit) {
      this.edit = edit;
    },
    updateSorting(event) {
      if (this.readOnlyMode) return;
      this.setMenuSortNumber(event.newIndex, this.menu.groups);
    },
    setMenuSortNumber(moved_record_index, records = []) {
      let last_record_with_sort_number_index = null;
      for (let i = records.length - 1; i >= 0; i -= 1) {
        const record = records[i];
        if (record && record.meta && record.meta.menu_sort_number) {
          last_record_with_sort_number_index = i;
          break;
        }
      }
      if (moved_record_index > last_record_with_sort_number_index) {
        last_record_with_sort_number_index = moved_record_index;
      }

      for (let i = 0; i <= last_record_with_sort_number_index; i += 1) {
        const record = records[i];
        this.$set(record, 'meta', { ...record.meta, menu_sort_number: i + 1 });
      }
    },
    async openEditMenuNameDialog() {
      if (!this.menu.groups) {
        const newMenu = await this.fetchMenu({
          id: this.menu.id,
          nocache: true,
          partial: this.isPartialCategoriesActive,
        });
        this.$set(this.menu, 'groups', [...newMenu.groups]);
      }
      this.openMenuDialog = true;
    },
    checkMenuForGroups() {
      if (!this.menu.groups) {
        this.$emit('getMenu', {
          menu_id: this.menu.id,
        });
      }
    },
  },
};
</script>

<style>
.v-icon.mdi.mdi-eye.theme--light {
  color: green;
}
.sortable-chosen {
  border: 1px solid #0d73d8;
  /*transform:none!important;;*/
}
</style>
<style scoped>
div >>> .edit-btn {
  margin-top: 0px;
  margin-left: 3px;
}
div >>> .v-btn.edit-btn .v-btn__content .v-icon {
  font-size: 17px;
  color: #6b6868;
}
div >>> .secondary-text {
  color: #6b6868;
  margin-top: 5px;
  font-size: 11px;
  padding-left: 4px;
}
</style>
