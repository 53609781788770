import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VFlex, { attrs: { xs12: "" } }, [
    _c(
      "div",
      {
        staticClass: "modifier-group",
        on: {
          mouseover: function($event) {
            _vm.showActions = true
          },
          mouseleave: function($event) {
            _vm.showActions = false
          },
          click: _vm.expandAndStartEdit
        }
      },
      [
        _c(
          VLayout,
          {
            staticClass: "display-flex justify-space-between",
            attrs: { "align-center": "" }
          },
          [
            _vm.isVersion1
              ? _c(
                  "div",
                  {
                    staticClass:
                      "display-flex align-center f-column align-items-start"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "display-flex align-center" },
                      [
                        _c(VIcon, { staticClass: "icon" }, [
                          _vm._v(_vm._s(_vm.expandIcon))
                        ]),
                        !_vm.editLabel && !_vm.editLocalLabel
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "Body-1-Selected-On-Surface-High-Emphasis-Left",
                                staticStyle: { "pointer-events": "none" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.groupProxy.label.en) +
                                    "\n          "
                                )
                              ]
                            )
                          : _c(VTextField, {
                              ref: "labelinput",
                              attrs: {
                                "single-line": "",
                                rules: _vm.rules,
                                disabled: _vm.readOnlyMode
                              },
                              on: {
                                blur: _vm.doneEdit,
                                keydown: [
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.doneEdit($event)
                                  },
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return (function() {})($event)
                                  }
                                ]
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              },
                              model: {
                                value: _vm.groupProxy.label.en,
                                callback: function($$v) {
                                  _vm.$set(_vm.groupProxy.label, "en", $$v)
                                },
                                expression: "groupProxy.label.en"
                              }
                            }),
                        !_vm.isModGroupLabelDisabled
                          ? _c(
                              VTooltip,
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            VBtn,
                                            _vm._g(
                                              {
                                                staticClass: "edit-btn mb-0",
                                                attrs: {
                                                  flat: "",
                                                  small: "",
                                                  icon: "",
                                                  disabled: _vm.readOnlyMode
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    _vm.editLocalLabel = !_vm.editLocalLabel
                                                  },
                                                  mousedown: function($event) {
                                                    $event.stopPropagation()
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(VIcon, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.editLocalLabel
                                                      ? "mdi-check"
                                                      : "mdi-pencil"
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2123429898
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.editLocalLabel
                                        ? "Save changes"
                                        : "Edit Modifier Group Name"
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.showGlobalModGroupName
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "Caption-Selected-On-Surface-High-Emphasis-Left secondary-text mt-0 display-flex justify-space-between align-center f-wrap",
                            staticStyle: { "margin-left": "5.2rem" }
                          },
                          [
                            _c("div", [
                              _c("strong", [_vm._v("Original Name:")]),
                              _vm._v(" " + _vm._s(_vm.globalModGroupName))
                            ]),
                            !_vm.isModGroupLabelDisabled
                              ? _c(
                                  VTooltip,
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "clickable tiny-btn",
                                                    attrs: {
                                                      flat: "",
                                                      icon: "",
                                                      round: "",
                                                      small: "",
                                                      color: "gray"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.setModGroupName(
                                                          _vm.globalModGroupName
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-restore")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      437117070
                                    )
                                  },
                                  [_c("span", [_vm._v("Use Original Name")])]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm.isVersion2
              ? _c(
                  "div",
                  { staticClass: "display-flex align-center" },
                  [
                    _c(VIcon, { staticClass: "icon" }, [
                      _vm._v(_vm._s(_vm.expandIcon))
                    ]),
                    !_vm.editLabel
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "Body-1-Selected-On-Surface-High-Emphasis-Left",
                            staticStyle: { "pointer-events": "none" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.groupProxy.unique_name) +
                                "\n        "
                            )
                          ]
                        )
                      : _c(VSelect, {
                          ref: "uniqueName",
                          attrs: {
                            disabled: _vm.readOnlyMode,
                            items: _vm.getGlobalModGroupsToDisplay,
                            value: _vm.groupProxy,
                            "item-text": "unique_name",
                            "item-value": "id",
                            rules: _vm.selectRule,
                            placeholder: _vm.groupProxy.unique_name
                          },
                          on: { change: _vm.selectGlobalMod },
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isVersion2
              ? _c(
                  "div",
                  {
                    staticClass: "display-flex align-center f-wrap",
                    staticStyle: { "max-width": "27rem" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "display-flex align-center" },
                      [
                        _vm.editLocalLabel && !_vm.isModGroupLabelDisabled
                          ? _c(VTextField, {
                              attrs: {
                                label: "Label In App",
                                value: _vm.groupProxy.label.en,
                                rules: _vm.rules
                              },
                              on: {
                                input: function($event) {
                                  return _vm.setModGroupName($event)
                                },
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            })
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "Body-1-Selected-On-Surface-High-Emphasis-right"
                              },
                              [
                                _vm._v(
                                  "\n            Label in App: " +
                                    _vm._s(_vm.groupProxy.label.en) +
                                    "\n          "
                                )
                              ]
                            ),
                        !_vm.isModGroupLabelDisabled
                          ? _c(
                              VTooltip,
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            VBtn,
                                            _vm._g(
                                              {
                                                staticClass: "edit-btn mb-0",
                                                attrs: {
                                                  flat: "",
                                                  small: "",
                                                  icon: "",
                                                  disabled: _vm.readOnlyMode
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    _vm.editLocalLabel = !_vm.editLocalLabel
                                                  },
                                                  mousedown: function($event) {
                                                    $event.stopPropagation()
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(VIcon, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.editLocalLabel
                                                      ? "mdi-check"
                                                      : "mdi-pencil"
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2123429898
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.editLocalLabel
                                        ? "Save changes"
                                        : "Edit Modifier Group Name"
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.showGlobalModGroupName
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "Caption-Selected-On-Surface-High-Emphasis-Left secondary-text mt-0 display-flex justify-space-between align-center f-wrap"
                          },
                          [
                            _c("div", [
                              _c("strong", [_vm._v("Original Name:")]),
                              _vm._v(" " + _vm._s(_vm.globalModGroupName))
                            ]),
                            !_vm.isModGroupLabelDisabled
                              ? _c(
                                  VTooltip,
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "clickable tiny-btn",
                                                    attrs: {
                                                      flat: "",
                                                      icon: "",
                                                      round: "",
                                                      small: "",
                                                      color: "gray"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.setModGroupName(
                                                          _vm.globalModGroupName
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-restore")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      437117070
                                    )
                                  },
                                  [_c("span", [_vm._v("Use Original Name")])]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "display-flex align-center" },
              [
                !_vm.isLocal
                  ? [
                      _vm.isVersion1
                        ? _c(
                            VBtn,
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showActions,
                                  expression: "showActions"
                                }
                              ],
                              staticClass: "ma-0",
                              attrs: {
                                flat: "",
                                icon: "",
                                disabled: _vm.readOnlyMode
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit("modifierGroup:copy")
                                },
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return (function() {})($event)
                                }
                              }
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-content-copy")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VBtn,
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showActions,
                              expression: "showActions"
                            }
                          ],
                          staticClass: "ma-0",
                          attrs: {
                            flat: "",
                            icon: "",
                            disabled: _vm.readOnlyMode
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("modifierGroup:delete")
                            },
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.stopPropagation()
                              $event.preventDefault()
                              return (function() {})($event)
                            }
                          }
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-delete")
                          ])
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mr-1" })
                    ]
                  : _vm._e(),
                !_vm.isLocal
                  ? _c(VCheckbox, {
                      staticClass: "pt-0 mt-0 mr-4",
                      attrs: {
                        "hide-details": "",
                        color: "secondary",
                        "false-value": true,
                        "true-value": false,
                        disabled: _vm.readOnlyMode || _vm.isVersion2
                      },
                      on: {
                        change: function(v) {
                          return _vm.toggleModifierGroup(v, "disabled")
                        }
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      },
                      model: {
                        value: _vm.groupProxy.is.disabled,
                        callback: function($$v) {
                          _vm.$set(_vm.groupProxy.is, "disabled", $$v)
                        },
                        expression: "groupProxy.is.disabled"
                      }
                    })
                  : _vm._e(),
                _vm.isLocal
                  ? _c(
                      "div",
                      [
                        _c(
                          VTooltip,
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g({}, on),
                                        [
                                          _vm.isLocal
                                            ? _c(
                                                VCheckbox,
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "pt-0 mt-0 mr-2",
                                                    attrs: {
                                                      "hide-details": "",
                                                      "on-icon": "mdi-eye-off",
                                                      "off-icon": "mdi-eye",
                                                      color: _vm.hiddenColor,
                                                      "false-value": false,
                                                      "true-value": true,
                                                      disabled:
                                                        _vm.readOnlyMode ||
                                                        _vm.isDisabledForPLU
                                                    },
                                                    on: {
                                                      change: function(v) {
                                                        return _vm.toggleModifierGroup(
                                                          v,
                                                          "hidden"
                                                        )
                                                      }
                                                    },
                                                    nativeOn: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.groupProxy.is
                                                          .hidden,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.groupProxy.is,
                                                          "hidden",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "groupProxy.is.hidden"
                                                    }
                                                  },
                                                  on
                                                )
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4047379438
                            )
                          },
                          [
                            _vm.isDisabledForPLU
                              ? _c("span", [_vm._v("Requires PLU")])
                              : _c("span", [
                                  _vm.groupProxy.is.hidden
                                    ? _c("span", [_vm._v("Hidden in App")])
                                    : _c("span", [_vm._v("Display in App")])
                                ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isLocal
                  ? _c(VCheckbox, {
                      staticClass: "pt-0 mt-0 mr-4",
                      attrs: {
                        "hide-details": "",
                        color: "secondary",
                        "false-value": true,
                        "true-value": false,
                        disabled: _vm.readOnlyMode || _vm.isDisabledForPLU
                      },
                      on: {
                        change: function(v) {
                          return _vm.toggleModifierGroup(v, "out_of_stock")
                        }
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      },
                      model: {
                        value: _vm.outOfStockProxy,
                        callback: function($$v) {
                          _vm.outOfStockProxy = $$v
                        },
                        expression: "outOfStockProxy"
                      }
                    })
                  : _vm._e(),
                _vm.showPromoExemptField
                  ? _c(
                      "span",
                      [
                        _c(VSwitch, {
                          staticClass: "pt-0 mt-0 mr-4",
                          class: { switchenabled: !_vm.promoExemptProxy },
                          attrs: {
                            inset: "",
                            "hide-details": "",
                            "false-value": false,
                            "true-value": true,
                            color: "green",
                            disabled: _vm.readOnlyMode || _vm.isSiteOperator
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          },
                          model: {
                            value: _vm.promoExemptProxy,
                            callback: function($$v) {
                              _vm.promoExemptProxy = $$v
                            },
                            expression: "promoExemptProxy"
                          }
                        }),
                        _c("div", { staticClass: "promo_exempt_label" }, [
                          _vm._v(_vm._s(_vm.promoExemptProxy ? "Yes" : "No"))
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  VFlex,
                  { staticClass: "sort_number" },
                  [
                    _c(VTextField, {
                      attrs: {
                        type: "number",
                        min: "0",
                        label: "",
                        value: _vm.sortNumberProxy,
                        rules: _vm.sortNumberRules,
                        disabled: _vm.isSortNumberDisabled
                      },
                      on: {
                        input: function($event) {
                          _vm.sortNumberProxy =
                            parseInt($event, 10) || undefined
                        },
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.stopPropagation()
                          $event.preventDefault()
                          return (function() {})($event)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              2
            )
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.expand,
            expression: "expand"
          }
        ],
        staticClass: "modifiers-list"
      },
      [
        _c("div", { staticClass: "Body-1-Black-Medium-Emphasis-Left" }, [
          _vm._v("How many items can the customer choose?")
        ]),
        _c(
          VLayout,
          {
            staticClass: "size-line",
            attrs: { row: "", wrap: "", "align-baseline": "" }
          },
          [
            _c(
              VFlex,
              {
                staticStyle: { "margin-right": "24px" },
                attrs: { xs4: "", shrink: "" }
              },
              [
                _c(VSelect, {
                  attrs: { items: _vm.modifierTypes, disabled: _vm.isDisabled },
                  on: { change: _vm.updateMinMax },
                  model: {
                    value: _vm.modifierValue,
                    callback: function($$v) {
                      _vm.modifierValue = $$v
                    },
                    expression: "modifierValue"
                  }
                })
              ],
              1
            ),
            _vm.modifierValue === "Range"
              ? [
                  _c(
                    VFlex,
                    { attrs: { xs2: "", shrink: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          disabled: _vm.isDisabled,
                          rules: _vm.rangeMinRules,
                          "error-messages": _vm.errorRangeMin,
                          type: "number"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            $event.preventDefault()
                            return (function() {})($event)
                          }
                        },
                        model: {
                          value: _vm.minProxy,
                          callback: function($$v) {
                            _vm.minProxy = $$v
                          },
                          expression: "minProxy"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    {
                      staticStyle: { margin: "0px 24px" },
                      attrs: { shrink: "" }
                    },
                    [_vm._v("to")]
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs2: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          disabled: _vm.isDisabled,
                          rules: _vm.rangeMaxRules,
                          "error-messages": _vm.errorRangeMax,
                          type: "number"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            $event.preventDefault()
                            return (function() {})($event)
                          }
                        },
                        model: {
                          value: _vm.maxProxy,
                          callback: function($$v) {
                            _vm.maxProxy = $$v
                          },
                          expression: "maxProxy"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.modifierValue === "Minimum"
              ? [
                  _c(
                    VFlex,
                    { attrs: { xs2: "", shrink: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          rules: _vm.requiredRule,
                          disabled: _vm.isDisabled,
                          type: "number"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            $event.preventDefault()
                            return (function() {})($event)
                          }
                        },
                        model: {
                          value: _vm.minProxy,
                          callback: function($$v) {
                            _vm.minProxy = $$v
                          },
                          expression: "minProxy"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.modifierValue === "Maximum"
              ? [
                  _c(
                    VFlex,
                    { attrs: { xs2: "", shrink: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          disabled: _vm.isDisabled,
                          rules: _vm.requiredRule,
                          type: "number"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            $event.preventDefault()
                            return (function() {})($event)
                          }
                        },
                        model: {
                          value: _vm.maxProxy,
                          callback: function($$v) {
                            _vm.maxProxy = $$v
                          },
                          expression: "maxProxy"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.modifierValue === "Exactly"
              ? [
                  _c(
                    VFlex,
                    { attrs: { xs2: "", shrink: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          disabled: _vm.isDisabled,
                          rules: _vm.requiredRule,
                          type: "number"
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.stopPropagation()
                            $event.preventDefault()
                            return (function() {})($event)
                          }
                        },
                        model: {
                          value: _vm.exactValue,
                          callback: function($$v) {
                            _vm.exactValue = $$v
                          },
                          expression: "exactValue"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _c(VTextField, {
          staticClass: "custom-validation",
          attrs: {
            rules: _vm.modifierMessageRule,
            "error-messages": _vm.modifierMessage
          }
        }),
        _vm.group.items
          ? _c(
              "draggable",
              {
                staticClass: "pa-0",
                attrs: { sort: "false" },
                on: { end: _vm.updateSorting },
                model: {
                  value: _vm.group.items,
                  callback: function($$v) {
                    _vm.$set(_vm.group, "items", $$v)
                  },
                  expression: "group.items"
                }
              },
              _vm._l(_vm.group.items, function(modifier, index) {
                return _c("modifier", {
                  key: modifier.id || index,
                  attrs: {
                    modifier: modifier,
                    isDisabledForPLU: _vm.isDisabledForPLU,
                    isCaloriesDisabled: _vm.isCaloriesDisabled,
                    isLabelDisabled: _vm.isLabelDisabled,
                    currentGroup: _vm.groupProxy,
                    parentModifier: _vm.getParentModifier(modifier.id)
                  },
                  on: {
                    "update:modifier": function($event) {
                      modifier = $event
                    },
                    "modifier:groupaction": function($event) {
                      return _vm.modifyGroupAction()
                    },
                    "modifier:delete": function($event) {
                      return _vm.deleteModifier(index)
                    },
                    "modifier:copy": function($event) {
                      return _vm.copyModifier(index)
                    },
                    "modifier:invalid": function($event) {
                      return _vm.expandModifierGroup()
                    }
                  }
                })
              }),
              1
            )
          : _vm._e(),
        !_vm.isLocal && !_vm.readOnlyMode
          ? _c(
              VLayout,
              [
                _c(
                  VFlex,
                  { attrs: { shrink: "" } },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "ml-0 pl-0",
                        attrs: { flat: "", color: "primary" },
                        on: { click: _vm.addModifier }
                      },
                      [
                        _c(VIcon, [_vm._v("mdi-plus")]),
                        _vm._v("Add MODIFIER\n        ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }