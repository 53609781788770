<template>
  <v-layout row wrap align-center :class="!isCard ? 'modifier' : 'modifierCard'">
    <v-flex xs9 pl-0>
      <v-layout>
        <v-flex class="d-flex pl-0 xs6" @click="edit">
          <div>
            <v-select
              :placeholder="modifier.label.en"
              @click.native.stop
              :disabled="isCard || !editable || isLocal"
              ref="modifierName"
              :items="globalModGroupsModifiersToDisplay"
              item-text="label.en"
              item-value="id"
              @change="setGlobalMod"
              :no-data-text="modifier && modifier.label && modifier.label.en"
            ></v-select>
          </div>
        </v-flex>
        <v-flex xs4 style="margin-right: 10px;" @click="edit">
          <span class="Body-1-Black-Medium-Emphasis-Left" v-show="!editable"
            >+ ${{ price && price.toFixed(2) }}</span
          >
          <v-text-field
            label="Price"
            type="number"
            :value="price"
            :rules="priceRules"
            @input="price = parseFloat($event) || 0"
            prefix="+$"
            ref="price"
            single-line
            v-show="editable"
            :disabled="isCard || !isLocal"
            :success-messages="priceModifiedMessage"
          />
        </v-flex>
        <v-flex xs3 style="margin-right: 10px; max-width: 100px;" @click="edit">
          <span class="Body-1-Black-Medium-Emphasis-Left" v-show="!editable"
            >Calories:{{ calories }}</span
          >
          <v-text-field
            label="Calories"
            type="number"
            v-model.number="calories"
            :rules="calorieRules"
            :disabled="isCard || !isLocal"
            v-show="editable"
            ref="calories"
            :success-messages="caloriesModifiedMessage"
          />
        </v-flex>
        <v-flex v-if="showPluField" xs3 style="margin-right: 10px; max-width: 100px;" @click="edit">
          <span class="Body-1-Black-Medium-Emphasis-Left" v-show="!editable">PLU:{{ plu }}</span>
          <v-text-field
            label="PLU:"
            v-model="plu"
            v-show="editable"
            :disabled="isPluDisabled || isCard || !isLocal"
            ref="plu"
            :success-messages="pluModifiedMessage"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex shrink class="display-flex align-center xs2">
      <v-tooltip bottom v-if="!isLocal && !isCard">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn flat icon class="ma-0" @click="$emit('bulkModifier:delete')" :disabled="isCard">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Remove from Selected Items</span>
      </v-tooltip>
      <div class="mr-1"></div>
      <v-tooltip bottom v-if="!isLocal">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-checkbox
              v-model="modifier.is.disabled"
              hide-details
              color="secondary"
              @click.native.stop
              :false-value="true"
              :true-value="false"
              class="pt-0 mt-2 mr-4 d-inline-block"
              :disabled="true"
            />
          </div>
        </template>
        <span>Enabled</span>
      </v-tooltip>
      <div v-if="isLocal">
        <v-tooltip bottom v-if="!isCard">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-checkbox
                :disabled="isCard"
                v-model="modifier.is.hidden"
                hide-details
                on-icon="mdi-eye-off"
                off-icon="mdi-eye"
                :color="hiddenColor"
                @click.native.stop
                :false-value="false"
                :true-value="true"
                class="pt-0 mt-2 mr-2 d-inline-block"
                :class="modifier.is.hidden === undefined && 'black-color'"
              />
            </div>
          </template>
          <span>
            <span v-if="modifier.is.hidden">Hidden in App</span>
            <span v-else-if="modifier.is.hidden === undefined">No changes</span>
            <span v-else>Display in App</span>
          </span>
        </v-tooltip>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-icon class="pt-0 mt-0 mr-2 d-inline-block" :color="hiddenColor">{{
                isHiddenIconName
              }}</v-icon>
            </div>
          </template>
          <span>
            <span v-if="modifier.is.hidden">Hidden in App</span>
            <span v-else>Display in App</span>
          </span>
        </v-tooltip>
      </div>
      <v-tooltip bottom v-if="isLocal">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-checkbox
              v-model="outOfStockProxy"
              ref="stock"
              :disabled="isCard"
              hide-details
              color="secondary"
              @click.native.stop
              :false-value="true"
              :true-value="false"
              class="pt-0 mt-2 mr-4 d-inline-block"
              :class="modifier.is.out_of_stock === undefined && 'black-color'"
              :success-messages="stockModifiedMessage"
            />
          </div>
        </template>
        <span v-if="modifier.is.out_of_stock === undefined && !isCard">No Changes To In Stock</span>
        <span v-else-if="modifier.is.out_of_stock">Out Of Stock</span>
        <span v-else>In Stock</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isLocal && !isCard">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn flat icon class="ma-0" @click="resetModifierToGlobalModState" :disabled="isCard">
              <v-icon small>mdi-restart</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Reset Changes</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import get from 'lodash/get';
import rules from '@/rules';
import cloneDeep from 'lodash/cloneDeep';
import { PRICE_RULE } from '@/constants';

export default {
  props: {
    modifier: Object,
    isCard: Boolean,
    currentGroup: Object,
    originalModifier: Object,
  },
  data: () => ({
    showActions: false,
    editLabel: false,
    labelRules: [rules.required('Label is required')],
    priceRules: [rules.min(PRICE_RULE.min), rules.max(PRICE_RULE.max)],
    calorieRules: [rules.min(0)],
    sortNumberRules: [rules.min(1)],
    editPrice: false,
    editable: false,
    items: [],
    taxoptions: ['Carbonated Beverage', 'Prepared', 'Sweetened Beverage'],
    backupModifier: {},
  }),
  computed: {
    ...mapGetters('adminPanel', ['isAdmin', 'isSiteOperator', 'hasSpecificPermissions']),
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('users', ['customPermissions']),
    ...mapState('menus', [
      'isLocal',
      'showPluField',
      'showPromoExemptField',
      'readOnlyMode',
      'menuVersion',
    ]),
    isHiddenIconName() {
      if (!this.modifier.is.hidden) return 'mdi-eye';
      if (this.modifier.is.hidden) return 'mdi-eye-off';
      return 'mdi-eye';
    },
    plu: {
      get() {
        return get(this.modifier, 'meta.plu');
      },
      set(v) {
        if (!this.modifier.meta) {
          this.$set(this.modifier, 'meta', { plu: v });
        } else if (!this.modifier.meta.plu) {
          this.$set(this.modifier.meta, 'plu', v);
        } else {
          this.modifier.meta.plu = v;
        }
      },
    },
    canEditPlu() {
      if (this.isAdmin) return true;
      const hasSpecificPermission = this.hasSpecificPermissions([
        this.customPermissions.overridePlu,
      ]);
      return this.isLocal && hasSpecificPermission;
    },
    modifierProxy: {
      get() {
        return this.modifier;
      },
      set(value) {
        this.$emit('update:modifier', value);
      },
    },
    sortNumberProxy: {
      get() {
        return this.modifierProxy.meta.sort_number;
      },
      set(value) {
        this.$set(this.modifierProxy.meta, 'sort_number', parseInt(value, 10) || undefined);
      },
    },
    isModifierValid: {
      get() {
        const isLabelValid = (this.$refs.label && this.$refs.label.valid) || false;
        const isPriceValid = (this.$refs.price && this.$refs.price.valid) || false;
        const isCaloriesValid = (this.$refs.calories && this.$refs.calories.valid) || false;
        const isPluValid = !this.$refs.plu || this.$refs.plu.valid || false;
        return isLabelValid && isPriceValid && isCaloriesValid && isPluValid;
      },
    },
    outOfStockProxy: {
      get() {
        if (this.modifierProxy.is && this.modifierProxy.is.out_of_stock) {
          return this.modifierProxy.is.out_of_stock;
        }
        return false;
      },
      set(v) {
        this.$set(this.modifierProxy.is, 'out_of_stock', v);
      },
    },
    promoExemptProxy: {
      get() {
        return (
          Array.isArray(this.modifierProxy.amount_off_exclusions) &&
          this.modifierProxy.amount_off_exclusions.includes('promo')
        );
      },
      set(v) {
        let exclusions = this.modifierProxy.amount_off_exclusions || [];
        if (v === true && !exclusions.find((e) => e === 'promo')) {
          exclusions.push('promo');
        }
        if (v === false) {
          exclusions = exclusions.filter((e) => e !== 'promo');
        }
        this.$set(this.modifierProxy, 'amount_off_exclusions', exclusions);
      },
    },
    calories: {
      get() {
        return (
          this.modifier.nutrition &&
          this.modifier.nutrition.calories &&
          this.modifier.nutrition.calories.amount
        );
      },
      set(value) {
        value = Number.isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);
        if (
          this.modifier.nutrition &&
          this.modifier.nutrition.calories &&
          this.modifier.nutrition.calories.amount
        ) {
          this.modifierProxy.nutrition.calories.amount = value;
        } else {
          this.$set(this.modifierProxy, 'nutrition', {
            calories: { amount: value },
          });
        }
      },
    },
    backupCalories: {
      get() {
        return (
          this.backupModifier.nutrition &&
          this.backupModifier.nutrition.calories &&
          this.backupModifier.nutrition.calories.amount
        );
      },
    },
    price: {
      get() {
        if (this.modifierProxy.price && this.modifierProxy.price.amount) {
          return this.modifierProxy.price.amount;
        }
        return '';
      },
      set(value) {
        if (value === '') {
          this.$delete(this.modifierProxy, 'price');
        } else {
          this.$set(this.modifierProxy, 'price', { amount: value });
        }
      },
    },
    hiddenColor() {
      if (this.modifier.is.hidden) {
        return 'red';
      }
      return 'green';
    },
    disableEditForModPLU() {
      if (
        this.isLocal &&
        this.isSiteOperator &&
        this.showPluField &&
        (this.plu === undefined || this.plu === null || this.plu.toString().length === 0)
      ) {
        return true;
      }
      return false;
    },
    isVersion1() {
      return this.menuVersion === 1;
    },
    isVersion2() {
      return this.menuVersion === 2;
    },
    globalModGroupsModifiersToDisplay() {
      if (!this.globalModGroupModifers || this.globalModGroupModifers.length === 0) {
        return [];
      }
      const modifiers = this.globalModGroupModifers.filter(
        (m) => !this.currentGroup.items.find((d) => d.id === m.id),
      );
      const currentMod = this.globalModGroupModifers.find((m) => m.id === this.modifier.id);
      if (currentMod) {
        modifiers.push(currentMod);
      }

      return modifiers.sort((a, b) => (a.label.en < b.label.en ? -1 : 1));
    },
    isPluDisabled() {
      return !this.canEditPlu || this.readOnlyMode || (!this.isLocal && this.isVersion2);
    },
    isSortNumberDisabled() {
      return this.disableEditForModPLU || this.readOnlyMode || (!this.isLocal && this.isVersion2);
    },
    globalModGroupModifers() {
      const modifiers =
        (this.currentGroup.globalModGroup && this.currentGroup.globalModGroup.items) || [];
      return cloneDeep(modifiers);
    },
    priceModifiedMessage() {
      const value = this.modifier.price && this.modifier.price.amount;
      const backupValue = this.backupModifier.price && this.backupModifier.price.amount;
      const isValueSame = (this.isCard && value === backupValue) || value === undefined;
      const isFieldValid = this.$refs.price && this.$refs.price.valid;
      return isFieldValid && !isValueSame ? ['Modified'] : [];
    },
    caloriesModifiedMessage() {
      const isValueSame =
        (this.isCard && this.calories === this.backupCalories) || this.calories === undefined;
      const isFieldValid = this.$refs.calories && this.$refs.calories.valid;
      return isFieldValid && !isValueSame ? ['Modified'] : [];
    },
    stockModifiedMessage() {
      const value = this.modifier.is && this.modifier.is.out_of_stock;
      const backupValue = this.backupModifier.is && this.backupModifier.is.out_of_stock;
      const isValueSame = (this.isCard && value === backupValue) || value === undefined;
      const isFieldValid = this.$refs.stock && this.$refs.stock.valid;
      return isFieldValid && !isValueSame ? ['Modified'] : [];
    },
    pluModifiedMessage() {
      const value = this.modifier.meta && this.modifier.meta.plu;
      const backupValue = this.backupModifier.meta && this.backupModifier.meta.plu;
      const isValueSame = (this.isCard && value === backupValue) || value === undefined;
      const isFieldValid = this.$refs.plu && this.$refs.plu.valid;
      return isFieldValid && !isValueSame ? ['Modified'] : [];
    },
  },
  async created() {
    if (!this.modifierProxy.label.en) {
      this.editable = true;
      await this.$nextTick();
      if (this.isVersion1) this.$refs.label.focus();
      if (this.isVersion2) this.$refs.modifierName.focus();
    }
    await this.$nextTick();
    if (!this.isModifierValid) {
      this.editable = true;
      this.$emit('modifier:invalid');
    }
  },
  methods: {
    ...mapActions('menus', ['getMenuItems', 'fetchGlobalModGroup']),
    edit() {
      if (this.disableEditForModPLU) return;
      this.editable = true;
    },
    disableEdit() {
      if (this.isModifierValid) {
        this.editable = false;
      }
    },
    setGlobalMod(id) {
      this.modifierProxy = this.globalModGroupModifers.find((m) => m.id === id);
    },
    resetModifierToGlobalModState() {
      this.modifierProxy = cloneDeep(this.backupModifier);
    },
  },
  mounted() {
    this.backupModifier = cloneDeep(this.originalModifier);
  },
};
</script>

<style scoped>
.modifier {
  padding: 0px 12px 0px 24px;
  min-height: 46px;
}
.modifier:hover {
  background-color: #ececec;
}
.modifierCard {
  padding: 0px 12px 0px 24px;
  min-height: 46px;
  max-height: 65px;
}
.modifierCard:hover {
  background-color: #ececec;
}
.editable:hover {
  cursor: text;
  text-decoration: underline;
}
div >>> .v-text-field__prefix {
  min-width: 28px;
}
.flex.sort_number {
  max-width: 3.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}
</style>
