<template>
  <ul v-if="pager.pages && pager.pages.length" class="pagination" :style="ulStyles">
    <li class="page-item first" :class="{ disabled: pager.currentPage === 1 }" :style="liStyles">
      <a class="page-link" @click="setPage(1)" :style="aStyles">{{ labels.first }}</a>
    </li>
    <li class="page-item previous" :class="{ disabled: pager.currentPage === 1 }" :style="liStyles">
      <a class="page-link" @click="setPage(pager.currentPage - 1)" :style="aStyles">{{
        labels.previous
      }}</a>
    </li>
    <li
      v-for="page in pager.pages"
      :key="page"
      class="page-item page-number"
      :class="{ active: pager.currentPage === page }"
      :style="liStyles"
    >
      <a class="page-link" @click="setPage(page)" :style="aStyles">{{ page }}</a>
    </li>
    <li
      class="page-item next"
      :class="{ disabled: pager.currentPage === pager.totalPages }"
      :style="liStyles"
    >
      <a class="page-link" @click="setPage(pager.currentPage + 1)" :style="aStyles">{{
        labels.next
      }}</a>
    </li>
    <li
      class="page-item last"
      :class="{ disabled: pager.currentPage === pager.totalPages }"
      :style="liStyles"
    >
      <a class="page-link" @click="setPage(pager.totalPages)" :style="aStyles">{{ labels.last }}</a>
    </li>
    <li class="page-item all" :class="{ active: pager.currentPage === 0 }" :style="liStyles">
      <a class="page-link" @click="setPage(0)" :style="aStyles">All</a>
    </li>
  </ul>
</template>

<script>
import paginate from 'jw-paginate';

const defaultLabels = {
  first: '<<',
  last: '>>',
  previous: '<',
  next: '>',
};

const defaultStyles = {
  ul: {
    margin: 0,
    padding: 0,
    display: 'inline-block',
  },
  li: {
    listStyle: 'none',
    display: 'inline',
    textAlign: 'center',
  },
  a: {
    cursor: 'pointer',
    padding: '6px 12px',
    display: 'block',
    float: 'left',
  },
};

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    maxPages: {
      type: Number,
      default: 10,
    },
    labels: {
      type: Object,
      default: () => defaultLabels,
    },
    styles: {
      type: Object,
    },
    disableDefaultStyles: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pager: {},
      ulStyles: {},
      liStyles: {},
      aStyles: {},
    };
  },
  created() {
    if (!this.$listeners.changePage) {
      console.error('Missing required event listener: "changePage"');
    }

    // set default styles unless disabled
    if (!this.disableDefaultStyles) {
      this.ulStyles = defaultStyles.ul;
      this.liStyles = defaultStyles.li;
      this.aStyles = defaultStyles.a;
    }

    // merge custom styles with default styles
    if (this.styles) {
      this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
      this.liStyles = { ...this.liStyles, ...this.styles.li };
      this.aStyles = { ...this.aStyles, ...this.styles.a };
    }

    // set to initial page
    this.setPage(this.initialPage);
  },
  methods: {
    setPage(page) {
      const { items, pageSize, maxPages } = this;

      // get new pager object for specified page
      const pager = paginate(items.length, page, pageSize, maxPages);

      // get new page of items from items array
      const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

      // update pager
      this.pager = pager;

      // this for handling "All"
      if (page === 0) {
        pager.currentPage = 0;
        this.$emit('changePage', [...items]);
      } else {
        // emit change page event to parent component
        this.$emit('changePage', pageOfItems);
      }
    },
  },
  watch: {
    items() {
      this.setPage(this.initialPage);
    },
  },
};
</script>

<style scoped>
.page-item .page-link {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 14px;
  background: transparent;
  height: 36px;
  width: 36px;
  margin: 0.3rem;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  text-align: center;
}

.page-item.active .page-link {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #1867c0 !important;
  border-color: #1867c0 !important;
  color: #fff;
}

.page-item.disabled .page-link {
  color: #6c757d;
}
</style>
