import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VFlex,
    {
      directives: [{ def: Ripple, name: "ripple", rawName: "v-ripple" }],
      class: _vm.flexClass,
      attrs: { xs12: "" }
    },
    [
      _c(
        VLayout,
        { attrs: { "align-center": "" } },
        [
          _vm.icon
            ? _c(VIcon, { on: { click: _vm.iconAction } }, [
                _vm._v(_vm._s(_vm.icon))
              ])
            : _vm._e(),
          _vm._t("prefix"),
          !_vm.edit
            ? _c(
                "span",
                { staticClass: "listspan" },
                [
                  _c(
                    "div",
                    { class: _vm.titleClass, style: _vm.titleStyle },
                    [
                      _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
                      !_vm.edit ? _vm._t("inlineActions") : _vm._e()
                    ],
                    2
                  ),
                  _vm._t("secondaryData"),
                  _vm.secondary
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "Caption-Selected-On-Surface-Medium-Emphasis-Left",
                          style: _vm.titleStyle
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.secondary) + "\n      "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            : _c(
                VLayout,
                { attrs: { "align-center": "" } },
                [
                  _c(VTextField, {
                    ref: "input",
                    attrs: {
                      value: _vm.title,
                      label: _vm.label,
                      "single-line": "",
                      rules: _vm.rules,
                      "error-messages": _vm.errors,
                      disabled: _vm.readOnlyMode
                    },
                    on: {
                      input: function($event) {
                        return _vm.$emit("update:title", $event)
                      },
                      blur: _vm.editDone,
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.editDone($event)
                      },
                      click: function($event) {
                        $event.stopPropagation()
                      },
                      mousedown: function($event) {
                        $event.stopPropagation()
                      },
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.stopPropagation()
                        $event.preventDefault()
                        return (function() {})($event)
                      }
                    }
                  }),
                  _vm._t("edit")
                ],
                2
              ),
          _c(VSpacer),
          !_vm.edit ? _vm._t("actions") : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }