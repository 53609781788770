<template>
  <v-layout fill-height>
    <loading-wrapper :loading="!active_company" mt="2vh" :size="100">
      <v-layout>
        <v-flex style="min-width: 300px; max-width: 450px" xs3>
          <nav-drawer
            :parentMenus="parentMenus"
            v-model="menus"
            ref="menu_nav"
            @setView="setView"
            @getMenu="getMenu"
            :active="{ category: activecategory, menu: activemenu }"
            :title="navTitle"
            :loading="locking || publishing"
            @menu:import="importMenuSet"
          />
        </v-flex>
        <v-flex
          v-if="
            (menusOriginal.length === 0 && readOnlyMode) ||
              (menusOriginal.length === 0 && activecategory == null && !readOnlyMode)
          "
        >
          <menu-helper @newMenuSet="$refs.menu_nav.newMenu($event)" v-if="!isLocalMenu" />
          <local-menu-warning v-else />
        </v-flex>
        <v-flex v-if="menusOriginal.length || isFirstMenu">
          <v-container grid-list-xl>
            <v-layout row wrap justify-space-between>
              <v-flex xs6>
                <v-text-field label="Search Menus" solo v-model="search" />
              </v-flex>
              <v-flex shrink>
                <v-btn color="primary" @click="edit" :loading="locking" :disabled="!canEdit">
                  <v-icon>mdi-pencil</v-icon>EDIT MENU
                </v-btn>
                <v-btn
                  color="primary"
                  @click="publishMenuAll"
                  :loading="publishing"
                  :disabled="!canPublish"
                >
                  <v-icon>mdi-upload</v-icon>publish
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="readOnlyMode"
                  @click="newItem"
                  v-if="!isLocalMenu && activeCategoryData"
                >
                  <v-icon>mdi-plus</v-icon>new item
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout v-if="recentMenu" row justify-end align-center>
              <menu-status-chip :menu-id="recentMenu.id">
                <span v-if="canKick">
                  <v-tooltip :disabled="!canKick" bottom max-width="150">
                    <template v-slot:activator="{ on }">
                      <v-btn @click="kick" icon v-on="on">
                        <v-icon color="red">mdi-minus-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Kick {{ recentMenuUser.name && recentMenuUser.name.first }}</span>
                  </v-tooltip>
                </span>
              </menu-status-chip>
            </v-layout>
            <loading-wrapper :loading="loadingItems" :size="40" mt="2vh">
              <v-layout row wrap v-if="activeCategoryData">
                <v-flex xs10>
                  <h2 style="width: fit-content">
                    <div class="category-heading">
                      {{ (activeCategoryData.label && activeCategoryData.label.en) || '' }}
                      <v-tooltip bottom v-if="showEditLocalCategoryNameIcon">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            flat
                            small
                            icon
                            @click.stop="openEditCategoryNameDialog"
                            @mousedown.stop
                            class="edit-btn"
                            v-on="on"
                            :disabled="readOnlyMode"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Category Name</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-if="showGlobalCategoryName"
                      class="Caption-Selected-On-Surface-High-Emphasis-Left secondary-text"
                    >
                      <strong>Original Name:</strong> {{ globalCategoryName }}
                    </div>
                  </h2>
                </v-flex>
                <v-flex xs2>Menu Version: {{ menuVersion }}</v-flex>
                <v-flex offset-xs10 style="display: flex; justify-content: center">
                  <span style="margin-right: 5px"><strong>Hide Inactive</strong></span>
                  <v-switch
                    :disabled="readOnlyMode"
                    color="primary"
                    v-model="hideDisabledItems"
                  ></v-switch>
                </v-flex>
                <v-flex xs12>
                  <v-layout justify-left align-center>
                    <v-flex xs1 style="padding-left: 25px; padding-bottom: 20px">
                      <v-checkbox
                        v-model="isAllItemsSelected"
                        primary
                        hide-details
                        @click.stop="toggleSelectedItems"
                        :disabled="readOnlyMode"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs1 style="padding-left: 0; padding-top: 20px">
                      <v-btn
                        flat
                        @click="showBulkUpdate"
                        v-if="updateMessage"
                        :disabled="isBulkUpdateDisabled"
                      >
                        <span class="Button-Primary-Center"> {{ updateMessage }}</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex>
                  <v-data-table
                    :headers="headers"
                    :items="activeCategoryData.items"
                    class="menutable"
                    :search="search"
                    :rows-per-page-items="rowsPerPageItems"
                    ref="sortableTable"
                  >
                    <template v-slot:headers="props" align-left>
                      <tr>
                        <th><v-spacer /></th>
                        <th style="text-align: left">
                          Sequence <br />
                          On Ticket
                          <v-btn
                            flat
                            small
                            icon
                            @mousedown.stop
                            class="edit-btn"
                            @click.stop="toggleChitNumberEdits"
                            v-if="!readOnlyMode && !showChitEditTextBoxes"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn
                            flat
                            icon
                            class="edit-btn"
                            v-if="showChitEditTextBoxes"
                            @click="toggleChitNumberEdits"
                          >
                            <v-icon small>mdi-check</v-icon>
                          </v-btn>
                        </th>
                        <th
                          v-for="header in props.headers"
                          :key="header.text"
                          style="text-align: left"
                        >
                          {{ header.text }}
                        </th>
                      </tr>
                    </template>
                    <template v-slot:items="props">
                      <item-row
                        :key="`${props.item.id}-${props.index}`"
                        @click.native="openItemConfig(props.item, true)"
                        :item="getItemCopy(props.item)"
                        :showChitEditTextBoxes="showChitEditTextBoxes"
                        :selected-items.sync="selectedItems"
                        @item:delete="deleteItem(props.item.id)"
                        @item:copy="copyItem(props.item.id)"
                        @item:groupaction="modifyGroupAction()"
                        class="sortableRow"
                      />
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </loading-wrapper>
          </v-container>
        </v-flex>
        <div v-if="showItemConfig">
          <item-config
            :showItemImageTab="getShowItemImageTab(activeitem)"
            :parentItem="getParentMenuItem(activeitem)"
            :hasDescEditPermission="hasDescEditPermission"
            :hasCaloriesEditPermissions="hasCaloriesEditPermissions"
            :hasLabelEditPermissions="hasLabelEditPermissions"
            :item="activeitem"
            :activeMenuId="activemenu"
            :isUSLocationBrand="isUSLocationBrand"
            :taxOptions="taxOptions"
            @update:item="updateItem"
            @cancelEditing="cancelEditing"
            v-if="activeCategoryData"
            ref="itemPanel"
          />
        </div>
        <category-name-dialog
          :open.sync="openCategoryDialog"
          :category="activeCategoryData"
          :parentCategory="parentMenuCategory"
        />
        <bulk-update
          v-if="isAnyItemsSelected"
          ref="bulkUpdatePanel"
          :menu="activemenu"
          :group="activeCategoryId"
          :updateMessage="updateMessage"
          :selectedItems.sync="selectedItems"
        />
      </v-layout>
    </loading-wrapper>
    <import-errors-modal :errors="importErrors" v-model="showImportErrorsModal" />
  </v-layout>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import values from 'lodash/values';
import DateTime from 'luxon/src/datetime.js';
import Sortable from 'sortablejs'; // eslint-disable-line
import ExcelJS from 'exceljs';
import ID from '@compassdigital/id';
import {
  tryGetRowValue,
  parseRow,
  createImportError,
  validateImportHeaders,
  findDuplicatesByField,
  getDuplicateErrorMessage,
} from '@/helpers/excelImport';
import {
  TENDER_TYPES,
  MEALPLAN_TYPES,
  IMAGE_TEMPLATE,
  IMAGE_AGES,
  IMAGE_ENTITY_TYPES,
  IMAGE_TEMPLATE_KEYS,
  PRICE_RULE,
} from '@/constants';
import { mergeReactive, generateCdlId, deepFreeze } from '@/helpers';
import MenuStatusChip from '@/components/menu-status-chip';
import MealplanHelperModule from '@/helpers/mealplan-helper-module';
import FileHelperModule from '@/helpers/file-helper-module';
import navDrawer from './nav';
import itemConfig from './itemConfig';
import bulkUpdate from './bulkUpdate';
import categoryNameDialog from './categoryNameDialog';
import menuHelper from './helpers/addMenuSet';
import localMenuWarning from './helpers/localmenus';
import itemRow from './item';
import importErrorsModal from '../importErrorsModal';

export default {
  name: 'menuEditor',
  components: {
    'menu-status-chip': MenuStatusChip,
    navDrawer,
    menuHelper,
    localMenuWarning,
    itemConfig,
    itemRow,
    categoryNameDialog,
    bulkUpdate,
    importErrorsModal,
  },
  async beforeRouteLeave(to, from, next) {
    if (this.modified && !this.skipRouteCheck) {
      const input = await this.$confirm({
        title: 'Leave without publishing?',
        message: 'Changes will be lost if you do not publish.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        if (Object.keys(this.newImageMap).length) {
          /* clean new unused images from s3 */
          await this.cleanImages({
            imageKeyList: Object.keys(IMAGE_TEMPLATE_KEYS.menu),
            age: IMAGE_AGES.new,
          });
        }
        this.resetImageMaps();
        this.cleanUp();
        await this.unlockMenus(true);
        return next();
      }
      return next(false);
    }
    this.cleanUp();
    await this.unlockMenus();
    return next();
  },
  data: () => ({
    showItemConfig: false,
    meta: {},
    skipRouteCheck: false,
    version: {
      version1: 1,
      version2: 2,
    },
    menus: [],
    menusOriginal: [],
    activeitem: {},
    activemenu: null,
    activecategory: null,
    hideDisabledItems: false,
    globalHeaders: [
      { text: 'Item Name', value: 'label.en', sortable: false },
      { text: 'Enabled', value: 'is.disabled', sortable: false },
      { text: 'Price', value: 'price', sortable: false },
      { text: 'PLU', value: 'meta.plu', sortable: false },
      { text: 'Barcode', value: 'meta.barcodes', sortable: false },
      { text: 'Calories', value: 'calories', sortable: false },
      { text: '', value: '', sortable: false },
    ],
    localHeaders: [
      { text: 'Item Name', value: 'label.en', sortable: false },
      { text: 'Visible', value: 'is.hidden', sortable: false },
      { text: 'Price', value: 'price', sortable: false },
      { text: 'PLU', value: 'meta.plu', sortable: false },
      { text: 'Barcode', value: 'meta.barcodes', sortable: false },
      { text: 'Calories', value: 'calories', sortable: false },
      { text: 'MEQ Eligible', value: 'is.meq_eligible', sortable: false },
      { text: 'In Stock', value: 'is.out_of_stock', sortable: false },
      { text: 'P.Exempt', value: 'amount_off_exclusions', sortable: false },
    ],
    menusDownloadedOnPage: {},
    search: '',
    sessionExpiry: null,
    sessionExpirySeen: false,
    sessionInterval: null,
    sortable: null,
    locking: false,
    publishing: false,
    saveNewItem: null,
    parentMenus: [],
    openCategoryDialog: false,
    showChitEditTextBoxes: false,
    itemsSelected: [],
    selectedItems: {},
    localCategoriesSchedule: [],
    localItemsSchedule: [],
    taxOptions: [
      'Alcohol',
      'Baked Goods',
      'Candy',
      'Carbonated Beverage',
      'Tax Exempt',
      'Pop',
      'Prepared',
      'Sweetened Beverage',
      'Unprepared',
      'Water',
      'Meal',
      'Good',
    ],
    importing: false,
    importErrors: [],
    showImportErrorsModal: false,
    importMenuCategoryValues: {
      'Category ID': { type: 'string', required: false },
      'Category Name': { type: 'string', required: true },
      'Category Sequence On Ticket': { type: 'number', integerOnly: true, required: false },
      'Category Enabled': { type: 'boolean', required: true },
    },
    importMenuItemValues: {
      'Item ID': { type: 'string', required: false },
      'Item Name': { type: 'string', required: true },
      Price: { type: 'number', integerOnly: false, required: false },
      Calories: { type: 'number', integerOnly: true, required: false },
      Description: { type: 'string', required: false },
      'Item Sequence On Ticket': { type: 'number', integerOnly: true, required: false },
      PLU: { type: 'number', integerOnly: true, required: false },
      'Item Enabled': { type: 'boolean', required: true },
      'Tax Tags': { type: 'string', required: false },
      Barcodes: { type: 'string', required: false },
      Units: { type: 'number', integerOnly: true, required: false },
    },
    importMenuItemModValues: {
      'Modifier Group ID': { type: 'string', required: true },
      'Modifier Group Name': { type: 'string', required: false },
    },
    isFirstMenu: false,
    shouldForceMenuRefetch: false,
    unpublishedActiveMenuIndex: null,
    rowsPerPageItems: [25, 50, 100],
    loadingItems: false,
  }),
  computed: {
    ...mapGetters('splitio', ['getFeatureFlags']),
    ...mapState('adminPanel', ['user']),
    ...mapState('sites', ['brandMap', 'active_brand']),
    ...mapState('sectors', ['active_sector', 'active_company']),
    ...mapState('menus', [
      'showPluField',
      'showPromoExemptField',
      'showMEQField',
      'readOnlyMode',
      'sessionLength',
      'companyGlobalModGroups',
      'menuVersion',
    ]),
    ...mapState('file', ['newImageMap']),
    ...mapState('users', ['usersMap', 'customPermissions']),
    ...mapGetters('adminPanel', ['hasRole', 'hasSpecificPermissions', 'isSiteOperator']),
    ...mapGetters('menus', ['getCompanyMenuRecent', 'isSomeMenuLocked', 'isMenuLocked']),
    isPartialCategoriesActive() {
      return this.getFeatureFlags['ap3-menu-partial-categories'];
    },
    isPartialItemsActive() {
      return this.getFeatureFlags['ap3-menu-partial-items'];
    },
    canPublish() {
      return !this.readOnlyMode && this.modified && this.isValid;
    },
    isLocalMenu() {
      this.setLocal(!!this.$route.params.brand_id);
      return !!this.$route.params.brand_id;
    },
    companyId() {
      return this.$route.params.company_id;
    },
    sectorId() {
      return this.$route.params.sector_id;
    },
    headers() {
      if (this.isLocalMenu) {
        let headers = cloneDeep(this.localHeaders);
        if (!this.showPluField) {
          headers = headers.filter((h) => h.text !== 'PLU');
        }
        if (!this.showPromoExemptField) {
          headers = headers.filter((h) => h.text !== 'P.Exempt');
        }
        if (!this.showMEQField) {
          headers = headers.filter((h) => h.text !== 'MEQ Eligible');
        }
        return headers;
      }
      return this.globalHeaders;
    },
    activeCategoryId: {
      get() {
        return this.currentMenu?.groups?.[this.activecategory]?.id;
      },
    },
    activeCategoryData: {
      get() {
        if (this.activemenu === null && this.activecategory === null) {
          return false;
        }
        const menu = this.currentMenu || this.menus.find((e) => e.label.en === this.activemenu);
        if (!menu || !menu.groups) {
          return {};
        }
        if (this.hideDisabledItems) {
          const items = menu.groups[this.activecategory].items.filter((i) =>
            this.$route.name === 'menu-global' ? !i.is.disabled : !i.is.hidden,
          );
          return {
            ...menu.groups[this.activecategory],
            items,
          };
        }
        return menu.groups[this.activecategory];
      },
      set(value) {
        this.currentMenu.groups[this.activecategory] = {
          ...this.currentMenu.groups[this.activecategory],
          ...value,
        };
      },
    },
    activeLocation() {
      if (this.activeBrand) {
        return this.activeBrand.parentLocation;
      }
      return null;
    },
    activeBrand() {
      if (this.$route.params.brand_id) {
        return this.brandMap[this.$route.params.brand_id];
      }
      return null;
    },
    navTitle() {
      if (this.activeLocation && this.activeBrand) {
        return `${this.activeBrand.name} @${this.activeLocation.name}`;
      }
      return '';
    },
    modified() {
      if (!this.isLocalMenu && this.menus.length !== this.menusOriginal.length) {
        return true;
      }
      for (let i = 0; i < this.menus.length; i += 1) {
        const menu = this.menus[i];
        const menuBackup = this.menusOriginal.find((e) => e.id === menu.id);
        if (menu && menuBackup) {
          if (!isEqual(menu, menuBackup)) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    },
    isUserEditing() {
      try {
        return this.user.id === this.recentMenuUser.id;
      } catch (err) {
        return false;
      }
    },
    isValid() {
      return this.$store.state.menus.isMenuValid;
    },
    parentMenuCategory() {
      if (!this.isLocalMenu) return undefined;
      const parentMenu = this.parentMenus.find(
        (parent) => parent.id === this.currentMenu.parent_id,
      );
      if (!parentMenu || !parentMenu.groups) return undefined;
      if (!this.activeCategoryData) return undefined;
      return parentMenu.groups.find((g) => g.id === this.activeCategoryData.id);
    },
    globalCategoryName() {
      if (!this.parentMenuCategory) return '';
      return (this.parentMenuCategory.label && this.parentMenuCategory.label.en) || '';
    },
    showGlobalCategoryName() {
      if (!this.isLocalMenu || !this.globalCategoryName) return false;
      const localCaterogyName =
        (this.activeCategoryData &&
          this.activeCategoryData.label &&
          this.activeCategoryData.label.en) ||
        '';
      return this.globalCategoryName !== localCaterogyName;
    },
    currentMenu() {
      if (!this.activemenu || !this.menus) return {};
      return this.menus.find((m) => m.id === this.activemenu);
    },
    showEditLocalCategoryNameIcon() {
      try {
        const isAdmin = this.hasRole('admin');
        const userHasSpecificPermission = this.user.permissions.scopes.includes(
          this.customPermissions.renameLocalMenu,
        );
        return this.isLocalMenu && (isAdmin || userHasSpecificPermission);
      } catch (err) {
        console.error('Error checking permissions for category renaming', err);
        return false;
      }
    },
    isCurrentMenuLocked() {
      if (!this.currentMenu.id) return false;

      const locked_by_user = this.currentMenu.meta && this.currentMenu.meta.locked_by_user;
      if (!locked_by_user) return false;

      return typeof locked_by_user === 'string'
        ? locked_by_user !== this.user.id
        : locked_by_user.id !== this.user.id;
    },
    recentMenuUser() {
      const defaultUser = { name: { first: 'User' } };
      const menu = this.recentMenu;
      try {
        const user = menu && (menu.meta.locked_by_user || menu.meta.last_modified_user);
        return this.usersMap[user] || defaultUser;
      } catch (err) {
        return defaultUser;
      }
    },
    canEdit() {
      return (
        this.readOnlyMode &&
        !this.isSomeMenuLocked(
          this.isLocalMenu,
          this.user.id,
          this.recentMenu.location_brand,
          this.companyId,
        )
      );
    },
    canKick() {
      return (
        this.hasSpecificPermissions([this.customPermissions.kickMenu]) &&
        this.readOnlyMode &&
        !this.isUserEditing &&
        this.isSomeMenuLocked(
          this.isLocalMenu,
          this.user.id,
          this.recentMenu.location_brand,
          this.companyId,
        )
      );
    },
    recentMenu() {
      if (this.isLocalMenu) {
        const brand = this.menus.find((m) => m.location_brand).location_brand;
        return this.getCompanyMenuRecent(this.companyId, brand);
      }

      return this.getCompanyMenuRecent(this.companyId);
    },
    isLockedToUser() {
      return (
        this.user &&
        this.isSomeMenuLocked(
          this.isLocalMenu,
          undefined,
          this.$route.params.brand_id,
          this.companyId,
        ) &&
        !this.isSomeMenuLocked(
          this.isLocalMenu,
          this.user.id,
          this.$route.params.brand_id,
          this.companyId,
        )
      );
    },
    hasDescEditPermission() {
      try {
        const isAdmin = this.hasRole('admin');
        const editItemDescEnabled = !!(
          this.currentMenu &&
          this.currentMenu.is &&
          this.currentMenu.is.item_desc_edit_enabled
        );
        const userHasSpecificPermission = this.user.permissions.scopes.includes(
          this.customPermissions.editItemDescription,
        );
        return isAdmin || (editItemDescEnabled && userHasSpecificPermission);
      } catch (err) {
        console.error('Error checking permissions for category description edit', err);
        return false;
      }
    },
    hasCaloriesEditPermissions() {
      try {
        if (this.hasRole('admin')) return true;
        const editCaloriesEnabled =
          this.currentMenu && this.currentMenu.is && this.currentMenu.is.calories_edit_enabled;
        const userHasSpecificPermission = this.user.permissions.scopes.includes(
          this.customPermissions.editCalories,
        );
        return editCaloriesEnabled && userHasSpecificPermission;
      } catch (err) {
        console.error('Error checking permissions for calories edit', err);
        return false;
      }
    },
    hasLabelEditPermissions() {
      try {
        if (this.hasRole('admin')) return true;
        const editLabelEnabled =
          this.currentMenu && this.currentMenu.is && this.currentMenu.is.item_label_edit_enabled;
        const userHasSpecificPermission = this.user.permissions.scopes.includes(
          this.customPermissions.renameLocalItems,
        );
        return editLabelEnabled && userHasSpecificPermission;
      } catch (err) {
        console.error('Error checking permissions for calories edit', err);
        return false;
      }
    },
    isAllItemsSelected() {
      return (
        this.activeCategoryData &&
        this.activeCategoryData.items &&
        this.activeCategoryData.items.length === this.selectedItemsCount
      );
    },
    selectedItemsCount() {
      return Object.keys(this.selectedItems).length;
    },
    isAnyItemsSelected() {
      return this.selectedItemsCount > 0;
    },
    updateMessage() {
      if (!this.activeCategoryData.items || this.selectedItemsCount === 0)
        return 'Please select items for bulk update';

      return this.selectedItemsCount === 1
        ? 'Update 1 Item'
        : `Update ${this.selectedItemsCount} Items`;
    },
    isVersion2() {
      return this.menuVersion === this.version.version2;
    },
    isBulkUpdateDisabled() {
      return (
        !this.isAnyItemsSelected ||
        this.readOnlyMode ||
        Object.values(this.selectedItems).some((item) => this.checkItemDisabledForPlu(item))
      );
    },
    isUSLocationBrand() {
      return this.currentMenu && this.currentMenu.is && this.currentMenu.is.is_us_location_brand;
    },
  },
  methods: {
    ...MealplanHelperModule,
    ...FileHelperModule,
    ...mapMutations('users', ['cacheUser']),
    ...mapMutations('menus', [
      'setLocal',
      'setReadOnlyMode',
      'setMenuVersion',
      'setShowPluField',
      'setMenus',
      'setCompanyGlobalModGroups',
    ]),
    ...mapMutations('file', ['setOriginalImageObjectMap']),
    ...mapActions('file', ['cleanImages', 'resetImageMaps']),
    ...mapActions('sites', ['fetchBrand']),
    ...mapActions('menus', [
      'fetchCompanyMenu',
      'fetchMenu',
      'fetchMenuGroupItems',
      'fetchMenuItemModGroups',
      'postMenu',
      'putMenu',
      'patchMenu',
      'deleteMenu',
      'lockMenu',
      'fetchCompanyGlobalModGroups',
      'fetchGlobalModGroup',
    ]),
    ...mapActions('users', ['fetchUser']),
    renderSortable(sort = false) {
      setTimeout(() => {
        try {
          if (this.sortable && this.sortable.el) {
            this.sortable.destroy();
          }
          // need this hack since v-data-table can't work with vuedraggable
          this.sortable = Sortable.create(
            this.$refs.sortableTable.$el.getElementsByTagName('tbody')[0],
            {
              draggable: '.sortableRow',
              onEnd: this.dragReorder,
              onMove: () => !this.readOnlyMode,
              sort,
            },
          );
          this.sortable.option('disabled', !sort);
          this.sortable.option('sort', sort);
        } catch (err) {
          // The menu item table may not be rendered yet
        }
      }, 100);
    },
    async loadMenuGroupItems(menuId, groupId) {
      const items = await this.fetchMenuGroupItems({
        menu_id: menuId,
        group_id: groupId,
        nocache: true,
      });
      const currentMenu = this.menus.find((m) => m.id === menuId);
      const currentGroup = currentMenu.groups.find((g) => g.id === groupId);
      this.$set(currentGroup, 'items', items);

      const menuOriginal = this.menusOriginal.find((m) => m.id === menuId);
      const newMenu = cloneDeep(menuOriginal);
      if (newMenu.groups) {
        const newMenuCategory = newMenu.groups.find((g) => g.id === groupId);
        if (newMenuCategory) newMenuCategory.items = cloneDeep(currentGroup.items);
      }
      const newMenuOriginal = mergeReactive(cloneDeep(menuOriginal), cloneDeep(newMenu), false);
      this.replaceInArrayById(newMenuOriginal, this.menusOriginal);

      return cloneDeep(items);
    },
    async setView({ category, menu, expandAction }) {
      if (!menu) return;
      const isSameMenu = this.activemenu === menu;
      if (this.activeCategoryData) {
        // freezing previous category
        deepFreeze(this.activeCategoryData.items);
      }
      if (!(await this.checkSelectedItems())) {
        return;
      }
      if (
        this.shouldForceMenuRefetch ||
        !this.menusDownloadedOnPage[menu] ||
        (!isSameMenu && !this.isMenuModified(menu))
      ) {
        await this.loadMenu(menu, { partial: this.isPartialCategoriesActive });
        this.menusDownloadedOnPage[menu] = true;
        this.shouldForceMenuRefetch = false;
      }
      this.activemenu = menu;
      const activeMenu = this.menus.find((m) => m.id === this.activemenu);
      if (!activeMenu) {
        this.unpublishedActiveMenuIndex = this.menus
          .map((_menu) => _menu?.label?.en)
          .indexOf(this.activemenu);
      }
      const showPluField =
        !this.isLocalMenu || (activeMenu && activeMenu.is && activeMenu.is.plu_enabled) || false;
      const showPromoExemptField =
        (activeMenu && activeMenu.is && activeMenu.is.promo_exemptions_enabled) || false;
      const version = !activeMenu
        ? this.version.version2
        : (activeMenu.meta && activeMenu.meta.version) || this.version.version1;
      this.setMenuVersion(version);
      this.$store.commit('menus/setShowPluField', showPluField);
      this.$store.commit('menus/setShowPromoExemptField', showPromoExemptField);
      this.$store.commit('menus/setShowMEQField', this.isLocalMenu);
      this.activecategory = category;
      const group = activeMenu?.groups[category];
      if (
        !expandAction &&
        this.isPartialCategoriesActive &&
        group &&
        !group.items.length &&
        this.isExistingCategory(group)
      ) {
        this.loadingItems = true;
        await this.loadMenuGroupItems(menu, group.id);
        this.loadingItems = false;
      }
      this.unFreezeActiveCategoryItems();
      this.loadCompanyGlobalModGroups();
      this.renderSortable(!this.readOnlyMode);
    },
    isExistingCategory(category) {
      if (!this.activemenu) return false;
      const menuOriginal = this.menusOriginal.find((m) => m.id === this.activemenu);
      return menuOriginal.groups.find((g) => g.id === category.id);
    },
    async loadCompanyGlobalModGroups() {
      if (this.isVersion2) {
        if (
          !this.companyGlobalModGroups ||
          !Array.isArray(this.companyGlobalModGroups) ||
          this.companyGlobalModGroups.length === 0
        ) {
          return this.fetchCompanyGlobalModGroups({ id: this.companyId });
        }
      }
      return Promise.resolve();
    },
    async checkSelectedItems() {
      if (!this.isAnyItemsSelected) return true;
      const input = await this.$confirm({
        title: 'Leave without bulk updates?',
        message: 'If you navigate to another category you will lose your selected items',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        this.selectedItems = {};
      }
      return input;
    },
    newItem() {
      const item = {
        id: generateCdlId('menu', 'cdl', 'item'),
        label: { en: '' },
        description: {},
        is: {
          disabled: false,
        },
        nutrition: {
          calories: { amount: null },
        },
        meta: {
          taxes: [],
          on_sale: false,
        },
        options: [],
        price: { amount: 0 },
        item_number: null,
      };
      this.openItemConfig(item);

      // need this to add new item to table on save
      const itemCategory = this.activeCategoryData;
      this.saveNewItem = (newItem) => {
        itemCategory.items.push(newItem);
      };
    },
    async getMenus() {
      try {
        if (this.isLocalMenu) {
          await this.getLocal();
        } else {
          await this.getGlobal();
        }
      } catch (err) {
        console.error(err);
        this.$toast.error('Failed to fetch menus');
      }
    },
    async loadMenu(menuId, { refresh = false, partial = undefined } = {}) {
      if (!menuId || !ID(menuId)) return;
      if (partial === undefined) {
        partial = this.isPartialCategoriesActive;
      }
      const menu = this.menus.find((m) => m.id === menuId);
      const menuWasAlreadyDownloaded = !!menu.groups;
      const promises = [];

      if (!this.shouldForceMenuRefetch && menuWasAlreadyDownloaded && !refresh) {
        await this.loadParentMenu(menu);
        return;
      }
      const menuPromise = this.fetchMenu({
        id: menu.id,
        nocache: true,
        refreshCache: menuWasAlreadyDownloaded,
        partial,
      })
        .then((menu_response) => {
          if (!menu_response.label || !menu_response.label.en) {
            menu_response.label = { en: 'Untitled' };
          }

          if (!menu_response.is) {
            menu_response.is = {};
          }
          menu_response.is = {
            ...menu_response.is,
            disabled: menu_response.is.disabled || false,
            linked: menu_response.is.linked || false,
            hidden: menu_response.is.hidden || false,
          };

          if (menu_response.is.plu_enabled) this.setShowPluField(true);
          if (!menu_response.meta) menu_response.meta = {};
          this.replaceInArrayById(menu_response, this.menus, true);
          this.replaceInArrayById(cloneDeep(menu_response), this.menusOriginal);
        })
        .catch((err) => {
          console.error(`Could not get menu with id ${menu.id}`, err);
          this.$toast.error('Could not fetch data');
        });
      promises.push(menuPromise);
      if (this.isLocalMenu && menu.parent_id && !menuWasAlreadyDownloaded) {
        const parentPromise = this.fetchMenu({ id: menu.parent_id, noLocalCache: true, partial })
          .then((res) => {
            this.replaceInArrayById(res, this.parentMenus);
          })
          .catch((err) => {
            console.error(`Could not get parent menu with id ${menu.parent_id}`, err);
            this.$toast.error('Could not fetch data');
          });
        promises.push(parentPromise);
      }

      await Promise.all(promises);
    },

    async loadParentMenu(menu) {
      if (this.isLocalMenu && menu.parent_id) {
        const parentMenu = this.parentMenus.find((parent) => parent.id === menu.parent_id);
        if (!parentMenu || !parentMenu.groups) {
          await this.fetchMenu({ id: menu.parent_id, noLocalCache: true })
            .then((res) => {
              this.replaceInArrayById(res, this.parentMenus);
            })
            .catch((err) => {
              console.error(`Could not get parent menu with id ${menu.parent_id}`, err);
              this.$toast.error('Could not fetch data');
            });
        }
      }
    },
    isMenuModified(menuId) {
      if (this.readOnlyMode) return false;
      const menuOriginal = this.menusOriginal.find((m) => m.id === menuId);
      const menu = this.menus.find((m) => m.id === menuId);
      return menuOriginal && menu && !isEqual(menu, menuOriginal);
    },
    replaceInArrayById(newValue, arrayOfValues, menuEditable = false) {
      const foundIndex = arrayOfValues.findIndex((item) => item.id === newValue.id);
      const menuToInsert = menuEditable ? newValue : deepFreeze(newValue);
      if (menuEditable && Array.isArray(newValue.groups)) {
        newValue.groups.forEach((g) => deepFreeze(g.items));
      }
      if (foundIndex > -1) {
        arrayOfValues.splice(foundIndex, 1, menuToInsert);
      } else {
        arrayOfValues.push(menuToInsert);
      }
    },
    async getMenusCall(filter, saveParentMenus = false) {
      this.setReadOnlyMode(true);
      let { menus: global } = await this.fetchCompanyMenu({ id: this.companyId, nocache: true });
      global.forEach((m) => {
        delete m.groups; // could be taken from local cache;
      });
      const isInitialising = !this.menus || !this.menus.length;

      if (saveParentMenus && isInitialising) {
        this.parentMenus = global.filter((m) => !m.parent_id).map((m) => deepFreeze(m));
      }

      global = global.filter(filter);

      if (isInitialising) {
        this.menus = cloneDeep(global)
          .filter((e) => e.label && e.label.en !== 'Untitled')
          .sort((a, b) => (a.is && a.is.disabled < b.is && b.is.disabled ? -1 : 1))
          .map((m) => {
            if (Array.isArray(m.groups)) {
              m.groups.forEach((g) => {
                // don't freeze active category
                if (this.activeCategoryData && this.activeCategoryData.id === g.id) return;
                deepFreeze(g.items);
              });
            }
            return m;
          });
        this.isFirstMenu = global.length === 1 || !this.menus.length;
        this.menusOriginal = cloneDeep(this.menus).map((m) => deepFreeze(m));
      }
    },
    async getGlobal() {
      await this.getMenusCall((e) => !e.parent_id);
    },
    async getLocal() {
      await this.getMenusCall(
        (e) =>
          e.location_brand === this.$route.params.brand_id && e.is && !e.is.disabled && e.is.linked,
        true,
      );
    },
    updateItem(newItem) {
      this.activeitem = Object.assign(this.activeitem, newItem);
      if (this.saveNewItem) {
        this.saveNewItem(newItem);
        this.saveNewItem = null;
      } else if (newItem.id) {
        this.$set(
          this.currentMenu.groups[this.activecategory],
          'items',
          this.activeCategoryData.items.map((i) => (i.id === newItem.id ? newItem : i)),
        );
      }
      this.activeItem = null;
      this.showItemConfig = false;
    },
    async openItemConfig(item, partial = false) {
      this.showItemConfig = true;
      await this.$nextTick();
      this.activeitem = item;
      this.$refs.itemPanel.openConfig();

      if (
        partial &&
        this.isPartialCategoriesActive &&
        this.isPartialItemsActive &&
        !item.options.length &&
        this.isExistingItem(item)
      ) {
        this.$refs.itemPanel.setLoadingModifierGroups(true);
        const items = await this.fetchMenuItemModGroups({
          menu_id: this.activemenu,
          group_id: this.activeCategoryId,
          item_id: item.id,
          nocache: true,
          show_unlinked: false,
        });
        item.options = items[0]?.options;
        this.activeitem = item;
        this.$refs.itemPanel.setModifierGroups(item.options);
        this.$refs.itemPanel.setLoadingModifierGroups(false);

        const activeMenu = this.menus.find((m) => m.id === this.activemenu);
        const activeItem = activeMenu.groups[this.activecategory].items.find(
          (i) => i.id === item.id,
        );
        this.$set(activeItem, 'options', item.options);

        const menuOriginal = this.menusOriginal.find((m) => m.id === this.activemenu);
        const newMenu = cloneDeep(menuOriginal);
        const newMenuGroup = newMenu.groups.find(
          (g) => g.id === this.currentMenu.groups[this.activecategory].id,
        );
        const newMenuItem = newMenuGroup.items.find((_item) => _item.id === item.id);
        newMenuItem.options = cloneDeep(item.options);
        const newMenuOriginal = mergeReactive(cloneDeep(menuOriginal), cloneDeep(newMenu), false);
        this.replaceInArrayById(newMenuOriginal, this.menusOriginal);
      }
    },
    isExistingItem(item) {
      if (!this.activemenu) return false;
      const menuOriginal = this.menusOriginal.find((m) => m.id === this.activemenu);
      return menuOriginal.groups[this.activecategory].items.find((i) => i.id === item.id);
    },
    showBulkUpdate() {
      this.$refs.bulkUpdatePanel.openConfig();
    },
    // Menu calls
    async publishMenuAll() {
      if (!ID(this.activemenu)?.id) this.shouldForceMenuRefetch = true;
      // determine delete
      this.publishing = true;
      // otherwise publish button freezes in not loading state
      await new Promise((resolve) => setTimeout(resolve, 2));
      const requests = [];
      const updatedIDs = this.menus.map((e) => e.id).filter(Boolean);
      const { menus: latestCompanyMenus } = await this.fetchCompanyMenu({
        id: this.companyId,
        nocache: true,
      });
      const userCanStillEditMenus = (
        await Promise.all(
          this.menusOriginal.map(async (m) => {
            if (!m.id || !m.label) return true; // new menus or first menu when company menus created
            const menu = latestCompanyMenus.find((latest) => latest.id === m.id);
            return menu.meta && menu.meta.locked_by_user === this.user.id;
          }),
        )
      ).some(Boolean);
      if (!userCanStillEditMenus && !this.isFirstMenu) {
        await this.$confirm({
          title: 'You have been kicked out.',
          message: 'All of your changes have not been saved.',
          buttonTrueText: 'OK',
          buttonFalseText: null,
          width: 800,
        });
        this.cleanUp();
        this.$store.commit('adminPanel/setLoading', false);
        this.$router.go();
        return;
      }
      if (this.isLocalMenu) {
        updatedIDs.forEach((id) => {
          const menuOriginal = this.menusOriginal.find((m) => m.id === id);
          const menu = this.menus.find((m) => m.id === id);
          const menusEqual = isEqual(menu, menuOriginal);
          const isMenuLockedByCurrentUser = menu.meta && menu.meta.locked_by_user === this.user.id;
          const meta = menu && menu.meta;
          if (meta) menu.meta.locked_by_user = null;
          if (menusEqual) {
            // Unlock the menu
            if (isMenuLockedByCurrentUser) {
              requests.push(this.lockMenu({ id: menu.id, meta }));
            }
            return;
          }
          requests.push(this.publishLocalMenu(menu));
        });
      } else {
        const deleteMenus = this.menusOriginal
          .map((e) => e.id)
          .filter((e) => !updatedIDs.includes(e));
        const newMenus = this.menus.filter((e) => !e.id);
        newMenus.forEach((menu) => {
          menu.company = this.active_company.id;
          menu.sector = this.active_sector.id;
        });
        requests.push(...newMenus.map((e) => this.addMenu(e)));
        if (deleteMenus) {
          this.$toast('Deleting menu... Please remember to unschedule deleted menus.');
        }
        requests.push(...deleteMenus.map((e) => this.deleteMenu(e)));
        updatedIDs.forEach((id) => {
          const menu = this.menus.find((m) => m.id === id);
          const menuOriginal = this.menusOriginal.find((m) => m.id === id);
          const menusEqual = isEqual(menu, menuOriginal);
          const isMenuLockedByCurrentUser = menu.meta && menu.meta.locked_by_user === this.user.id;
          const meta = menu && menu.meta;
          if (meta) menu.meta.locked_by_user = null;
          if (menusEqual) {
            // Unlock the menu
            if (isMenuLockedByCurrentUser) {
              requests.push(this.lockMenu({ id: menu.id, meta }));
            }
            return;
          }
          const mergeMenu = (modified_payload, original_payload) => {
            if (original_payload === undefined) return modified_payload;
            if (typeof modified_payload === 'object' && modified_payload !== null) {
              if (Array.isArray(modified_payload)) {
                const modified_dict = modified_payload
                  .filter((i) => i.id)
                  .reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), []);
                const original_dict = original_payload
                  .filter((i) => i.id)
                  .reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), []);
                const deleted = original_payload
                  .filter((i) => i.id && !modified_dict[i.id])
                  .map((i) => ({ ...i, _delete: true }));
                return [
                  ...modified_payload.map((i) => (i.id ? mergeMenu(i, original_dict[i.id]) : i)),
                  ...deleted,
                ];
              }
              return Object.fromEntries(
                Object.entries(modified_payload).map(([key, value]) => [
                  key,
                  mergeMenu(value, original_payload[key]),
                ]),
              );
            }
            return modified_payload;
          };
          requests.push(
            this.publishGlobalMenu(
              this.isPartialCategoriesActive ? mergeMenu(menu, menuOriginal) : menu,
            ),
          );
        });
      }
      try {
        await Promise.all(requests);
        const { menus: publishedCompanyMenus } = await this.fetchCompanyMenu({
          id: this.companyId,
          nocache: true,
        });
        await this.processDisabledGlobalMenu(this.menus);
        this.$toast('Menu successfully published');
        this.menusOriginal = [];
        if (this.menus.length === 0) {
          // one menu should always exist for company to show up on menu/sector page
          const hidden_menu = await this.postMenu({
            company: this.companyId,
            sector: this.sectorId,
          });
          this.replaceInArrayById(hidden_menu, this.menusOriginal);
        } else {
          this.menus.forEach((m) => {
            const latestMenu = publishedCompanyMenus.find((latest) => latest.id === m.id);
            m.date = latestMenu.date;
            this.replaceInArrayById(cloneDeep(m), this.menusOriginal);
          });
        }
        this.renderSortable(false);
        this.setReadOnlyMode(true);
        try {
          /* clean old unused images from s3 */
          await this.cleanImages({
            imageKeyList: Object.keys(IMAGE_TEMPLATE_KEYS.menu),
            type: IMAGE_ENTITY_TYPES.menu,
            entities: this.menus,
            age: IMAGE_AGES.old,
          });
          if (Object.keys(this.newImageMap).length) {
            /* clean all other unused images added in session */
            await this.cleanImages({
              imageKeyList: Object.keys(IMAGE_TEMPLATE_KEYS.menu),
              type: IMAGE_ENTITY_TYPES.menu,
              entities: this.menus,
              age: IMAGE_AGES.new,
            });
          }
        } catch (err) {
          console.error('could not clean s3 images');
        }
      } catch (err) {
        if (Object.keys(this.newImageMap).length) {
          /* clean new unused images from s3 */
          await this.cleanImages({
            imageKeyList: Object.keys(IMAGE_TEMPLATE_KEYS.menu),
            age: IMAGE_AGES.new,
          });
        }
        console.error(err);
        this.$toast('Menu could not be published');
      }
      this.resetImageMaps();
      this.showChitEditTextBoxes = false;
      this.publishing = false;
      this.hideDisabledItems = false;
      if (this.unpublishedActiveMenuIndex !== null) {
        this.activemenu = this.menus[this.unpublishedActiveMenuIndex]?.id;
        this.unpublishedActiveMenuIndex = null;
      }
      await this.getMenus();
    },
    async processDisabledGlobalMenu(menus) {
      const disabledMenus = menus.filter((e) => e.is && e.is.disabled);
      if (disabledMenus.length === 0) return;

      const { menus: allMenus } = await this.fetchCompanyMenu({
        id: this.companyId,
        nocache: true,
      });

      const localMenus = allMenus.filter((menu) =>
        disabledMenus.find((d) => d.id === menu.parent_id),
      );
      const localMenuLocationBrands = [...new Set(localMenus.map((item) => item.location_brand))];
      const promises = localMenuLocationBrands.map((brand) =>
        this.processDisabledLocalMenu(brand, localMenus),
      );
      await Promise.all(promises);
    },
    async processDisabledLocalMenu(brand, localMenus) {
      // we need to get calendar info
      const { data } = await this.api.get(`/calendar/${brand}`);
      const { events } = data;
      if (Array.isArray(events) && events.length > 0) {
        // we need to get menu from calendar events, check if there is an active menu scheduled
        const events_to_update = await events
          .filter(
            (event) =>
              event.data.menu &&
              !event.data.is.disabled &&
              localMenus.find((d) => d.id === event.data.menu),
          )
          .map((event) => {
            // const localMenu = localMenus.filter(m => m.id === event.menu);
            // if (!localMenu || localMenu.length === 0) return false;
            // make scheduled menu inactive
            event.data.is.disabled = true;
            return event;
          });
        // save the events back in calendar
        if (events_to_update && events_to_update.length > 0) {
          await this.api.put(`/calendar/${brand}`, {
            events,
          });
          // refresh cache without await
          this.api.get(`/calendar/${brand}`, {
            params: {
              nocache: true,
            },
          });
        }
      }
    },
    async publishLocalMenu(modifiedMenu) {
      if (this.isPartialCategoriesActive)
        await this.patchMenu({ payload: { ...modifiedMenu }, _query: '{id}' });
      else await this.putMenu({ ...modifiedMenu });
      // refreshing cache for consumers, no need to await
      this.fetchMenu({ id: modifiedMenu.id, refreshCache: true, noLocalCache: true });
      return true;
    },
    async publishGlobalMenu(modifiedMenu) {
      if (this.isPartialCategoriesActive)
        await this.patchMenu({ payload: modifiedMenu, _query: '{id}' });
      else await this.putMenu(modifiedMenu);
      // refreshing cache for consumers, no need to await
      this.fetchMenu({ id: modifiedMenu.id, refreshCache: true, noLocalCache: true });
      return true;
    },

    async addMenu(payload) {
      if (payload.id) {
        delete payload.id;
      }
      const menu = await this.postMenu(payload);
      await this.fetchMenu({ id: menu.id, nocache: true });
      payload.id = menu.id;
      return true;
    },
    // Item calls
    deleteItem(item_id) {
      const index = this.activeCategoryData.items.findIndex((i) => i.id === item_id);
      this.activeCategoryData.items.splice(index, 1);
    },

    async copyItem(item_id) {
      const index = this.activeCategoryData.items.findIndex((i) => i.id === item_id);
      const item = this.activeCategoryData.items[index];
      if (this.isPartialItemsActive && this.isPartialCategoriesActive) {
        await this.copyOptions(item);
      }
      const { id, ...newItem } = cloneDeep(item);
      newItem.label.en += ' copy';
      if (!this.isVersion2 && Array.isArray(newItem.options)) {
        newItem.options.forEach((option) => {
          delete option.id;
          if (Array.isArray(option.items)) {
            option.items.forEach((option_item) => delete option_item.id);
          }
        });
      }
      newItem.id = generateCdlId('menu', 'cdl', 'item');
      this.activeCategoryData.items.push(newItem);
    },
    async copyOptions(item) {
      const items = await this.fetchMenuItemModGroups({
        menu_id: this.activemenu,
        group_id: this.activeCategoryId,
        item_id: item.id,
        nocache: true,
        show_unlinked: false,
      });
      item.options = items[0]?.options;
    },
    updateSorting(event) {
      if (!this.isLocalMenu) return; // global menus don't need menu_sort_number, since just modify json
      this.setMenuSortNumber(event.newIndex, this.activeCategoryData.items);
    },
    setMenuSortNumber(moved_record_index, records = []) {
      let last_record_with_sort_number_index = null;
      for (let i = records.length - 1; i >= 0; i -= 1) {
        const record = records[i];
        if (record && record.meta && record.meta.menu_sort_number) {
          last_record_with_sort_number_index = i;
          break;
        }
      }
      if (moved_record_index > last_record_with_sort_number_index) {
        last_record_with_sort_number_index = moved_record_index;
      }

      for (let i = 0; i <= last_record_with_sort_number_index; i += 1) {
        const record = records[i];
        this.$set(record, 'meta', { ...record.meta, menu_sort_number: i + 1 });
      }
    },
    dragReorder(event) {
      if (this.readOnlyMode) return;
      const rowSelected = this.activeCategoryData.items.splice(event.oldIndex, 1)[0]; // Get the selected row and remove it
      this.activeCategoryData.items.splice(event.newIndex, 0, rowSelected); // Move it to the new index
      this.updateSorting(event);
    },
    modifyGroupAction() {
      const all_items_hidden =
        !this.activeCategoryData.items ||
        this.activeCategoryData.items.every((i) => !i || !i.is || i.is.hidden);
      if (all_items_hidden) {
        this.$set(this.activeCategoryData.is, 'hidden', true);
        if (this.currentMenu.groups.every((g) => g.is && g.is.hidden)) {
          this.$set(this.currentMenu.is, 'hidden', true);
        }
      }
    },
    getParentMenuItem(item) {
      if (!this.parentMenuCategory || !this.parentMenuCategory.items) return undefined;
      return this.parentMenuCategory.items.find((i) => i.id === item.id);
    },
    async getShowItemImageTab(item) {
      let showImage;
      let refetchedCurrentMenu;
      if (Object.keys(item).length) {
        /* handle new menu set and item */
        if (!this?.currentMenu) {
          return true;
        }
        if (this.activemenu && !this.currentMenu?.is) {
          refetchedCurrentMenu = await this.fetchMenu({ id: this.activemenu, nocache: true });
        }
        showImage =
          !!this.currentMenu?.is?.item_images_enabled ||
          !!refetchedCurrentMenu?.is?.item_images_enabled;
      }
      if (this.isLocalMenu) {
        const parentItem = this.getParentMenuItem(item);
        showImage = !!(showImage && parentItem && parentItem.image && parentItem.image.src);
      }
      return showImage || false;
    },
    openEditCategoryNameDialog() {
      this.openCategoryDialog = true;
    },
    async kick() {
      if (!this.canKick) return;
      await this.edit({ unlock: true });
    },
    async edit({ unlock }) {
      this.locking = true;

      try {
        let menuHasStaleData = false;
        const { menus: latestCompanyMenus } = await this.fetchCompanyMenu({
          id: this.companyId,
          nocache: true,
        });
        // Due to multiple patterns on mutating `this.menus`
        // we need to update the store before continuing
        this.$store.commit('menus/setMenus', cloneDeep(this.menus));

        this.menus = await Promise.all(
          this.menus.map(async (m) => {
            if (!m.id) return m;
            const latestMenu = latestCompanyMenus.find((latest) => latest.id === m.id);
            menuHasStaleData =
              menuHasStaleData || latestMenu.date.published > (m.date && m.date.published);
            const noUnlockNeeded = !unlock && (!latestMenu.meta || !latestMenu.meta.locked_by_user);
            if (menuHasStaleData || noUnlockNeeded) return m;
            if (!unlock && this.isMenuLocked(m.id, this.user.id)) {
              menuHasStaleData = true; // someone locked it before user clicked Edit
              return m;
            }
            const lockedMenu = await this.lockMenu({
              id: m.id,
              meta: { locked_by_user: null },
            });
            return { ...lockedMenu, groups: m.groups };
          }),
        );
        if (menuHasStaleData) {
          await this.$confirm({
            title: "The menu you're trying to edit was modified after you've loaded this page.",
            message: 'The page will be refreshed to get latest data.',
            buttonTrueText: 'OK',
            buttonFalseText: null,
            width: 800,
          });
          this.cleanUp();
          this.$store.commit('adminPanel/setLoading', false);
          this.$router.go();
          return;
        }
        if (!unlock) {
          const oldestMenuOriginal = this.menusOriginal.reduce((prev, next) => {
            if (!prev || !prev.date || !prev.date.created) return next;
            if (!next || !next.date || !next.date.created) return prev;
            return next.date.created > prev.date.created ? prev : next;
          }, {});
          if (oldestMenuOriginal && oldestMenuOriginal.id) {
            // locking only one menu(oldest one) instead of all menus for performance reasons
            // if we ever go to per menu locking, should be changed to per menu
            const lockedOldestMenu = await this.lockMenu({
              id: oldestMenuOriginal.id,
              meta: { locked_by_user: this.user.id },
            });
            delete lockedOldestMenu.groups;
            const oldestMenu = this.menus.find((m) => m.id === lockedOldestMenu.id);
            if (oldestMenu) {
              mergeReactive(oldestMenu, lockedOldestMenu);
            }
            const mergedMenuOriginal = mergeReactive(
              cloneDeep(oldestMenuOriginal),
              lockedOldestMenu,
              false,
            );
            this.replaceInArrayById(mergedMenuOriginal, this.menusOriginal);
          }
        }

        this.menus.forEach((m) => {
          if (m.meta && m.meta.locked_by_user) {
            this.cacheUser({ id: this.user.id, user: this.user });
          }
          if (m.meta && m.meta.last_modified_user) {
            this.cacheUser({ id: this.user.id, user: this.user });
          }
        });
        if (this.menus.length) {
          this.menus.forEach((m) => {
            this.replaceInArrayById(cloneDeep(m), this.menusOriginal);
          });
          this.menusOriginal = this.menusOriginal.filter((m) => m.id);
        }
        this.renderSortable(!unlock);
        this.setReadOnlyMode(unlock);
        this.unFreezeActiveCategoryItems();
        this.sessionExpiry = DateTime.utc().plus({ minutes: this.sessionLength });
      } catch (err) {
        console.error(err);
        this.$toast('Menu could not be locked for editing');
      }

      this.locking = false;
    },
    unFreezeActiveCategoryItems() {
      if (!this.activeCategoryData || !Array.isArray(this.activeCategoryData.items)) return;
      this.$set(this.activeCategoryData, 'items', cloneDeep(this.activeCategoryData.items));
    },
    async unlockMenus(leaving = false) {
      if (!this.isLockedToUser) return;

      const prop = leaving || this.menus.length === 0 ? 'menusOriginal' : 'menus';
      await Promise.all(
        this[prop].map((m) => {
          if (!m.meta || !m.meta.locked_by_user) return false;
          return this.lockMenu({
            id: m.id,
            meta: { locked_by_user: null },
          });
        }),
      );

      this[prop].forEach((m) => {
        if (!m.id) return;
        const newMenu = cloneDeep(m);
        newMenu.meta = { ...m.meta, locked_by_user: null };
        this.replaceInArrayById(newMenu, this[prop], prop === 'menus');
      });

      this.renderSortable(false);
      this.setReadOnlyMode(true);
    },
    async getMenuUsers() {
      const menuUsersSet = new Set();
      this.menus.forEach((menu) => {
        if (menu.meta) {
          if (menu.meta.last_modified_user) menuUsersSet.add(menu.meta.last_modified_user);
          if (menu.meta.locked_by_user) menuUsersSet.add(menu.meta.locked_by_user);
        }
      });

      return Promise.all(
        Array.from(menuUsersSet).map((id) => this.fetchUser({ id, fields: ['id', 'name'] })),
      );
    },
    async showSessionExpiry() {
      if (this.sessionExpirySeen || !this.sessionExpiry) return;
      if (this.sessionExpiry.diffNow('minutes').toObject().minutes > 10) return;

      this.sessionExpirySeen = true;

      await this.$confirm({
        title: `Session timeout in 10 minutes (${this.sessionExpiry
          .toLocal()
          .toLocaleString(DateTime.TIME_SIMPLE)})`,
        message: 'Changes will be lost if you do not publish.',
        buttonTrueText: 'DISMISS',
        buttonFalseText: null,
        width: 800,
      });

      this.sessionExpiry = DateTime.utc().plus({ minutes: this.sessionLength });
      this.sessionExpirySeen = false;
    },
    async expireSession() {
      if (!this.sessionExpiry) return;

      const expired = this.sessionExpiry.diffNow('minutes').toObject().minutes <= 0;
      if (!expired) return;

      this.$store.commit('adminPanel/setLoading', true);
      this.setReadOnlyMode(true);
      await Promise.all(
        this.menus.map(async (m) => {
          await this.fetchMenu({ id: m.id, nocache: true, _query: '{meta}' });
          if (!this.isMenuLocked(m.id, this.user.id)) return;
          this.lockMenu({
            id: m.id,
            meta: { locked_by_user: null },
          });
        }),
      );

      this.cleanUp();
      this.$store.commit('adminPanel/setLoading', false);
      this.$router.go();
    },
    onActivity() {
      if (this.sessionExpiry === null || this.sessionExpirySeen) return;
      this.sessionExpiry = DateTime.utc().plus({ minutes: this.sessionLength });
    },
    cleanUp() {
      if (this.sortable && this.sortable.el) {
        this.sortable.destroy();
      }
      this.activemenu = null;
      this.activecategory = null;
      this.$el.removeEventListener('click', this.onActivity);
      clearInterval(this.sessionInterval);
    },
    toggleChitNumberEdits() {
      this.showChitEditTextBoxes = !this.showChitEditTextBoxes;
    },
    toggleSelectedItems() {
      const toggleValue = this.isAllItemsSelected;

      if (toggleValue) {
        this.selectedItems = {};
      } else {
        this.activeCategoryData.items.map(async (item) => {
          this.selectedItems[item.id] = item;
        });
      }
      // this will not lose references for items, but will trigger reactivity on selectedItems
      this.selectedItems = Object.assign({}, this.selectedItems);
    },
    async getMenu({ menu_id }) {
      const newMenu = await this.fetchMenu({ id: menu_id, nocache: true });
      const menuToUpdate = this.menus.find((m) => m.id === menu_id);
      const menuOriginal = this.menusOriginal.find((m) => m.id === menu_id);
      mergeReactive(menuToUpdate, newMenu);
      const newMenuOriginal = mergeReactive(cloneDeep(menuOriginal), cloneDeep(newMenu), false);
      this.replaceInArrayById(newMenuOriginal, this.menusOriginal);
    },
    checkItemDisabledForPlu(item) {
      if (!this.isSiteOperator || (this.isSiteOperator && !this.showPluField)) return false;
      const isEnabled =
        this.showPluField &&
        item.meta &&
        item.meta.plu !== undefined &&
        item.meta.plu !== null &&
        item.meta.plu.toString().length > 0;
      return !isEnabled;
    },
    async determineMEQVisibilty() {
      if (!this.isLocalMenu) return false;
      let showMEQField = (this.active_sector.is && this.active_sector.is.meq_eligible) || false;
      if (showMEQField) {
        showMEQField = false;
        // verify at least one tender type is MEAL_EQUIVALENT
        try {
          const publicConfig = await this.getConfigPublic({
            id: this.activeBrand.parentSite.id,
          });
          if (
            this.mealPlanConfigExists(publicConfig) &&
            publicConfig.mealplan[0].tenders &&
            publicConfig.mealplan[0].tenders.length
          ) {
            let meqTender = publicConfig.mealplan[0].tenders.find(
              (x) => x.type === TENDER_TYPES.MEAL_EQUIVALENT,
            );

            // ATRIUM has tender types defined in private config
            if (
              meqTender === undefined &&
              publicConfig.mealplan[0].type === MEALPLAN_TYPES.ATRIUM
            ) {
              const privateConfig = await this.getConfigPrivate({
                id: this.activeBrand.parentSite.id,
              });
              if (
                this.mealPlanConfigExists(privateConfig) &&
                privateConfig.mealplan[0].config &&
                privateConfig.mealplan[0].config.tenders &&
                privateConfig.mealplan[0].config.tenders.length
              ) {
                meqTender = privateConfig.mealplan[0].config.tenders.find(
                  (tender) => tender.type === TENDER_TYPES.MEAL_EQUIVALENT,
                );
              }
            }
            if (meqTender) showMEQField = true;
          }
        } catch (err) {
          console.error(err);
        }
      }
      return showMEQField;
    },
    async fetchSchedule() {
      if (!this.activeBrandId) return;
      const [localItems, localCategories] = await Promise.all([
        this.fetchLocalItemsSchedule(this.activeBrandId),
        this.fetchLocalCategorySchedule(this.activeBrandId),
      ]);
      this.localItemsSchedule = localItems;
      this.localCategoriesSchedule = localCategories;
    },
    async importMenuSet({ file, menuId }) {
      if (!file) return;

      this.importing = true;
      this.importErrors = [];

      this.$store.commit('adminPanel/setLoading', true);
      await this.setView({ menu: menuId, category: null });

      const reader = new FileReader();
      new Promise((resolve, reject) => {
        reader.readAsArrayBuffer(file);

        if (!file) reject();
        reader.onload = async () => {
          const buffer = reader.result;
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(buffer);

          // try parsing data from imported excel sheet
          const { parsedMenuSet, parseErrors } = await this.parseMenuSetExcel(workbook);
          if (parseErrors.length > 0) reject(parseErrors);

          // try get full mod group data for imported mod groups
          const { fullMenuSet, modGroupErrors } = await this.getImportedModGroupData(parsedMenuSet);
          if (modGroupErrors.length > 0) reject(modGroupErrors);

          // check for duplicate values
          let duplicateErrors = this.checkMenuSetDuplicates(fullMenuSet);
          if (duplicateErrors.length > 0) reject(duplicateErrors);

          // merge with existing menuset data
          // eslint-disable-next-line prefer-const
          let { mergedMenuSet, mergeErrors } = await this.mergeImportedMenuSet(menuId, fullMenuSet);
          if (mergeErrors.length > 0) reject(mergeErrors);

          // check for duplicate values after merge
          duplicateErrors = this.checkMenuSetDuplicates(mergedMenuSet);
          if (duplicateErrors.length > 0) reject(duplicateErrors);

          // verify menuset values
          const verificationErrors = await this.verifyMenuSet(mergedMenuSet);
          if (verificationErrors.length > 0) reject(verificationErrors);

          // remove excel sheet/row info
          mergedMenuSet = this.stripMenuSetExcelInfo(mergedMenuSet);

          resolve(mergedMenuSet);
        };
      })
        .then(async (mergedMenuSet) => {
          await this.edit({ unlock: false });
          this.$set(this.currentMenu, 'groups', mergedMenuSet);
          this.$store.commit('menus/setMenu', { id: menuId, menu: cloneDeep(this.currentMenu) });

          this.$toast.success(
            'Menu set successfully imported locally. Please verify before publishing.',
          );
        })
        .catch((errors) => {
          this.importErrors = errors;
          this.showImportErrorsModal = true;
        })
        .finally(() => {
          this.importing = false;
          this.$store.commit('adminPanel/setLoading', false);
        });
    },
    stripMenuSetExcelInfo(menuSet) {
      return menuSet.map((category) => {
        delete category.excelSheetName;
        delete category.excelSheetRow;
        category.items = category.items.map((item) => {
          delete item.excelSheetName;
          delete item.excelSheetRow;
          item.options = item.options.map((mod) => {
            delete mod.excelSheetName;
            delete mod.excelSheetRow;
            return mod;
          });
          return item;
        });
        return category;
      });
    },
    async getImportedModGroupData(menuSet) {
      const errors = [];
      const modGroupPromises = {};
      const fullMenuSet = await Promise.all(
        menuSet.map(async (category) => {
          return Promise.all(
            category.items.map(async (item) => {
              return Promise.all(
                item.options.map((mod) => {
                  if (this.companyGlobalModGroups) {
                    const matchingCompanyGlobalMod = this.companyGlobalModGroups.find(
                      ({ id }) => id === mod.id,
                    );

                    if (!matchingCompanyGlobalMod) {
                      errors.push(
                        createImportError(
                          mod.excelSheetName,
                          mod.excelSheetRow,
                          'Modifier group does not exist. Check that "Modifier Group ID" matches an existing modifier group.',
                        ),
                      );
                    } else if (
                      get(matchingCompanyGlobalMod, 'unique_name') !== get(mod, 'unique_name')
                    ) {
                      errors.push(
                        createImportError(
                          mod.excelSheetName,
                          mod.excelSheetRow,
                          '"Modifier Group Name" does not match with existing modifier group. Please verify correct modifier group ID and name.',
                        ),
                      );
                    } else {
                      if (!modGroupPromises[mod.id]) {
                        modGroupPromises[mod.id] = this.fetchGlobalModGroup({ id: mod.id });
                      }
                      return modGroupPromises[mod.id];
                    }
                  }
                  return mod;
                }),
              ).then((fullMods) => {
                item.options = fullMods;
                return item;
              });
            }),
          ).then((fullItems) => {
            category.items = fullItems;
            return category;
          });
        }),
      );

      return { fullMenuSet, modGroupErrors: errors };
    },
    async verifyMenuSet(menuSet) {
      const menuCategoryTests = [
        {
          test: (category) =>
            category.meta &&
            category.meta.sort_number !== undefined &&
            category.meta.sort_number <= 0,
          message: () => '"Category Sequence On Ticket" must be greater than 0.',
        },
      ];

      const menuItemTests = [
        {
          test: (item) =>
            item.meta && item.meta.sort_number !== undefined && item.meta.sort_number <= 0,
          message: () => '"Item Sequence On Ticket" must be greater than 0.',
        },
        {
          test: (item) =>
            item.price.amount !== undefined &&
            (item.price.amount < PRICE_RULE.min || item.price.amount > PRICE_RULE.max),
          message: () =>
            `Item "Price" must be a number from ${PRICE_RULE.min} to ${PRICE_RULE.max}.`,
        },
        {
          test: (item) =>
            item.nutrition &&
            item.nutrition.calories &&
            item.nutrition.calories.amount !== undefined &&
            item.nutrition.calories.amount < 0,
          message: () => 'Item "Calories" must be 0 or higher.',
        },
        {
          test: (item) => item.unit !== undefined && item.unit <= 0,
          message: () => 'Item "Units" must be greater than 0.',
        },
        {
          test: (item) =>
            item.meta &&
            item.meta.taxes &&
            item.meta.taxes.some((taxTag) => !this.taxOptions.includes(taxTag)),
          message: () =>
            `"Tag Tags" contains invalid entries. Valid tax tags are: "${this.taxOptions.join(
              '", "',
            )}"`,
        },
      ];

      return menuSet.reduce((errors, category) => {
        menuCategoryTests.forEach((test) => {
          if (test.test(category)) {
            errors.push(
              createImportError(
                category.excelSheetName,
                category.excelSheetRow,
                test.message(category),
                category.label && category.label.en,
              ),
            );
          }
        });

        if (!category.items) return errors;
        category.items.forEach((item) => {
          menuItemTests.forEach((test) => {
            if (test.test(item)) {
              errors.push(
                createImportError(
                  item.excelSheetName,
                  item.excelSheetRow,
                  test.message(item),
                  item.label && item.label.en,
                ),
              );
            }
          });
        });

        return errors;
      }, []);
    },
    async mergeImportedMenuSet(menuId, menuSet) {
      const mergeErrors = [];
      const originalMenu = cloneDeep(this.menus.find(({ id }) => id === menuId));

      const mergedMenuSet = await Promise.all(
        menuSet.map(async (importedGroup) => {
          const originalGroup =
            originalMenu.groups && originalMenu.groups.find(({ id }) => id === importedGroup.id);

          if (!originalGroup && importedGroup.id) {
            mergeErrors.push(
              createImportError(
                importedGroup.excelSheetName,
                importedGroup.excelSheetRow,
                'Imported category has "Category ID" set, but no matching category was found.',
                importedGroup.label && importedGroup.label.en,
              ),
            );
          } else if (originalGroup) {
            if (this.isPartialCategoriesActive && !originalGroup.items.length) {
              originalGroup.items = await this.loadMenuGroupItems(menuId, originalGroup.id);
            }
            const newAndModifiedItems = importedGroup.items.map((importedItem) => {
              const originalItem = originalGroup.items.find(({ id }) => id === importedItem.id);

              if (!originalItem && importedItem.id) {
                mergeErrors.push(
                  createImportError(
                    importedItem.excelSheetName,
                    importedItem.excelSheetRow,
                    'Imported item has "Item ID" set, but no matching item was found.',
                    importedItem.label && importedItem.label.en,
                  ),
                );
              } else if (originalItem) {
                return mergeWith({}, originalItem, importedItem, (objValue, srcValue, key) => {
                  if (key === 'options') {
                    // merge options object based on id
                    const merged = merge(keyBy(objValue, 'id'), keyBy(srcValue, 'id'));
                    return values(merged);
                  }

                  if (key === 'meta') {
                    const merged = merge({}, objValue, srcValue);
                    merged.taxes = union(get(objValue, 'taxes', []), get(srcValue, 'taxes', []));
                    return merged;
                  }

                  return undefined; // falls back to default _.merge behaviour
                });
              }
              if (!importedItem.id) importedItem.id = generateCdlId('menu', 'cdl', 'item');
              return importedItem;
            });

            const unmodifiedItems = originalGroup.items.filter((originalItem) => {
              return newAndModifiedItems.every(({ id }) => id !== originalItem.id);
            });

            const mergedGroup = merge({}, originalGroup, importedGroup);
            mergedGroup.items = [...newAndModifiedItems, ...unmodifiedItems];
            return mergedGroup;
          }
          if (!importedGroup.id) importedGroup.id = generateCdlId('menu', 'cdl', 'group');
          return importedGroup;
        }),
      );

      if (originalMenu) {
        // add unmerged/unmodifier groups back in
        originalMenu.groups.forEach((originalGroup) => {
          const merged = mergedMenuSet.find(({ id }) => id === originalGroup.id);
          if (!merged) mergedMenuSet.push(originalGroup);
        });
      }

      return { mergedMenuSet, mergeErrors };
    },
    checkMenuSetDuplicates(menuSet) {
      const duplicateErrors = findDuplicatesByField(menuSet, 'id').map((duplicate) =>
        createImportError(
          duplicate.excelSheetName || duplicate.duplicateSheet,
          duplicate.excelSheetRow || duplicate.duplicateRowNum,
          getDuplicateErrorMessage('Category', 'Category Id', duplicate),
          duplicate.unique_name || (duplicate.label && duplicate.label.en),
        ),
      );

      duplicateErrors.push(
        ...findDuplicatesByField(menuSet, 'label.en').map((duplicate) =>
          createImportError(
            duplicate.excelSheetName || duplicate.duplicateSheet,
            duplicate.excelSheetRow || duplicate.duplicateRowNum,
            getDuplicateErrorMessage('Category', 'Category Name', duplicate),
            duplicate.label && duplicate.label.en,
          ),
        ),
      );

      const menuItems = menuSet.flatMap(({ items }) => items);
      duplicateErrors.push(
        ...findDuplicatesByField(menuItems, 'id').map((duplicate) =>
          createImportError(
            duplicate.excelSheetName,
            duplicate.excelSheetRow,
            getDuplicateErrorMessage('Item', 'Item ID', duplicate),
            duplicate.label && duplicate.label.en,
          ),
        ),
      );

      menuItems.forEach((item) => {
        duplicateErrors.push(
          ...findDuplicatesByField(item.options, 'id').map((duplicate) =>
            createImportError(
              item.excelSheetName,
              item.excelSheetRow,
              `Item "${item.label && item.label.en}" contains duplicate modifier groups.`,
              duplicate.unique_name || (duplicate.label && duplicate.label.en),
            ),
          ),
        );

        duplicateErrors.push(
          ...findDuplicatesByField(get(item, 'meta.taxes', [])).map((duplicate) =>
            createImportError(
              item.excelSheetName,
              item.excelSheetRow,
              `Item "${item.label && item.label.en}" contains duplicate tax tags.`,
              duplicate,
            ),
          ),
        );
      });

      return duplicateErrors;
    },
    async parseMenuSetExcel(workbook) {
      const parseErrors = [];
      const menuSet = [];

      const requiredHeaders = ['Record Type'];
      requiredHeaders.push(...Object.keys(this.importMenuCategoryValues));
      requiredHeaders.push(...Object.keys(this.importMenuItemValues));
      requiredHeaders.push(...Object.keys(this.importMenuItemModValues));

      await this.loadCompanyGlobalModGroups(); // wait for global mods to load

      workbook.eachSheet((sheet) => {
        const headers = sheet.getRow(1); // first row is column headers

        const headerErrors = validateImportHeaders(requiredHeaders, headers, sheet.name);
        parseErrors.push(...headerErrors);
        if (headerErrors.length > 0) return; // don't continue if there are headers missing/invalid

        let currentMenuCategory = null;
        let currentMenuItem = null;

        sheet.eachRow({ includeEmpty: false }, (row, rowIndex) => {
          if (rowIndex === 1) return; // skip header row

          try {
            const recordType = tryGetRowValue('Record Type', headers, row);

            if (recordType === 'Category') {
              if (currentMenuCategory) {
                if (currentMenuItem) {
                  currentMenuCategory.items.push(currentMenuItem);
                  currentMenuItem = null;
                }

                menuSet.push(currentMenuCategory); // next menu category
                currentMenuCategory = null;
              }

              const { menuCategory, errors } = this.parseMenuCategoryRow(headers, sheet.name, row);
              parseErrors.push(...errors);

              menuCategory.excelSheetName = sheet.name;
              menuCategory.excelSheetRow = rowIndex;

              currentMenuCategory = menuCategory;
            } else if (recordType === 'Item') {
              if (currentMenuCategory && currentMenuItem) {
                currentMenuCategory.items.push(currentMenuItem); // next menu item
                currentMenuItem = null;
              }

              const { menuItem, errors } = this.parseMenuItemRow(headers, sheet.name, row);
              parseErrors.push(...errors);

              menuItem.excelSheetName = sheet.name;
              menuItem.excelSheetRow = rowIndex;

              currentMenuItem = menuItem;
            } else if (recordType === 'Modifier Group') {
              const { menuItemMod, errors } = this.parseMenuItemModRow(headers, sheet.name, row);
              parseErrors.push(...errors);

              menuItemMod.excelSheetName = sheet.name;
              menuItemMod.excelSheetRow = rowIndex;

              currentMenuItem.options.push(menuItemMod);
            } else {
              parseErrors.push(
                createImportError(
                  sheet.name,
                  rowIndex,
                  'Invalid "Record Type". Should be "Category", "Item", or "Modifier Group".',
                ),
              );
            }
          } catch (err) {
            console.error(err);
            parseErrors.push(
              createImportError(sheet.name, rowIndex, 'Error trying to read "Record Type" value.'),
            );
          }
        });

        if (currentMenuCategory) {
          if (currentMenuItem) currentMenuCategory.items.push(currentMenuItem);
          menuSet.push(currentMenuCategory);
        }
      });

      return { parsedMenuSet: menuSet, parseErrors };
    },
    parseMenuCategoryRow(headers, sheetName, row) {
      const { errors, parsedData } = parseRow(
        this.importMenuCategoryValues,
        headers,
        sheetName,
        row,
      );

      return {
        menuCategory: {
          id: parsedData['Category ID'],
          is: {
            disabled: !parsedData['Category Enabled'],
          },
          label: {
            en: parsedData['Category Name'],
          },
          meta: {
            sort_number: parsedData['Category Sequence On Ticket'],
          },
          items: [],
        },
        errors,
      };
    },
    parseMenuItemRow(headers, sheetName, row) {
      const { errors, parsedData } = parseRow(this.importMenuItemValues, headers, sheetName, row);

      const jsonFields = [
        { id: 'Tax Tags', example: '["Prepared", "Sweetened Beverage"]' },
        { id: 'Barcodes', example: '["abcd1234"]' },
      ];

      jsonFields.forEach((field) => {
        if (parsedData[field.id] !== undefined) {
          try {
            parsedData[field.id] = JSON.parse(parsedData[field.id]);

            if (!Array.isArray(parsedData[field.id])) {
              throw new Error(`"${field.id}" is not an array.`);
            } else if (!parsedData[field.id].every((value) => typeof value === 'string')) {
              throw new Error(`"${field.id}" is not an array of strings.`);
            }
          } catch (e) {
            errors.push(
              createImportError(
                sheetName,
                row._number,
                `Error parsing "${field.id}". Format should be a JSON array of strings. ex. ${field.example}`,
              ),
            );
          }
        }
      });

      return {
        menuItem: {
          description: {
            en: parsedData.Description,
          },
          id: parsedData['Item ID'],
          is: {
            disabled: !parsedData['Item Enabled'],
          },
          label: {
            en: parsedData['Item Name'],
          },
          meta: {
            taxes: parsedData['Tax Tags'],
            barcodes: parsedData.Barcodes,
            plu: parsedData.PLU,
            sort_number: parsedData['Item Sequence On Ticket'],
          },
          nutrition: {
            calories: {
              amount: parsedData.Calories,
            },
          },
          options: [],
          price: {
            amount: parsedData.Price,
          },
          unit: parsedData.Units,
        },
        errors,
      };
    },
    parseMenuItemModRow(headers, sheetName, row) {
      const { errors, parsedData } = parseRow(
        this.importMenuItemModValues,
        headers,
        sheetName,
        row,
      );

      return {
        menuItemMod: {
          id: parsedData['Modifier Group ID'],
          unique_name: parsedData['Modifier Group Name'],
        },
        errors,
      };
    },
    cancelEditing() {
      this.saveNewItem = null;
      const activeItemBackup = this.menusOriginal
        .find((menu) => menu.id === this.activemenu)
        ?.groups?.[this.activecategory]?.items.find((item) => item.id === this.activeitem.id);
      if (activeItemBackup && (!activeItemBackup.image || !activeItemBackup.image.src)) {
        const activeItem = this.menus
          .find((menu) => menu.id === this.activemenu)
          ?.groups?.[this.activecategory]?.items.find((item) => item.id === this.activeitem.id);
        if (!this.readOnlyMode && activeItem && isEqual(activeItem.image, IMAGE_TEMPLATE.menu)) {
          if (!activeItemBackup.image) {
            delete activeItem.image;
          } else {
            activeItem.image = cloneDeep(activeItemBackup.image);
          }
        }
      }
      this.activeItem = null;
      this.showItemConfig = false;
    },
    getItemCopy(itemToCopy) {
      deepFreeze(itemToCopy.options);
      return itemToCopy;
    },
  },
  async mounted() {
    if (Object.keys(this.newImageMap).length) {
      /* if this object is populated in localStorage on mount, it indicates that the page was refreshed before publishing, and we need to clean new unused images from s3 */
      await this.cleanImages({
        imageKeyList: Object.keys(IMAGE_TEMPLATE_KEYS.menu),
        age: IMAGE_AGES.new,
      });
      this.resetImageMaps();
    }
    this.$store.commit('adminPanel/setLoading', true);
    await this.getMenus();
    await Promise.all([
      this.getMenuUsers(),
      // If the user refreshed while editing, let's unlock to menu just to be safe
      this.unlockMenus(),
    ]);

    if (this.menusOriginal.length === 0 && !this.isFirstMenu) this.setReadOnlyMode(false);
    this.$el.addEventListener('click', this.onActivity);
    this.sessionInterval = setInterval(async () => {
      this.showSessionExpiry();
      await this.expireSession();
    }, 1000);
    if (this.active_sector) {
      this.$store.commit('adminPanel/setLoading', false);
    }
  },
  beforeDestroy() {
    this.cleanUp();
  },
  watch: {
    active_sector: {
      handler(newValue, oldValue) {
        if (!newValue || newValue.id === (oldValue && oldValue.id)) return;
        const activeCompany = this.active_sector.companies.find((c) => c.id === this.companyId);
        this.$store.commit('sectors/setActiveCompany', activeCompany);
      },
      immediate: true,
    },
  },
};
</script>

<style>
.menutable .v-datatable {
  background: transparent !important;
}
.menutable .v-datatable__actions {
  background: transparent !important;
}
</style>
<style scoped>
div >>> .edit-btn {
  margin-top: 0px;
  margin-left: 7px;
}
div >>> .v-btn.edit-btn .v-btn__content .v-icon {
  font-size: 20px;
  color: #6b6868;
}
div >>> .secondary-text {
  color: #6b6868;
  margin-top: 5px;
  font-size: 13px;
}
div >>> .category-heading {
  font-size: 1.5rem;
}
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
