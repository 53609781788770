<template>
  <div @keydown.esc="vcoHandler">
    <v-navigation-drawer
      absolute
      temporary
      right
      :value="open"
      v-if="open"
      app
      stateless
      id="bulkupdatepaneldrawer"
      v-bind:style="{ height: 'calc(100vh - 64px)', top: `${pageYOffset}px` }"
    >
      <v-form ref="form" :class="configClass">
        <v-layout>
          <v-flex v-if="showModGroupConfig && showItemCards" xs6 class="cardStyle">
            <v-container grid-list-xl class="pb-0 pr-0" fluid>
              <v-layout row wrap>
                <v-flex xs12>
                  <div>
                    <pagination
                      :items="itemsforPreview"
                      @changePage="onChangePage"
                      :pageSize="5"
                    ></pagination>
                  </div>
                </v-flex>
                <v-flex xs3>
                  <template>
                    <item-card
                      v-for="item in pageOfItems"
                      :key="item.id"
                      :currentItem.sync="item"
                    />
                  </template>
                </v-flex>
              </v-layout> </v-container
          ></v-flex>
          <v-flex shrink style="max-width: 800px">
            <v-container grid-list-xl class="pb-0" fluid v-if="showItemConfigOnly">
              <v-layout row wrap>
                <v-flex xs12>
                  <div class="H5-Primary-Left">{{ updateMessage }}</div>
                </v-flex>
                <v-flex xs6>
                  <v-card height="200">
                    <v-card-title primary-title>
                      <div class="H6-Primary-Left">Price</div>
                    </v-card-title>

                    <div class="v-card-content">
                      <v-layout>
                        <v-flex>
                          <div>Update price for the selected items.</div>
                          <v-currency-field
                            v-if="open"
                            :rules="priceRules"
                            placeholder="Price ($)"
                            v-model="bulkUpdateConfig.price"
                          />
                        </v-flex>
                      </v-layout></div
                  ></v-card>
                </v-flex>
                <v-flex xs6 v-if="isLocal">
                  <v-card height="200">
                    <v-card-title primary-title>
                      <div class="H6-Primary-Left">Stock</div>
                    </v-card-title>

                    <div class="v-card-content">
                      <v-layout>
                        <v-flex>
                          <div>Set stock of selected items.</div>
                          <v-radio-group v-model="bulkUpdateConfig.stock" row>
                            <v-radio label="In Stock" v-bind:value="true"></v-radio>
                            <v-radio label="Out of Stock" v-bind:value="false"></v-radio>
                          </v-radio-group>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card>
                </v-flex>
                <v-flex xs6>
                  <v-card height="200">
                    <v-card-title primary-title>
                      <div v-if="!isLocal" class="H6-Primary-Left">
                        Enable/Disable in Local Menu
                      </div>
                      <div v-else class="H6-Primary-Left">In-App Visibilty</div>
                    </v-card-title>
                    <div class="v-card-content">
                      <v-layout>
                        <v-flex>
                          <div v-if="!isLocal">
                            Set the selected items be Enabled/Disabled in local menu?
                          </div>
                          <div v-else>Will the following items be visible in-app?</div>
                          <v-radio-group v-model="bulkUpdateConfig.appVisibility">
                            <v-radio :label="getLabelforItemEnabled" v-bind:value="true"></v-radio>
                            <v-radio
                              :label="getLabelforItemDisabled"
                              v-bind:value="false"
                            ></v-radio>
                          </v-radio-group>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card>
                </v-flex>

                <v-flex xs12 v-if="!isLocal">
                  <v-card height="200">
                    <v-card-title primary-title>
                      <div class="H6-Primary-Left">Tax Tags</div>
                    </v-card-title>
                    <div class="v-card-content">
                      <v-layout>
                        <v-flex>
                          <div>Apply tax tags to {# of} items</div>
                          <v-select
                            :items="taxoptions"
                            placeholder="Tax Tags"
                            multiple
                            clearable
                            chips
                            v-model="bulkUpdateConfig.taxTags"
                          ></v-select>
                        </v-flex>
                      </v-layout></div></v-card
                ></v-flex>
                <v-flex xs12 v-if="isVersion2">
                  <v-card>
                    <v-card-title primary-title>
                      <div class="H6-Primary-Left">Edit Mod Groups in Your Item Selection</div>
                    </v-card-title>
                    <div class="v-card-content">
                      <loading-wrapper :loading="isLoadingModifierGroups">
                        <v-layout>
                          <v-flex v-if="existingModGroups.length === 0"
                            ><h2>No Mods Available in your selected items</h2></v-flex
                          >
                          <v-flex v-if="existingModGroups.length > 0">
                            All edits below will be applied only to items where the modifier group
                            exist.
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex>
                            <modifier-group
                              v-for="(modGroup, index) in existingModGroups"
                              :key="modGroup.id + index"
                              v-bind:group="modGroup"
                              v-on:update:group="existingModGroups.splice(index, 1, $event)"
                              :isAdd="false"
                              @modifierGroup:delete="deleteModGroup(index, existingModGroups)"
                              @modifierGroup:edit="editModGroup(index, existingModGroups)"
                              @validateform="$refs.form.validate()"
                            >
                              <template v-slot:unique_name>
                                <v-text-field
                                  label="Modifier Group:"
                                  :placeholder="modGroup.unique_name"
                                  :disabled="true"
                                ></v-text-field>
                              </template>
                            </modifier-group>
                          </v-flex>
                        </v-layout>
                      </loading-wrapper>
                    </div>
                  </v-card>
                </v-flex>
                <v-flex xs12 v-if="isVersion2 && !isLocal">
                  <v-card>
                    <v-card-title primary-title>
                      <div class="H6-Primary-Left">Add New Mod Groups to Your Items</div>
                    </v-card-title>
                    <div class="v-card-content">
                      <v-layout>
                        <v-flex>
                          Add modifier groups to the selected items
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex>
                          <modifier-group
                            v-for="(modGroup, index) in addedModGroups"
                            :key="modGroup.id + index"
                            v-bind:group="modGroup"
                            v-on:update:group="addedModGroups.splice(index, 1, $event)"
                            @modifierGroup:delete="deleteModGroup(index, addedModGroups)"
                            @modifierGroup:edit="editModGroup(index, addedModGroups)"
                            @validateform="$refs.form.validate()"
                          >
                            <template v-slot:unique_name>
                              <v-select
                                label="Modifier Group:"
                                @click.native.stop
                                :disabled="readOnlyMode"
                                ref="uniqueName"
                                :items="modGroupsAvailableToAdd"
                                item-text="unique_name"
                                item-value="id"
                                @change="selectGlobalModForAdd(index, $event)"
                                :placeholder="modGroup.unique_name"
                              ></v-select>
                            </template>
                          </modifier-group>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="!isLocal">
                        <v-flex shrink>
                          <v-btn
                            class="ml-0 pl-0"
                            flat
                            color="primary"
                            @click="addModifierGroup"
                            :disabled="modGroupsAvailableToAdd.length === 0"
                          >
                            <v-icon>mdi-plus</v-icon>Add MODIFIER GROUP
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
            <v-container grid-list-xl class="pb-0 pl-0" fluid v-if="showModGroupConfig">
              <v-layout row wrap justify-end>
                <v-flex>
                  <modifier-group-details
                    v-bind:group="modifierGroupToEdit"
                    v-on:update:group="updateEditedModGroup"
                    :isCard="false"
                    @validateform="$refs.form.validate()"
                /></v-flex>
                <v-flex shrink xs8>
                  <v-btn
                    outline
                    flat
                    @click="showHideItemCards"
                    class="clickable"
                    color="primary"
                    >{{ showHideItemCardButtonLabel }}</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-container></v-flex
          >
        </v-layout>
      </v-form>
      <v-layout row justify-center>
        <v-flex shrink style="position: sticky; bottom: 0;" v-if="showItemConfigOnly">
          <v-btn
            color="primary"
            :loading="saving"
            @click="applyUpdate"
            :disabled="!isBulkUpdateFormModified"
            >Apply Changes</v-btn
          >
          <v-btn flat @click="cancel" class="clickable">Cancel</v-btn>
        </v-flex>
        <v-flex shrink style="position: sticky; bottom: 0;" v-if="showModGroupConfig">
          <v-btn
            color="primary"
            :loading="saving"
            @click="preSaveModifierChanges"
            :disabled="!modifierModified"
            >Save Modifiers Changes</v-btn
          >
          <v-btn flat @click="cancelModifier" class="clickable">Cancel</v-btn>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { syncModGroupChanges } from '@/helpers';
import { mapState, mapGetters, mapActions } from 'vuex';
import rules from '@/rules';
import pagination from '@/components/pagination';
import { PRICE_RULE } from '@/constants';
import modifierGroup from './modifierGroup';
import modifierGroupDetails from './modGroupDetails';
import itemCard from './itemCard';

export default {
  name: 'bulkUpdateConfig',
  props: {
    updateMessage: String,
    menu: String,
    group: String,
    selectedItems: Object,
  },
  components: {
    modifierGroup,
    modifierGroupDetails,
    itemCard,
    pagination,
  },
  data() {
    return {
      version: {
        version1: 1,
        version2: 2,
      },
      showItemConfigOnly: true,
      showItemCards: false,
      valid: false,
      open: false,
      required: [rules.required('Required')],
      priceRules: [rules.min(PRICE_RULE.min), rules.max(PRICE_RULE.max)],
      taxoptions: [
        'Alcohol',
        'Baked Goods',
        'Candy',
        'Carbonated Beverage',
        'Tax Exempt',
        'Pop',
        'Prepared',
        'Sweetened Beverage',
        'Unprepared',
        'Water',
        'Meal',
        'Good',
      ],
      saving: false,
      buldUpdateDefaultConfig: {
        price: null,
        stock: undefined,
        appVisibility: undefined,
        plu: undefined,
        taxTags: undefined,
      },
      bulkUpdateConfig: {},
      itemsforPreview: [],
      modifierGroupToEdit: {},
      modifierGroupToEditBackup: {},
      selectedItemsCopy: {},
      downloadedModGroups: {},
      existingModGroups: [],
      addedModGroups: [],
      pageOfItems: [],
      isLoadingModifierGroups: false,
    };
  },
  computed: {
    ...mapGetters('adminPanel', ['isSiteOperator', 'isAdmin']),
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('menus', [
      'isLocal',
      'showPluField',
      'user',
      'menuVersion',
      'companyGlobalModGroups',
      'pageYOffset',
      'readOnlyMode',
    ]),
    ...mapGetters('splitio', ['getFeatureFlags']),
    isPartialCategoriesActive() {
      return this.getFeatureFlags['ap3-menu-partial-categories'];
    },
    isPartialItemsActive() {
      return this.getFeatureFlags['ap3-menu-partial-items'];
    },
    isBulkUpdateFormModified() {
      return (
        !isEqual(this.bulkUpdateConfig, this.buldUpdateDefaultConfig) ||
        !isEqual(this.selectedItems, this.selectedItemsCopy)
      );
    },
    modifierModified() {
      return (
        !isEqual(this.modifierGroupToEdit, this.modifierGroupToEditBackup) &&
        this.$refs.form.validate()
      );
    },
    configClass() {
      return { saveable: true };
    },
    showPLU() {
      if (!this.isLocal) return true;
      if (this.isLocal && this.isAdmin && this.showPluField) return true;
      return false;
    },
    getLabelforItemEnabled() {
      return this.isLocal ? 'Visible In App' : 'Enable in Local Menu';
    },
    getLabelforItemDisabled() {
      return this.isLocal ? 'Hide In App' : 'Disable in Local Menu';
    },
    isVersion2() {
      return this.menuVersion === this.version.version2;
    },
    showModGroupConfig() {
      return !this.showItemConfigOnly;
    },
    selectedItemsArray() {
      return Object.values(this.selectedItemsCopy);
    },
    modGroupsAvailableToAdd() {
      const availableModGroupsToAdd = [];
      this.companyGlobalModGroups.forEach((globalModGroup) => {
        const modifierIdsJoined =
          (globalModGroup.items &&
            globalModGroup.items
              .map((i) => i.id)
              .sort()
              .join('~')) ||
          '';
        const uniqueId = `${globalModGroup.id}/${modifierIdsJoined}`;
        const modGroupToAdd = {
          ...cloneDeep(globalModGroup),
          uniqueId,
          parent_items: [],
        };
        const allItemsThatAlreadyHaveModGroup = this.existingModGroups.map((mg) => {
          if (mg.id !== globalModGroup.id) return [];
          return mg.parent_items.map((i) => i.id);
        });
        const itemsInAddedModGroups = this.addedModGroups.map((mg) => {
          if (mg.id !== globalModGroup.id) return [];
          return mg.parent_items.map((i) => i.id);
        });
        const itemIdsToIgnore = [].concat(
          ...allItemsThatAlreadyHaveModGroup,
          ...itemsInAddedModGroups,
        );
        modGroupToAdd.parent_items = this.selectedItemsArray.filter(
          (item) => !itemIdsToIgnore.includes(item.id),
        );
        if (!modGroupToAdd.parent_items.length) return;
        availableModGroupsToAdd.push(modGroupToAdd);
      });
      return availableModGroupsToAdd.sort((a, b) => (a.unique_name < b.unique_name ? -1 : 1));
    },
    showHideItemCardButtonLabel() {
      if (this.showItemCards) return 'Close Selection';
      return 'Show Selection';
    },
  },
  mounted() {
    document.addEventListener('keyup', this.handleEscape);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEscape);
  },
  methods: {
    ...mapActions('menus', ['getMenuItems', 'fetchGlobalModGroup', 'fetchMenuItemModGroups']),
    async vcoHandler() {
      if (this.open) {
        let shouldLeave = true;
        if (this.isBulkUpdateFormModified) {
          shouldLeave = await this.$confirm({
            title: 'You have unsaved changes',
            message: 'Are you sure you want to leave?',
            buttonTrueText: 'Yes',
            buttonFalseText: 'Cancel',
          });
        }
        if (shouldLeave) {
          this.resetSelectedItems();
          this.open = false;
          this.reset();
        }
      }
    },
    handleEscape(e) {
      if (e.keyCode === 27) {
        this.vcoHandler();
      }
    },
    applyUpdate() {
      this.saving = true;
      if (this.$refs.form.validate()) {
        this.selectedItemUpdate();
        this.resetSelectedItems();
        this.$toast('Selected items have been updated.');
        this.reset();
        this.open = false;
      } else {
        this.$toast.error('Please correct the errors.');
      }
      this.saving = false;
    },
    cancel() {
      this.bulkUpdateConfig = cloneDeep(this.buldUpdateDefaultConfig);
      this.open = false;
      this.resetSelectedItems();
      this.$emit('cancelEditing');
      this.reset();
    },
    async openConfig() {
      await this.$nextTick();
      this.bulkUpdateConfig = cloneDeep(this.buldUpdateDefaultConfig);
      if (this.isVersion2) {
        this.selectedItemsCopy = cloneDeep(this.selectedItems);
        this.loadModGroups();
      }
      this.open = true;
      await new Promise((resolve) => setTimeout(resolve, 2));
      this.$refs.form.validate();
    },
    async loadModGroups() {
      this.isLoadingModifierGroups = true;
      const existingModGroups = {};
      const item_ids_with_missing_mod_groups = this.selectedItemsArray
        .filter((item) => !item.options || !item.options.length)
        .map((item) => item.id);
      const loaded_items =
        item_ids_with_missing_mod_groups.length &&
        this.isPartialCategoriesActive &&
        this.isPartialItemsActive
          ? await this.fetchMenuItemModGroups({
              menu_id: this.menu,
              group_id: this.group,
              item_id: item_ids_with_missing_mod_groups,
            })
          : [];

      this.selectedItemsArray.forEach((item) => {
        const options = loaded_items.find((_item) => _item.id === item.id)?.options;
        if (options) {
          item.options = cloneDeep(options);
          this.selectedItems[item.id].options = cloneDeep(options);
        }
        if (!item.options) return;
        item.options.forEach((modGroup) => {
          const modifierIdsJoined =
            (modGroup.items &&
              modGroup.items
                .map((i) => i.id)
                .sort()
                .join('~')) ||
            '';
          const uniqueId = `${modGroup.id}/${modifierIdsJoined}`;

          let existingModGroup = existingModGroups[uniqueId];
          if (!existingModGroup) {
            const nullifiedModGroup = cloneDeep(item.options.find((mg) => mg.id === modGroup.id));
            nullifiedModGroup.is = {};
            nullifiedModGroup.meta = {};
            existingModGroup = {
              ...nullifiedModGroup,
              parent_items: [],
            };
          }
          existingModGroup.parent_items.push(item);
          if (!existingModGroups[uniqueId]) {
            existingModGroups[uniqueId] = existingModGroup;
          }
        });
      });
      this.existingModGroups = Object.values(existingModGroups);
      this.isLoadingModifierGroups = false;
    },
    async getGlobalModGroupDetails(id) {
      if (!this.downloadedModGroups[id]) {
        this.downloadedModGroups[id] = await this.fetchGlobalModGroup({ id }).catch((err) => {
          console.error(err);
          this.$toast.error(
            'Could not fetch modifier group info, please try selecting items again',
          );
        });
      }
      return cloneDeep(this.downloadedModGroups[id]);
    },
    selectedItemUpdate() {
      Object.values(this.selectedItems).forEach((item) => {
        const modifiedItem = this.selectedItemsCopy[item.id];
        if (modifiedItem && !isEqual(item.options, modifiedItem.options)) {
          this.$set(item, 'options', modifiedItem.options);
        }
        if (this.bulkUpdateConfig.price !== null)
          this.$set(item.price, 'amount', this.bulkUpdateConfig.price);
        if (this.bulkUpdateConfig.stock !== undefined && this.isLocal)
          if (!item.is.out_of_stock) {
            this.$set(item, 'is', { ...item.is, out_of_stock: !this.bulkUpdateConfig.stock });
          } else {
            this.$set(item.is, 'out_of_stock', !this.bulkUpdateConfig.stock);
          }
        if (this.bulkUpdateConfig.appVisibility !== undefined) {
          if (this.isLocal) {
            this.$set(item.is, 'hidden', !this.bulkUpdateConfig.appVisibility);
          } else {
            this.$set(item.is, 'disabled', !this.bulkUpdateConfig.appVisibility);
          }
        }
        if (this.bulkUpdateConfig.plu !== undefined) {
          this.$set(item.meta, 'plu', null);
        }
        if (this.bulkUpdateConfig.taxTags !== undefined && !this.isLocal) {
          this.$set(item.meta, 'taxes', this.bulkUpdateConfig.taxTags);
        }
      });
    },
    deleteModGroup(index, modGroups) {
      const modGroup = modGroups[index];
      if (modGroup.parent_items) {
        modGroup.parent_items.forEach((item) => {
          item.options = cloneDeep(item.options);
          item.options.splice(
            item.options.findIndex((mg) => mg.id === modGroup.id),
            1,
          );
        });
      }
      modGroups.splice(index, 1);
    },
    async editModGroup(index, modGroups) {
      const modGroup = modGroups[index];
      if (!modGroup.items) {
        const globalModGroup = await this.getGlobalModGroupDetails(modGroup.id);
        Object.assign(modGroup, globalModGroup);
      }
      let globalModGroup = this.companyGlobalModGroups.find((mg) => mg.id === modGroup.id);
      if (!globalModGroup.items) {
        globalModGroup = await this.getGlobalModGroupDetails(modGroup.id);
      }
      const nullifiedModGroup = cloneDeep(
        modGroup.parent_items[0].options.find((mg) => mg.id === modGroup.id),
      );
      nullifiedModGroup.is = {};
      nullifiedModGroup.meta = {};
      if (this.isLocal) {
        nullifiedModGroup.min = undefined;
        nullifiedModGroup.max = undefined;
      }
      if (!nullifiedModGroup.items) {
        nullifiedModGroup.items = [];
      }
      nullifiedModGroup.items.forEach((modifier) => {
        modifier.is = { disabled: modifier.is && modifier.is.disabled };
        modifier.price = {};
        modifier.meta = {};
        modifier.nutrition = { calories: {} };
      });
      nullifiedModGroup.globalModGroup = globalModGroup;
      this.modifierGroupToEdit = cloneDeep(nullifiedModGroup);
      this.modifierGroupToEditBackup = cloneDeep(nullifiedModGroup);
      // passing by reference to be able to update during preSaveModifierChanges
      this.modifierGroupToEdit.parent_items = modGroup.parent_items;
      this.modifierGroupToEditBackup.parent_items = modGroup.parent_items;
      this.itemsforPreview = cloneDeep(modGroup.parent_items);
      this.itemsforPreview.forEach((element) => {
        element.options = [this.modifierGroupToEdit];
      });

      this.showItemConfigOnly = false;
    },
    addModifierGroup() {
      this.addedModGroups.push({
        label: { en: '' },
        unique_name: '',
        is: {
          disabled: false,
        },
        items: [],
        meta: {},
        min: null,
        max: null,
      });
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    preSaveModifierChanges() {
      this.modifierGroupToEdit.parent_items.forEach((menu_item) => {
        let modGroupToSave = cloneDeep({
          ...this.modifierGroupToEdit,
          parent_items: undefined,
          globalModGroup: undefined,
        });
        if (!menu_item.options) {
          this.$set(menu_item, 'options', []);
        }
        const existingIndex = menu_item.options.findIndex((mg) => mg.id === modGroupToSave.id);
        if (existingIndex === -1) {
          menu_item.options.push(modGroupToSave);
        } else {
          modGroupToSave = syncModGroupChanges(
            modGroupToSave,
            cloneDeep(menu_item.options[existingIndex]),
          );
          menu_item.options.splice(existingIndex, 1, modGroupToSave);
        }
      });
      this.showItemConfigOnly = true;
    },
    cancelModifier() {
      this.showItemConfigOnly = true;
    },
    resetSelectedItems() {
      this.$emit('update:selectedItems', {});
    },
    async selectGlobalModForAdd(index, id) {
      const globalModGroup = await this.getGlobalModGroupDetails(id);
      const selectedModGroup = Object.assign(
        this.modGroupsAvailableToAdd.find((v) => v.id === id),
        globalModGroup,
      );
      const previousModGroup = this.addedModGroups[index];
      selectedModGroup.parent_items.forEach((menu_item) => {
        const modGroupToSave = cloneDeep({
          ...selectedModGroup,
          parent_items: undefined,
          globalModGroup: undefined,
        });
        if (!menu_item.options) {
          this.$set(menu_item, 'options', []);
        }
        const existingIndex = menu_item.options.findIndex((mg) => mg.id === previousModGroup.id);
        if (existingIndex === -1) {
          menu_item.options.push(modGroupToSave);
        } else {
          menu_item.options.splice(existingIndex, 1, modGroupToSave);
        }
      });
      this.$set(this.addedModGroups, index, selectedModGroup);
    },

    updateEditedModGroup(newModGroup) {
      Object.keys(this.modifierGroupToEdit).forEach((key) => {
        if (['globalMoodGroup', 'parent_items', 'unique_name'].includes(key)) return;
        if (isEqual(this.modifierGroupToEdit[key], newModGroup[key])) return;
        this.$set(this.modifierGroupToEdit, key, newModGroup[key]);
      });
    },
    showHideItemCards() {
      this.showItemCards = !this.showItemCards;
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
  },
};
</script>

<style scoped>
.saveable {
  overflow: scroll;
  height: calc(100% - 48px);
}
.flex.sort_number {
  max-width: 3.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}
div >>> .overlay-pointer {
  cursor: pointer;
}
div >>> .overlay-pointer * {
  pointer-events: none;
}
div >>> #bulkupdatepaneldrawer {
  width: auto !important;
}
.cardStyle {
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 750px;
  height: calc(90vh - 64px);
}
div >>> .success--text {
  color: #ff9843 !important;
  caret-color: #ff9843 !important;
}
div >>> .success--text .secondary--text {
  color: #ff9843 !important;
  caret-color: #ff9843 !important;
}
div >>> .success--text.theme--light.v-input--selection-controls.v-input--is-disabled .v-icon {
  color: #ff9843 !important;
  caret-color: #ff9843 !important;
}
div >>> .black-color .v-icon.mdi.mdi-eye.theme--light,
div >>> .black-color .v-icon.mdi.mdi-checkbox-marked.theme--light {
  color: rgba(0, 0, 0, 1) !important;
}
</style>
