var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pager.pages && _vm.pager.pages.length
    ? _c(
        "ul",
        { staticClass: "pagination", style: _vm.ulStyles },
        [
          _c(
            "li",
            {
              staticClass: "page-item first",
              class: { disabled: _vm.pager.currentPage === 1 },
              style: _vm.liStyles
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  style: _vm.aStyles,
                  on: {
                    click: function($event) {
                      return _vm.setPage(1)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.labels.first))]
              )
            ]
          ),
          _c(
            "li",
            {
              staticClass: "page-item previous",
              class: { disabled: _vm.pager.currentPage === 1 },
              style: _vm.liStyles
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  style: _vm.aStyles,
                  on: {
                    click: function($event) {
                      return _vm.setPage(_vm.pager.currentPage - 1)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.labels.previous))]
              )
            ]
          ),
          _vm._l(_vm.pager.pages, function(page) {
            return _c(
              "li",
              {
                key: page,
                staticClass: "page-item page-number",
                class: { active: _vm.pager.currentPage === page },
                style: _vm.liStyles
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    style: _vm.aStyles,
                    on: {
                      click: function($event) {
                        return _vm.setPage(page)
                      }
                    }
                  },
                  [_vm._v(_vm._s(page))]
                )
              ]
            )
          }),
          _c(
            "li",
            {
              staticClass: "page-item next",
              class: {
                disabled: _vm.pager.currentPage === _vm.pager.totalPages
              },
              style: _vm.liStyles
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  style: _vm.aStyles,
                  on: {
                    click: function($event) {
                      return _vm.setPage(_vm.pager.currentPage + 1)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.labels.next))]
              )
            ]
          ),
          _c(
            "li",
            {
              staticClass: "page-item last",
              class: {
                disabled: _vm.pager.currentPage === _vm.pager.totalPages
              },
              style: _vm.liStyles
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  style: _vm.aStyles,
                  on: {
                    click: function($event) {
                      return _vm.setPage(_vm.pager.totalPages)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.labels.last))]
              )
            ]
          ),
          _c(
            "li",
            {
              staticClass: "page-item all",
              class: { active: _vm.pager.currentPage === 0 },
              style: _vm.liStyles
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  style: _vm.aStyles,
                  on: {
                    click: function($event) {
                      return _vm.setPage(0)
                    }
                  }
                },
                [_vm._v("All")]
              )
            ]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }