import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-nav" },
    [
      _c(
        VLayout,
        { attrs: { column: "", "fill-height": "" } },
        [
          !_vm.isSiteOperator && !_vm.isImUser
            ? _c(
                VFlex,
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "nav-btn",
                      attrs: {
                        block: "",
                        flat: "",
                        disabled: _vm.loading,
                        loading: _vm.loading
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.goToBrand()
                        }
                      }
                    },
                    [
                      _c(VIcon, { attrs: { left: "" } }, [
                        _vm._v("mdi-chevron-left")
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.isLocal && _vm.active_company
                              ? _vm.active_company.name + " (local brands)"
                              : _vm.sectorName
                          ) +
                          "\n      "
                      )
                    ],
                    1
                  ),
                  _c(VDivider)
                ],
                1
              )
            : _c(
                VFlex,
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "nav-btn",
                      attrs: {
                        block: "",
                        flat: "",
                        disabled: _vm.loading,
                        loading: _vm.loading
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [
                      _c(VIcon, { attrs: { left: "" } }, [
                        _vm._v("mdi-chevron-left")
                      ]),
                      _vm._v(" Back\n      ")
                    ],
                    1
                  ),
                  _c(VDivider)
                ],
                1
              ),
          _c(
            VFlex,
            {
              staticClass: "list-item-style",
              staticStyle: { "margin-top": "40px" },
              attrs: { shrink: "" }
            },
            [
              _c(
                "div",
                { staticClass: "Subtitle-2-Black-Medium-Emphasis-Left" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.title) +
                      " " +
                      _vm._s(_vm.isLocalMenu ? "(Local)" : "(Global)") +
                      "\n      "
                  )
                ]
              )
            ]
          ),
          _c(
            VFlex,
            {
              staticClass: "list-item-style",
              staticStyle: { "margin-bottom": "32px" }
            },
            [
              _c("div", { staticClass: "H4-Secondary-Center" }, [
                _vm._v(_vm._s(_vm.companyName))
              ])
            ]
          ),
          _c(
            VForm,
            {
              ref: "form",
              model: {
                value: _vm.isMenuValid,
                callback: function($$v) {
                  _vm.isMenuValid = $$v
                },
                expression: "isMenuValid"
              }
            },
            [
              _vm._l(_vm.value, function(menu, index) {
                return [
                  _c("menu-set", {
                    key: menu.id || index,
                    attrs: {
                      parentMenu: _vm.getParentMenu(menu.parent_id),
                      menunames: _vm.menunames,
                      active: _vm.active,
                      "menu-id": menu.id
                    },
                    on: {
                      "menu:duplicate": function($event) {
                        return _vm.duplicate(index)
                      },
                      "menu:delete": function($event) {
                        return _vm.deletes(index)
                      },
                      "menu:disable": function($event) {
                        return _vm.disable(index)
                      },
                      "menu:export": function($event) {
                        return _vm.exportToExcel(index)
                      },
                      "menu:import": function($event) {
                        return _vm.$emit("menu:import", $event)
                      },
                      getMenu: function($event) {
                        return _vm.$emit("getMenu", $event)
                      },
                      setView: function($event) {
                        return _vm.$emit("setView", $event)
                      }
                    },
                    model: {
                      value: _vm.value[index],
                      callback: function($$v) {
                        _vm.$set(_vm.value, index, $$v)
                      },
                      expression: "value[index]"
                    }
                  })
                ]
              })
            ],
            2
          ),
          _c(VFlex, { attrs: { xs12: "" } }),
          !_vm.isLocal
            ? _c(
                VFlex,
                { staticStyle: { "padding-left": "8px" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { flat: "", disabled: _vm.readOnlyMode },
                      on: {
                        click: function($event) {
                          return _vm.newMenu()
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "Button-Primary-Center" },
                        [
                          _c(VIcon, [_vm._v("mdi-plus")]),
                          _vm._v("add menu set ")
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isLocal
            ? _c(
                VFlex,
                [
                  _c(VDivider),
                  _c(
                    "div",
                    { staticStyle: { padding: "15px 35px" } },
                    [
                      _c(
                        VForm,
                        { ref: "form" },
                        [
                          _c(VTextField, {
                            attrs: {
                              outline: "",
                              label: "Menu ID",
                              rules: _vm.importMenuRules(_vm.importMenuID),
                              disabled: _vm.readOnlyMode
                            },
                            on: {
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                $event.stopPropagation()
                                $event.preventDefault()
                                return (function() {})($event)
                              }
                            },
                            model: {
                              value: _vm.importMenuID,
                              callback: function($$v) {
                                _vm.importMenuID = $$v
                              },
                              expression: "importMenuID"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          staticStyle: { margin: "0px" },
                          attrs: {
                            color: "primary",
                            disabled: _vm.readOnlyMode,
                            loading: _vm.importLoading
                          },
                          on: { click: _vm.importMenu }
                        },
                        [_vm._v("IMPORT")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }