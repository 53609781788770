<template>
  <v-flex xs12>
    <v-layout class="display-flex modifier-group" align-center>
      <div class="Body-1-Selected-On-Surface-High-Emphasis-Left fg1">
        <slot name="unique_name"></slot>
      </div>
      <div class="display-flex align-center fg1 ml-4">
        <span class="Body-1-Selected-On-Surface-High-Emphasis-right">
          <v-text-field
            label="Label in App:"
            :value="groupProxy.label.en"
            :disabled="true"
          ></v-text-field>
        </span>
      </div>
      <div class="display-flex align-center fg1">
        <template>
          <v-btn
            flat
            icon
            class="ma-0"
            @click.stop="$emit('modifierGroup:edit')"
            :disabled="!group.id"
            v-on:keydown.enter.stop.prevent="() => {}"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <div class="mr-1"></div>
        </template>
        <template v-if="!isLocal">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn flat icon class="ma-0" @click.stop="$emit('modifierGroup:delete')" v-on="on">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
              <div class="mr-1"></div>
            </template>
            <span class="tooltipClass">
              <div>You will be removing this Modifier Group from following items:</div>
              <v-divider></v-divider>
              <div v-for="item in group.parent_items" :key="item.id">- {{ item.label.en }}</div>
            </span>
          </v-tooltip>
        </template>
        <div v-if="isLocal">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-checkbox
                  v-if="isLocal"
                  v-model="isHiddenProxy"
                  hide-details
                  on-icon="mdi-eye-off"
                  off-icon="mdi-eye"
                  :color="hiddenColor"
                  @click.native.stop
                  :false-value="false"
                  :true-value="true"
                  class="pt-0 mt-0 mr-2"
                  :class="isHiddenProxy === undefined && 'black-color'"
                  v-on="on"
                />
              </div>
            </template>
            <span>
              <span v-if="groupProxy.is.hidden">Hidden in App</span>
              <span v-else-if="groupProxy.is.hidden === undefined">No changes</span>
              <span v-else>Display in App</span>
            </span>
          </v-tooltip>
        </div>
        <v-flex class="sort_number">
          <v-text-field
            label="Sequence On Ticket"
            type="number"
            min="0"
            :value="sortNumberProxy"
            @input="sortNumberProxy = parseInt($event, 10) || undefined"
            :rules="sortNumberRules"
            v-on:keydown.enter.stop.prevent="() => {}"
            :disabled="!isLocal"
            :success-messages="sortNumberChangedMessage"
            @keypress="blockDecimals"
          />
        </v-flex>
        <v-flex>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon color="primary" dark v-on="on">mdi-help-circle</v-icon>
            </template>
            <span class="tooltipClass">
              <div>Your Items Selected:</div>
              <v-divider></v-divider>
              <div v-for="item in group.parent_items" :key="item.id">- {{ item.label.en }}</div>
            </span>
          </v-tooltip>
        </v-flex>
      </div>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import rules from '@/rules';

export default {
  props: {
    group: Object,
  },
  data: () => ({
    expand: false,
    showActions: false,
    editLabel: false,
    requiredRule: [rules.required('Required')],
    sortNumberRules: [rules.min(1)],
  }),
  computed: {
    ...mapGetters('adminPanel', ['isSiteOperator', 'isImUser', 'isAdmin']),
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('menus', ['isLocal', 'showPromoExemptField', 'readOnlyMode', 'menuVersion']),
    expandIcon() {
      return this.expand ? 'mdi-chevron-down' : 'mdi-chevron-up';
    },
    groupProxy: {
      get() {
        return this.group;
      },
      set(value) {
        this.$emit('update:group', value);
      },
    },
    isHiddenProxy: {
      get() {
        return this.group.is && this.group.is.hidden;
      },
      set(value) {
        this.groupProxy = { ...this.groupProxy, is: { ...this.groupProxy.is, hidden: value } };
        this.updateParentItems(value, 'hidden');
      },
    },
    sortNumberProxy: {
      get() {
        return this.groupProxy.meta && this.groupProxy.meta.sort_number;
      },
      set(v) {
        this.groupProxy = {
          ...this.groupProxy,
          meta: { ...this.groupProxy.meta, sort_number: v },
        };
        this.modGroupsInParentItems.forEach((modGroup) => {
          this.$set(modGroup, 'meta', { ...modGroup.meta, sort_number: v });
        });
      },
    },
    hiddenColor() {
      if (this.isHiddenProxy) {
        return 'red';
      }
      return 'green';
    },
    isVersion1() {
      return this.menuVersion === 1;
    },
    isVersion2() {
      return this.menuVersion === 2;
    },
    isSortNumberDisabled() {
      return this.readOnlyMode || (!this.isLocal && this.isVersion2);
    },
    modGroupsInParentItems() {
      return this.group.parent_items
        .map((item) => {
          if (!item.options) return false;
          return item.options.find((mg) => mg.id === this.group.id);
        })
        .filter(Boolean);
    },
    sortNumberChangedMessage() {
      return this.sortNumberProxy === undefined || !this.isLocal ? [] : ['Modified'];
    },
  },
  methods: {
    ...mapActions('menus', ['fetchGlobalModGroup']),
    async selectGlobalMod(id) {
      const selectedModGroup = await this.fetchGlobalModGroup({ id });
      this.groupProxy = { ...selectedModGroup };
      await this.$nextTick();
    },
    updateParentItems(value, property) {
      this.modGroupsInParentItems.forEach((modGroup) => {
        this.$set(modGroup.is, property, value);
        if (!modGroup.items) return;
        modGroup.items.forEach((i) => {
          if (!i.is) i.is = {};
          this.$set(i.is, property, value);
        });
      });
    },
    blockDecimals(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode === 46) {
        event.preventDefault();
      }
    },
  },
  beforeMount() {
    if (!this.groupProxy.is) {
      this.groupProxy.is = {
        disabled: false,
        out_of_stock: false,
        hidden: false,
      };
    }
  },
};
</script>

<style scoped>
.modifier-group {
  cursor: pointer;
  padding-right: 10px;
}

.modifier-group:hover {
  background-color: #f5f5f5;
}

.icon {
  margin: 10px 24px;
}

.modifiers-list {
  padding: 10px 0px;
  background-color: #fbfbfb;
}

.modifiers-list > * {
  padding: 0px 12px 0px 24px;
}

div >>> .size-line .v-text-field__slot input {
  padding-left: 24px;
}
.flex.sort_number {
  max-width: 3.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}
.tooltipClass {
  color: white;
  margin-right: 8px;
  width: fit-content;
  font-size: medium;
}
</style>
