<template>
  <v-flex xs6>
    <v-card height="350" width="640" elevation="10" class="cardStyle">
      <v-card-title primary-title>
        <div class="H6-Primary-Left ">Item Name: {{ currentItem.label.en }}</div>
      </v-card-title>
      <div class="v-card-content">
        <v-layout>
          <v-flex>
            <modifier-group-details
              v-for="option in currentItem.options"
              :key="option.id"
              v-bind:group="option"
              :isCard="true"
              :currentItem="currentItem"
            />
          </v-flex>
        </v-layout></div
    ></v-card>
  </v-flex>
</template>

<script>
import modifierGroupDetails from './modGroupDetails';

export default {
  props: {
    currentItem: Object,
  },
  components: {
    modifierGroupDetails,
  },
};
</script>

<style scoped>
.v-card__title.v-card__title--primary {
  padding: 14px 24px 0px 24px;
}
</style>

<style>
.cardStyle {
  overflow: scroll;
}
</style>
