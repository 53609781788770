<template>
  <v-layout>
    <list-item
      :active="active"
      :title="name"
      label="Enter Category Name"
      :edit.sync="edit"
      :rules="rules"
      :errors="errors"
      :secondary="secondary"
      @click.native="$emit('setCategory')"
      @update:title="$emit('update:name', $event)"
      @mouseover.native="showActions = true"
      @mouseleave.native="showActions = false"
    >
      <template v-slot:prefix>
        <v-text-field
          type="number"
          label="Sequence On Ticket"
          :value="sortNumberProxy"
          @input="sortNumberProxy = parseInt($event, 10) || undefined"
          :rules="sortNumberRules"
          v-on:keydown.enter.stop.prevent="() => {}"
          style="max-width: 3.5rem; margin-right: 1rem;"
          :disabled="readOnlyMode"
          @keypress="blockDecimals"
        />
      </template>
      <template v-slot:actions>
        <template v-if="!isLocal">
          <v-btn flat small icon @click.stop @mousedown.stop style="margin: 0px;">
            <v-menu offset-y z-index="3" ref="menu">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-show="showIcons">mdi-dots-vertical</v-icon>
              </template>
              <v-list>
                <v-list-tile @click="rename" :disabled="readOnlyMode">
                  <v-list-tile-title>Rename</v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="duplicate" :disabled="readOnlyMode">
                  <v-list-tile-title>Duplicate</v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="deletes" :disabled="readOnlyMode">
                  <v-list-tile-title>Delete</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  @click="$emit(`category:disable`, !isDisabled)"
                  :disabled="readOnlyMode"
                >
                  <v-list-tile-title>{{ isDisabled ? 'Enable' : 'Disable' }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-btn>
        </template>
        <template v-if="isLocal">
          <v-layout shrink>
            <v-flex>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-flex shrink>
                    <v-checkbox
                      v-model="isHidden"
                      hide-details
                      on-icon="mdi-eye-off"
                      off-icon="mdi-eye"
                      color="red"
                      :false-value="false"
                      :true-value="true"
                      :value-comparator="() => isHidden"
                      v-on:change="checkItemsHidden"
                      v-on="on"
                      :disabled="readOnlyMode"
                    />
                  </v-flex>
                </template>
                <span v-if="properties.hidden">Display in App</span>
                <span v-else>Hide in App</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </template>
      </template>
      <template v-slot:edit>
        <v-btn flat small icon @click.stop="deletes" v-if="name.length === 0">
          <v-icon @click="deletes" :disabled="readOnlyMode">mdi-delete-forever</v-icon>
        </v-btn>
      </template>
    </list-item>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import rules from '@/rules';
import listItem from './listItem';

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    meta: Object,
    categories: Array,
    menuid: String,
    properties: Object,
    items: Array,
  },
  data: () => ({
    edit: false,
    showActions: false,
    rules: [rules.required('Category Name is required')],
    sortNumberRules: [rules.min(1)],
  }),
  components: {
    listItem,
  },
  computed: {
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('menus', ['isLocal', 'readOnlyMode']),
    isDisabled() {
      return (this.properties && this.properties.disabled) || this.readOnlyMode;
    },
    isHidden: {
      get() {
        return this.properties && this.properties.hidden;
      },
      set(v) {
        const allItemsHidden = !this.items.length || this.items.every((i) => i.is && i.is.hidden);
        this.properties.hidden = allItemsHidden || v;
        this.$emit('category:hide', this.properties.hidden);
      },
    },
    showIcons() {
      if (this.$refs.menu) {
        return this.showActions || this.$refs.menu.isActive;
      }
      return this.showActions;
    },
    errors() {
      if (!this.categories) return '';
      const test = [...this.categories].map((c) => c.toLowerCase());
      test.splice(test.indexOf(this.name.toLowerCase()), 1);
      if (test.includes(this.name.toLowerCase())) {
        return 'Category name must be unique';
      }
      return '';
    },
    secondary() {
      if (this.isDisabled) {
        return '(disabled)';
      }
      if (this.isHidden) {
        return '(Hidden in app)';
      }
      return '';
    },
    sortNumberProxy: {
      get() {
        return this.meta && this.meta.sort_number;
      },
      set(v) {
        this.$emit('category:setSortNumber', v);
      },
    },
  },

  methods: {
    rename() {
      this.edit = true;
    },
    deletes() {
      this.$emit('category:delete');
    },
    duplicate() {
      this.$emit('category:duplicate');
    },
    checkItemsHidden(v) {
      if (v === false && (!this.items.length || this.items.every((i) => i.is && i.is.hidden))) {
        this.$toast.warning(
          `You have no items set to visible in this category.
          To display the category in the mobile app, please make at least 1 item inside the category visible.`,
          {
            color: '#2C74D8',
          },
        );
      }
    },
    blockDecimals(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode === 46) {
        event.preventDefault();
      }
    },
  },
  mounted() {
    if (this.name.length === 0) {
      this.edit = true;
    }
  },
};
</script>

<style></style>
