import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { "fill-height": "" } },
    [
      _c(
        "loading-wrapper",
        { attrs: { loading: !_vm.active_company, mt: "2vh", size: 100 } },
        [
          _c(
            VLayout,
            [
              _c(
                VFlex,
                {
                  staticStyle: { "min-width": "300px", "max-width": "450px" },
                  attrs: { xs3: "" }
                },
                [
                  _c("nav-drawer", {
                    ref: "menu_nav",
                    attrs: {
                      parentMenus: _vm.parentMenus,
                      active: {
                        category: _vm.activecategory,
                        menu: _vm.activemenu
                      },
                      title: _vm.navTitle,
                      loading: _vm.locking || _vm.publishing
                    },
                    on: {
                      setView: _vm.setView,
                      getMenu: _vm.getMenu,
                      "menu:import": _vm.importMenuSet
                    },
                    model: {
                      value: _vm.menus,
                      callback: function($$v) {
                        _vm.menus = $$v
                      },
                      expression: "menus"
                    }
                  })
                ],
                1
              ),
              (_vm.menusOriginal.length === 0 && _vm.readOnlyMode) ||
              (_vm.menusOriginal.length === 0 &&
                _vm.activecategory == null &&
                !_vm.readOnlyMode)
                ? _c(
                    VFlex,
                    [
                      !_vm.isLocalMenu
                        ? _c("menu-helper", {
                            on: {
                              newMenuSet: function($event) {
                                return _vm.$refs.menu_nav.newMenu($event)
                              }
                            }
                          })
                        : _c("local-menu-warning")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.menusOriginal.length || _vm.isFirstMenu
                ? _c(
                    VFlex,
                    [
                      _c(
                        VContainer,
                        { attrs: { "grid-list-xl": "" } },
                        [
                          _c(
                            VLayout,
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "justify-space-between": ""
                              }
                            },
                            [
                              _c(
                                VFlex,
                                { attrs: { xs6: "" } },
                                [
                                  _c(VTextField, {
                                    attrs: { label: "Search Menus", solo: "" },
                                    model: {
                                      value: _vm.search,
                                      callback: function($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VFlex,
                                { attrs: { shrink: "" } },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.locking,
                                        disabled: !_vm.canEdit
                                      },
                                      on: { click: _vm.edit }
                                    },
                                    [
                                      _c(VIcon, [_vm._v("mdi-pencil")]),
                                      _vm._v("EDIT MENU\n              ")
                                    ],
                                    1
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.publishing,
                                        disabled: !_vm.canPublish
                                      },
                                      on: { click: _vm.publishMenuAll }
                                    },
                                    [
                                      _c(VIcon, [_vm._v("mdi-upload")]),
                                      _vm._v("publish\n              ")
                                    ],
                                    1
                                  ),
                                  !_vm.isLocalMenu && _vm.activeCategoryData
                                    ? _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            color: "primary",
                                            disabled: _vm.readOnlyMode
                                          },
                                          on: { click: _vm.newItem }
                                        },
                                        [
                                          _c(VIcon, [_vm._v("mdi-plus")]),
                                          _vm._v("new item\n              ")
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.recentMenu
                            ? _c(
                                VLayout,
                                {
                                  attrs: {
                                    row: "",
                                    "justify-end": "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "menu-status-chip",
                                    { attrs: { "menu-id": _vm.recentMenu.id } },
                                    [
                                      _vm.canKick
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                VTooltip,
                                                {
                                                  attrs: {
                                                    disabled: !_vm.canKick,
                                                    bottom: "",
                                                    "max-width": "150"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              VBtn,
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.kick
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  VIcon,
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "red"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-minus-circle"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1124210137
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Kick " +
                                                        _vm._s(
                                                          _vm.recentMenuUser
                                                            .name &&
                                                            _vm.recentMenuUser
                                                              .name.first
                                                        )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "loading-wrapper",
                            {
                              attrs: {
                                loading: _vm.loadingItems,
                                size: 40,
                                mt: "2vh"
                              }
                            },
                            [
                              _vm.activeCategoryData
                                ? _c(
                                    VLayout,
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(VFlex, { attrs: { xs10: "" } }, [
                                        _c(
                                          "h2",
                                          {
                                            staticStyle: {
                                              width: "fit-content"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "category-heading"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      (_vm.activeCategoryData
                                                        .label &&
                                                        _vm.activeCategoryData
                                                          .label.en) ||
                                                        ""
                                                    ) +
                                                    "\n                    "
                                                ),
                                                _vm.showEditLocalCategoryNameIcon
                                                  ? _c(
                                                      VTooltip,
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    VBtn,
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "edit-btn",
                                                                        attrs: {
                                                                          flat:
                                                                            "",
                                                                          small:
                                                                            "",
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.readOnlyMode
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.openEditCategoryNameDialog(
                                                                              $event
                                                                            )
                                                                          },
                                                                          mousedown: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                          }
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-pencil"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          3041878308
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Edit Category Name"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _vm.showGlobalCategoryName
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "Caption-Selected-On-Surface-High-Emphasis-Left secondary-text"
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v("Original Name:")
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.globalCategoryName
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]),
                                      _c(VFlex, { attrs: { xs2: "" } }, [
                                        _vm._v(
                                          "Menu Version: " +
                                            _vm._s(_vm.menuVersion)
                                        )
                                      ]),
                                      _c(
                                        VFlex,
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "center"
                                          },
                                          attrs: { "offset-xs10": "" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "5px"
                                              }
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v("Hide Inactive")
                                              ])
                                            ]
                                          ),
                                          _c(VSwitch, {
                                            attrs: {
                                              disabled: _vm.readOnlyMode,
                                              color: "primary"
                                            },
                                            model: {
                                              value: _vm.hideDisabledItems,
                                              callback: function($$v) {
                                                _vm.hideDisabledItems = $$v
                                              },
                                              expression: "hideDisabledItems"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VFlex,
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            VLayout,
                                            {
                                              attrs: {
                                                "justify-left": "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                VFlex,
                                                {
                                                  staticStyle: {
                                                    "padding-left": "25px",
                                                    "padding-bottom": "20px"
                                                  },
                                                  attrs: { xs1: "" }
                                                },
                                                [
                                                  _c(VCheckbox, {
                                                    attrs: {
                                                      primary: "",
                                                      "hide-details": "",
                                                      disabled: _vm.readOnlyMode
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.toggleSelectedItems(
                                                          $event
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.isAllItemsSelected,
                                                      callback: function($$v) {
                                                        _vm.isAllItemsSelected = $$v
                                                      },
                                                      expression:
                                                        "isAllItemsSelected"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                VFlex,
                                                {
                                                  staticStyle: {
                                                    "padding-left": "0",
                                                    "padding-top": "20px"
                                                  },
                                                  attrs: { xs1: "" }
                                                },
                                                [
                                                  _vm.updateMessage
                                                    ? _c(
                                                        VBtn,
                                                        {
                                                          attrs: {
                                                            flat: "",
                                                            disabled:
                                                              _vm.isBulkUpdateDisabled
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.showBulkUpdate
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "Button-Primary-Center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.updateMessage
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VFlex,
                                        [
                                          _c(VDataTable, {
                                            ref: "sortableTable",
                                            staticClass: "menutable",
                                            attrs: {
                                              headers: _vm.headers,
                                              items:
                                                _vm.activeCategoryData.items,
                                              search: _vm.search,
                                              "rows-per-page-items":
                                                _vm.rowsPerPageItems
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "headers",
                                                  fn: function(props) {
                                                    return [
                                                      _c(
                                                        "tr",
                                                        [
                                                          _c(
                                                            "th",
                                                            [_c(VSpacer)],
                                                            1
                                                          ),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "left"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        Sequence "
                                                              ),
                                                              _c("br"),
                                                              _vm._v(
                                                                "\n                        On Ticket\n                        "
                                                              ),
                                                              !_vm.readOnlyMode &&
                                                              !_vm.showChitEditTextBoxes
                                                                ? _c(
                                                                    VBtn,
                                                                    {
                                                                      staticClass:
                                                                        "edit-btn",
                                                                      attrs: {
                                                                        flat:
                                                                          "",
                                                                        small:
                                                                          "",
                                                                        icon: ""
                                                                      },
                                                                      on: {
                                                                        mousedown: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                        },
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.toggleChitNumberEdits(
                                                                            $event
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-pencil"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.showChitEditTextBoxes
                                                                ? _c(
                                                                    VBtn,
                                                                    {
                                                                      staticClass:
                                                                        "edit-btn",
                                                                      attrs: {
                                                                        flat:
                                                                          "",
                                                                        icon: ""
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.toggleChitNumberEdits
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-check"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _vm._l(
                                                            props.headers,
                                                            function(header) {
                                                              return _c(
                                                                "th",
                                                                {
                                                                  key:
                                                                    header.text,
                                                                  staticStyle: {
                                                                    "text-align":
                                                                      "left"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        header.text
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "items",
                                                  fn: function(props) {
                                                    return [
                                                      _c("item-row", {
                                                        key:
                                                          props.item.id +
                                                          "-" +
                                                          props.index,
                                                        staticClass:
                                                          "sortableRow",
                                                        attrs: {
                                                          item: _vm.getItemCopy(
                                                            props.item
                                                          ),
                                                          showChitEditTextBoxes:
                                                            _vm.showChitEditTextBoxes,
                                                          "selected-items":
                                                            _vm.selectedItems
                                                        },
                                                        on: {
                                                          "update:selectedItems": function(
                                                            $event
                                                          ) {
                                                            _vm.selectedItems = $event
                                                          },
                                                          "update:selected-items": function(
                                                            $event
                                                          ) {
                                                            _vm.selectedItems = $event
                                                          },
                                                          "item:delete": function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteItem(
                                                              props.item.id
                                                            )
                                                          },
                                                          "item:copy": function(
                                                            $event
                                                          ) {
                                                            return _vm.copyItem(
                                                              props.item.id
                                                            )
                                                          },
                                                          "item:groupaction": function(
                                                            $event
                                                          ) {
                                                            return _vm.modifyGroupAction()
                                                          }
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openItemConfig(
                                                              props.item,
                                                              true
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3730880121
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showItemConfig
                ? _c(
                    "div",
                    [
                      _vm.activeCategoryData
                        ? _c("item-config", {
                            ref: "itemPanel",
                            attrs: {
                              showItemImageTab: _vm.getShowItemImageTab(
                                _vm.activeitem
                              ),
                              parentItem: _vm.getParentMenuItem(_vm.activeitem),
                              hasDescEditPermission: _vm.hasDescEditPermission,
                              hasCaloriesEditPermissions:
                                _vm.hasCaloriesEditPermissions,
                              hasLabelEditPermissions:
                                _vm.hasLabelEditPermissions,
                              item: _vm.activeitem,
                              activeMenuId: _vm.activemenu,
                              isUSLocationBrand: _vm.isUSLocationBrand,
                              taxOptions: _vm.taxOptions
                            },
                            on: {
                              "update:item": _vm.updateItem,
                              cancelEditing: _vm.cancelEditing
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("category-name-dialog", {
                attrs: {
                  open: _vm.openCategoryDialog,
                  category: _vm.activeCategoryData,
                  parentCategory: _vm.parentMenuCategory
                },
                on: {
                  "update:open": function($event) {
                    _vm.openCategoryDialog = $event
                  }
                }
              }),
              _vm.isAnyItemsSelected
                ? _c("bulk-update", {
                    ref: "bulkUpdatePanel",
                    attrs: {
                      menu: _vm.activemenu,
                      group: _vm.activeCategoryId,
                      updateMessage: _vm.updateMessage,
                      selectedItems: _vm.selectedItems
                    },
                    on: {
                      "update:selectedItems": function($event) {
                        _vm.selectedItems = $event
                      },
                      "update:selected-items": function($event) {
                        _vm.selectedItems = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("import-errors-modal", {
        attrs: { errors: _vm.importErrors },
        model: {
          value: _vm.showImportErrorsModal,
          callback: function($$v) {
            _vm.showImportErrorsModal = $$v
          },
          expression: "showImportErrorsModal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }