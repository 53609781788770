import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VFlex,
    { attrs: { xs6: "" } },
    [
      _c(
        VCard,
        {
          staticClass: "cardStyle",
          attrs: { height: "350", width: "640", elevation: "10" }
        },
        [
          _c(VCardTitle, { attrs: { "primary-title": "" } }, [
            _c("div", { staticClass: "H6-Primary-Left " }, [
              _vm._v("Item Name: " + _vm._s(_vm.currentItem.label.en))
            ])
          ]),
          _c(
            "div",
            { staticClass: "v-card-content" },
            [
              _c(
                VLayout,
                [
                  _c(
                    VFlex,
                    _vm._l(_vm.currentItem.options, function(option) {
                      return _c("modifier-group-details", {
                        key: option.id,
                        attrs: {
                          group: option,
                          isCard: true,
                          currentItem: _vm.currentItem
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }