import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    {
      staticClass: "modifier",
      attrs: { row: "", wrap: "", "align-center": "" },
      on: {
        mouseover: function($event) {
          _vm.showActions = true
        },
        mouseleave: function($event) {
          _vm.showActions = false
        },
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.disableEdit($event)
        }
      }
    },
    [
      _c(
        VFlex,
        { attrs: { xs8: "" } },
        [
          _c(
            VLayout,
            [
              _c(
                VFlex,
                {
                  staticClass: "d-flex align-center",
                  staticStyle: {
                    "margin-right": "10px",
                    width: "18rem",
                    "max-width": "18rem"
                  },
                  on: { click: _vm.edit }
                },
                [
                  _vm.isVersion1 || (_vm.isVersion2 && _vm.isLocal)
                    ? _c(
                        "div",
                        [
                          !_vm.editable
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "Body-1-Black-Medium-Emphasis-Left",
                                  staticStyle: { "pointer-events": "none" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.modifier.label.en) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _c(VTextField, {
                                ref: "label",
                                attrs: {
                                  rules: _vm.labelRules,
                                  "append-icon": "",
                                  label: "Item Name",
                                  disabled: _vm.isModifierLabelDisabled
                                },
                                model: {
                                  value: _vm.modifier.label.en,
                                  callback: function($$v) {
                                    _vm.$set(_vm.modifier.label, "en", $$v)
                                  },
                                  expression: "modifier.label.en"
                                }
                              }),
                          _vm.showGlobalModifierName
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "Caption-Selected-On-Surface-High-Emphasis-Left secondary-text mt-0 mb-2 display-flex align-center f-wrap"
                                },
                                [
                                  _c("div", [
                                    _c("strong", [_vm._v("Original Name:")]),
                                    _vm._v(" " + _vm._s(_vm.globalModifierName))
                                  ]),
                                  !_vm.isModifierLabelDisabled
                                    ? _c(
                                        VTooltip,
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "clickable tiny-btn",
                                                          attrs: {
                                                            flat: "",
                                                            icon: "",
                                                            round: "",
                                                            small: "",
                                                            color: "gray"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.setModifierName(
                                                                _vm.globalModifierName
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v("mdi-restore")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2217258126
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Use Original Name")
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          !_vm.editable
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "Body-1-Black-Medium-Emphasis-Left",
                                  staticStyle: { "pointer-events": "none" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.modifier.label.en) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _c(VSelect, {
                                ref: "label",
                                attrs: {
                                  rules: _vm.selectRule,
                                  placeholder: _vm.modifier.label.en,
                                  value: _vm.modifier,
                                  disabled: _vm.readOnlyMode,
                                  items:
                                    _vm.getGlobalModGroupsModifiersToDisplay,
                                  "item-text": "label.en",
                                  "item-value": "id"
                                },
                                on: {
                                  focus: _vm.getGlobalModItems,
                                  change: _vm.setGlobalMod
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  }
                                }
                              })
                        ],
                        1
                      )
                ]
              ),
              _c(
                VFlex,
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.edit }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editable,
                          expression: "!editable"
                        }
                      ],
                      staticClass: "Body-1-Black-Medium-Emphasis-Left",
                      staticStyle: { "pointer-events": "none" }
                    },
                    [_vm._v("+ $" + _vm._s(_vm.price && _vm.price.toFixed(2)))]
                  ),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable"
                      }
                    ],
                    ref: "price",
                    attrs: {
                      label: "Price",
                      type: "number",
                      value: _vm.price,
                      rules: _vm.priceRules,
                      prefix: "+$",
                      "single-line": "",
                      disabled:
                        _vm.readOnlyMode || (_vm.isVersion2 && !_vm.isLocal)
                    },
                    on: {
                      input: function($event) {
                        _vm.price = parseFloat($event) || 0
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.edit }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editable,
                          expression: "!editable"
                        }
                      ],
                      staticClass: "Body-1-Black-Medium-Emphasis-Left",
                      staticStyle: { "pointer-events": "none" }
                    },
                    [_vm._v("Calories:" + _vm._s(_vm.calories))]
                  ),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable"
                      }
                    ],
                    ref: "calories",
                    attrs: {
                      label: "Calories",
                      type: "number",
                      rules: _vm.calorieRules,
                      disabled: _vm.isModifierCaloriesDisabled
                    },
                    model: {
                      value: _vm.calories,
                      callback: function($$v) {
                        _vm.calories = _vm._n($$v)
                      },
                      expression: "calories"
                    }
                  })
                ],
                1
              ),
              _vm.showPluField
                ? _c(
                    VFlex,
                    { on: { click: _vm.edit } },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.editable,
                              expression: "!editable"
                            }
                          ],
                          staticClass: "Body-1-Black-Medium-Emphasis-Left",
                          staticStyle: { "pointer-events": "none" }
                        },
                        [_vm._v("PLU:" + _vm._s(_vm.plu))]
                      ),
                      _c(VTextField, {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable,
                            expression: "editable"
                          }
                        ],
                        ref: "plu",
                        attrs: { label: "PLU:", disabled: _vm.isPluDisabled },
                        on: { click: _vm.edit },
                        model: {
                          value: _vm.plu,
                          callback: function($$v) {
                            _vm.plu = $$v
                          },
                          expression: "plu"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VFlex,
                { staticClass: "pt-3" },
                [
                  _vm.editable
                    ? _c(
                        VBtn,
                        {
                          staticClass: "ma-0",
                          attrs: { flat: "", icon: "" },
                          on: { click: _vm.disableEdit }
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-check")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(VSpacer),
      _c(
        VFlex,
        { staticClass: "display-flex align-center", attrs: { shrink: "" } },
        [
          _c(
            VBtn,
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showActions && !_vm.isLocal && _vm.isVersion1,
                  expression: "showActions && !isLocal && isVersion1"
                }
              ],
              staticClass: "ma-0",
              attrs: { flat: "", icon: "", disabled: _vm.readOnlyMode },
              on: {
                click: function($event) {
                  return _vm.$emit("modifier:copy")
                }
              }
            },
            [
              _c(VIcon, { attrs: { small: "" } }, [
                _vm._v("mdi-content-copy")
              ])
            ],
            1
          ),
          _c(
            VBtn,
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showActions && !_vm.isLocal,
                  expression: "showActions && !isLocal"
                }
              ],
              staticClass: "ma-0",
              attrs: { flat: "", icon: "", disabled: _vm.readOnlyMode },
              on: {
                click: function($event) {
                  return _vm.$emit("modifier:delete")
                }
              }
            },
            [_c(VIcon, { attrs: { small: "" } }, [_vm._v("mdi-delete")])],
            1
          ),
          _c("div", { staticClass: "mr-1" }),
          !_vm.isLocal
            ? _c(VCheckbox, {
                staticClass: "pt-0 mt-0 mr-4 d-inline-block",
                attrs: {
                  "hide-details": "",
                  color: "secondary",
                  "false-value": true,
                  "true-value": false,
                  disabled: _vm.readOnlyMode || _vm.isVersion2
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("modifier:groupaction")
                  }
                },
                nativeOn: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                },
                model: {
                  value: _vm.modifier.is.disabled,
                  callback: function($$v) {
                    _vm.$set(_vm.modifier.is, "disabled", $$v)
                  },
                  expression: "modifier.is.disabled"
                }
              })
            : _vm._e(),
          _vm.isLocal
            ? _c(
                "div",
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "div",
                                  _vm._g({}, on),
                                  [
                                    _c(
                                      VCheckbox,
                                      _vm._g(
                                        {
                                          staticClass:
                                            "pt-0 mt-0 mr-2 d-inline-block",
                                          attrs: {
                                            "hide-details": "",
                                            "on-icon": "mdi-eye-off",
                                            "off-icon": "mdi-eye",
                                            color: _vm.hiddenColor,
                                            "false-value": false,
                                            "true-value": true,
                                            disabled:
                                              _vm.disableEditForModPLU ||
                                              _vm.readOnlyMode
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$emit(
                                                "modifier:groupaction"
                                              )
                                            }
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                            }
                                          },
                                          model: {
                                            value: _vm.modifier.is.hidden,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.modifier.is,
                                                "hidden",
                                                $$v
                                              )
                                            },
                                            expression: "modifier.is.hidden"
                                          }
                                        },
                                        on
                                      )
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        881453190
                      )
                    },
                    [
                      _vm.disableEditForModPLU
                        ? _c("span", [_vm._v("Requires PLU")])
                        : _c("span", [
                            _vm.modifier.is.hidden
                              ? _c("span", [_vm._v("Hidden in App")])
                              : _c("span", [_vm._v("Display in App")])
                          ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isLocal
            ? _c(VCheckbox, {
                staticClass: "pt-0 mt-0 mr-4 d-inline-block",
                attrs: {
                  "hide-details": "",
                  color: "secondary",
                  "false-value": true,
                  "true-value": false,
                  disabled: _vm.disableEditForModPLU || _vm.readOnlyMode
                },
                nativeOn: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                },
                model: {
                  value: _vm.outOfStockProxy,
                  callback: function($$v) {
                    _vm.outOfStockProxy = $$v
                  },
                  expression: "outOfStockProxy"
                }
              })
            : _vm._e(),
          _vm.showPromoExemptField
            ? _c(
                "span",
                [
                  _c(VSwitch, {
                    staticClass: "pt-0 mt-0 mr-4 d-inline-block",
                    class: { switchenabled: !_vm.promoExemptProxy },
                    attrs: {
                      inset: "",
                      "hide-details": "",
                      "false-value": false,
                      "true-value": true,
                      color: "green",
                      disabled: _vm.isSiteOperator || _vm.readOnlyMode
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    },
                    model: {
                      value: _vm.promoExemptProxy,
                      callback: function($$v) {
                        _vm.promoExemptProxy = $$v
                      },
                      expression: "promoExemptProxy"
                    }
                  }),
                  _c("div", { staticClass: "promo_exempt_label" }, [
                    _vm._v(_vm._s(_vm.promoExemptProxy ? "Yes" : "No"))
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            VFlex,
            { staticClass: "sort_number" },
            [
              _c(VTextField, {
                attrs: {
                  type: "number",
                  min: "0",
                  label: "",
                  rules: _vm.sortNumberRules,
                  disabled: _vm.isSortNumberDisabled
                },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return (function() {})($event)
                  }
                },
                model: {
                  value: _vm.sortNumberProxy,
                  callback: function($$v) {
                    _vm.sortNumberProxy = $$v
                  },
                  expression: "sortNumberProxy"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.isVersion2
        ? _c(
            VFlex,
            {
              staticStyle: { "margin-right": "10px" },
              attrs: { xs7: "" },
              on: { click: _vm.edit }
            },
            [
              _c("div", { staticClass: "taxable_price" }, [
                _vm._v("Taxable Price")
              ]),
              _c(VTextField, {
                ref: "taxablePrice",
                attrs: {
                  label: "Taxable Price",
                  type: "number",
                  value: _vm.taxablePrice,
                  rules: _vm.taxablePriceRules,
                  prefix: "+$",
                  "single-line": "",
                  disabled: _vm.isTaxablePriceDisabled
                },
                on: {
                  input: function($event) {
                    _vm.taxablePrice = parseFloat($event) || 0
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isVersion2
        ? _c(
            VFlex,
            { attrs: { xs7: "" }, on: { click: _vm.edit } },
            [
              _c(VSelect, {
                staticClass: "mt-0 pt-0",
                attrs: {
                  items: _vm.taxoptions,
                  label: "Tax Tags",
                  multiple: "",
                  clearable: "",
                  chips: "",
                  disabled: true
                },
                model: {
                  value: _vm.modifier.meta.taxes,
                  callback: function($$v) {
                    _vm.$set(_vm.modifier.meta, "taxes", $$v)
                  },
                  expression: "modifier.meta.taxes"
                }
              }),
              _c(VTextField, {
                attrs: {
                  label: "Tax Tag (pcc)",
                  clearable: "",
                  disabled: true
                },
                model: {
                  value: _vm.pccTaxTagProxy,
                  callback: function($$v) {
                    _vm.pccTaxTagProxy = $$v
                  },
                  expression: "pccTaxTagProxy"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }