<template>
  <v-dialog v-model="openProxy" width="800">
    <v-card>
      <v-card-title class="H4-Secondary-Center" primary-title>
        Edit Menu Set Name
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-layout row wrap>
            <v-flex xs10>
              <v-text-field
                label="Menu Set Name"
                v-model="name"
                :rules="nameRules"
                :disabled="useGlobalMenuNameProxy"
              />
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <v-checkbox
                  v-model="useGlobalMenuNameProxy"
                  hide-details
                  color="secondary"
                  @click.native.stop
                  class="pt-0 mt-0 mr-4"
                  label="Use original name"
                />
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="clear">Cancel</v-btn>
        <v-btn color="primary" flat @click="save" :disabled="isSaveDisabled">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules';

export default {
  props: ['open', 'menu', 'parentMenu'],
  computed: {
    openProxy: {
      get() {
        return this.open;
      },
      set(v) {
        this.$emit('update:open', v);
      },
    },
    parentName() {
      return (this.parentMenu && this.parentMenu.label && this.parentMenu.label.en) || '';
    },
    useGlobalMenuNameProxy: {
      get() {
        return this.useGlobalMenuName;
      },
      set(v) {
        this.useGlobalMenuName = v;
        if (v) {
          this.name = this.parentName;
        }
      },
    },
    isSaveDisabled() {
      return (this.menu && this.menu.label && this.menu.label.en) === this.name;
    },
  },
  data: () => ({
    nameRules: [rules.required('Brand name is required')],
    isValid: true,
    name: '',
    useGlobalMenuName: false,
  }),
  watch: {
    open(v) {
      if (!v) {
        this.name = '';
        this.$refs.form.resetValidation();
        return;
      }
      this.name = (this.menu && this.menu.label && this.menu.label.en) || '';
      this.useGlobalMenuName = this.name === this.parentName;
    },
  },
  methods: {
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$set(this.menu, 'label', { ...this.menu.label, en: this.name });
      this.clear();
    },
    clear() {
      this.$refs.form.resetValidation();
      this.name = '';
      this.openProxy = false;
    },
  },
};
</script>

<style></style>
