import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    [
      _c("list-item", {
        attrs: {
          active: _vm.active,
          title: _vm.name,
          label: "Enter Category Name",
          edit: _vm.edit,
          rules: _vm.rules,
          errors: _vm.errors,
          secondary: _vm.secondary
        },
        on: {
          "update:edit": function($event) {
            _vm.edit = $event
          },
          "update:title": function($event) {
            return _vm.$emit("update:name", $event)
          }
        },
        nativeOn: {
          click: function($event) {
            return _vm.$emit("setCategory")
          },
          mouseover: function($event) {
            _vm.showActions = true
          },
          mouseleave: function($event) {
            _vm.showActions = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "prefix",
            fn: function() {
              return [
                _c(VTextField, {
                  staticStyle: {
                    "max-width": "3.5rem",
                    "margin-right": "1rem"
                  },
                  attrs: {
                    type: "number",
                    label: "Sequence On Ticket",
                    value: _vm.sortNumberProxy,
                    rules: _vm.sortNumberRules,
                    disabled: _vm.readOnlyMode
                  },
                  on: {
                    input: function($event) {
                      _vm.sortNumberProxy = parseInt($event, 10) || undefined
                    },
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.stopPropagation()
                      $event.preventDefault()
                      return (function() {})($event)
                    },
                    keypress: _vm.blockDecimals
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                !_vm.isLocal
                  ? [
                      _c(
                        VBtn,
                        {
                          staticStyle: { margin: "0px" },
                          attrs: { flat: "", small: "", icon: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            },
                            mousedown: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c(
                            VMenu,
                            {
                              ref: "menu",
                              attrs: { "offset-y": "", "z-index": "3" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          VIcon,
                                          _vm._g(
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.showIcons,
                                                  expression: "showIcons"
                                                }
                                              ]
                                            },
                                            on
                                          ),
                                          [_vm._v("mdi-dots-vertical")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2002709381
                              )
                            },
                            [
                              _c(
                                VList,
                                [
                                  _c(
                                    VListTile,
                                    {
                                      attrs: { disabled: _vm.readOnlyMode },
                                      on: { click: _vm.rename }
                                    },
                                    [
                                      _c(VListTileTitle, [
                                        _vm._v("Rename")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListTile,
                                    {
                                      attrs: { disabled: _vm.readOnlyMode },
                                      on: { click: _vm.duplicate }
                                    },
                                    [
                                      _c(VListTileTitle, [
                                        _vm._v("Duplicate")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListTile,
                                    {
                                      attrs: { disabled: _vm.readOnlyMode },
                                      on: { click: _vm.deletes }
                                    },
                                    [
                                      _c(VListTileTitle, [
                                        _vm._v("Delete")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListTile,
                                    {
                                      attrs: { disabled: _vm.readOnlyMode },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "category:disable",
                                            !_vm.isDisabled
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(VListTileTitle, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.isDisabled
                                              ? "Enable"
                                              : "Disable"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm.isLocal
                  ? [
                      _c(
                        VLayout,
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            VFlex,
                            [
                              _c(
                                VTooltip,
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              VFlex,
                                              { attrs: { shrink: "" } },
                                              [
                                                _c(
                                                  VCheckbox,
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        "hide-details": "",
                                                        "on-icon":
                                                          "mdi-eye-off",
                                                        "off-icon": "mdi-eye",
                                                        color: "red",
                                                        "false-value": false,
                                                        "true-value": true,
                                                        "value-comparator": function() {
                                                          return _vm.isHidden
                                                        },
                                                        disabled:
                                                          _vm.readOnlyMode
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.checkItemsHidden
                                                      },
                                                      model: {
                                                        value: _vm.isHidden,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.isHidden = $$v
                                                        },
                                                        expression: "isHidden"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2835342070
                                  )
                                },
                                [
                                  _vm.properties.hidden
                                    ? _c("span", [_vm._v("Display in App")])
                                    : _c("span", [_vm._v("Hide in App")])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "edit",
            fn: function() {
              return [
                _vm.name.length === 0
                  ? _c(
                      VBtn,
                      {
                        attrs: { flat: "", small: "", icon: "" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.deletes($event)
                          }
                        }
                      },
                      [
                        _c(
                          VIcon,
                          {
                            attrs: { disabled: _vm.readOnlyMode },
                            on: { click: _vm.deletes }
                          },
                          [_vm._v("mdi-delete-forever")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }