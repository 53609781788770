import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VFlex,
    [
      _c("list-item", {
        attrs: {
          title: _vm.menu.label.en,
          fontSize: "large",
          icon: _vm.icon,
          edit: _vm.edit,
          rules: _vm.rules,
          errors: _vm.errors,
          secondary: _vm.secondary
        },
        on: {
          "update:title": function($event) {
            return _vm.$set(_vm.menu.label, "en", $event)
          },
          "update:edit": _vm.checkCategories
        },
        nativeOn: {
          click: function($event) {
            return _vm.expandAction($event)
          },
          mouseover: function($event) {
            _vm.showActions = true
          },
          mouseleave: function($event) {
            _vm.showActions = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _vm.isLocal
                  ? [
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      VFlex,
                                      { attrs: { shrink: "" } },
                                      [
                                        _c(
                                          VCheckbox,
                                          _vm._g(
                                            {
                                              attrs: {
                                                "hide-details": "",
                                                "on-icon": "mdi-eye-off",
                                                "off-icon": "mdi-eye",
                                                color: "red",
                                                "false-value": false,
                                                "true-value": true,
                                                disabled: _vm.readOnlyMode
                                              },
                                              model: {
                                                value: _vm.menu.is.hidden,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.menu.is,
                                                    "hidden",
                                                    $$v
                                                  )
                                                },
                                                expression: "menu.is.hidden"
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3082465246
                          )
                        },
                        [
                          _vm.menu.is.hidden
                            ? _c("span", [_vm._v("Hidden in App")])
                            : _c("span", [_vm._v("Display in App")])
                        ]
                      )
                    ]
                  : _vm._e(),
                !_vm.isLocal
                  ? [
                      _c(
                        VBtn,
                        {
                          staticStyle: { margin: "0px", "margin-right": "8px" },
                          attrs: {
                            flat: "",
                            small: "",
                            icon: "",
                            disabled: _vm.readOnlyMode
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.addCategory($event)
                            },
                            mousedown: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [_c(VIcon, [_vm._v("mdi-plus")])],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          staticStyle: { margin: "0px" },
                          attrs: { flat: "", small: "", icon: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            },
                            mousedown: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c(
                            VMenu,
                            {
                              ref: "menu",
                              attrs: { "offset-y": "", "z-index": "3" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          VIcon,
                                          _vm._g(
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.showActions,
                                                  expression: "showActions"
                                                }
                                              ],
                                              on: {
                                                click: function($event) {
                                                  return _vm.checkMenuForGroups()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("mdi-dots-vertical")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                20158308
                              )
                            },
                            [
                              _c(
                                VList,
                                [
                                  _c(
                                    VListTile,
                                    {
                                      attrs: { disabled: _vm.readOnlyMode },
                                      on: { click: _vm.rename }
                                    },
                                    [
                                      _c(VListTileTitle, [
                                        _vm._v("Rename")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm.isVersion2
                                    ? _c(
                                        VListTile,
                                        {
                                          attrs: { disabled: _vm.readOnlyMode },
                                          on: { click: _vm.duplicate }
                                        },
                                        [
                                          _c(VListTileTitle, [
                                            _vm._v("Duplicate")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    VListTile,
                                    {
                                      attrs: { disabled: _vm.readOnlyMode },
                                      on: { click: _vm.disable }
                                    },
                                    [
                                      _c(VListTileTitle, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.isDisabled
                                              ? "Enable"
                                              : "Disable"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListTile,
                                    {
                                      attrs: { disabled: _vm.readOnlyMode },
                                      on: { click: _vm.deletes }
                                    },
                                    [
                                      _c(VListTileTitle, [
                                        _vm._v("Delete")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(VDivider),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false"
                                      }
                                    ],
                                    ref: _vm.getImportInputId(),
                                    attrs: {
                                      id: _vm.getImportInputId(),
                                      type: "file",
                                      accept: ".xlsx"
                                    },
                                    on: { change: _vm.onMenuSetImport }
                                  }),
                                  _vm.isVersion2
                                    ? _c(
                                        VListTile,
                                        {
                                          attrs: {
                                            disabled: !_vm.readOnlyMode
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.$refs[
                                                _vm.getImportInputId()
                                              ].click()
                                            }
                                          }
                                        },
                                        [
                                          _c(VListTileTitle, [
                                            _vm._v("Import")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isVersion2
                                    ? _c(
                                        VListTile,
                                        {
                                          attrs: {
                                            disabled: !_vm.readOnlyMode
                                          },
                                          on: { click: _vm.exportMenuSet }
                                        },
                                        [
                                          _c(VListTileTitle, [
                                            _vm._v("Export")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "edit",
            fn: function() {
              return [
                _vm.menu.label.en.length === 0
                  ? _c(
                      VBtn,
                      {
                        attrs: {
                          flat: "",
                          small: "",
                          icon: "",
                          disabled: _vm.readOnlyMode
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.deletes($event)
                          }
                        }
                      },
                      [
                        _c(
                          VIcon,
                          {
                            attrs: { disabled: _vm.readOnlyMode },
                            on: { click: _vm.deletes }
                          },
                          [_vm._v("mdi-delete-forever")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "secondaryData",
            fn: function() {
              return [
                _vm.showGlobalMenuName
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "Caption-Selected-On-Surface-High-Emphasis-Left secondary-text"
                      },
                      [
                        _c("strong", [_vm._v("Original Name:")]),
                        _vm._v(" " + _vm._s(_vm.globalMenuName) + "\n      ")
                      ]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "inlineActions",
            fn: function() {
              return [
                _vm.showEditLocalMenuNameIcon
                  ? _c(
                      VTooltip,
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        staticClass: "edit-btn",
                                        attrs: {
                                          flat: "",
                                          small: "",
                                          icon: "",
                                          disabled: _vm.readOnlyMode
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.openEditMenuNameDialog(
                                              $event
                                            )
                                          },
                                          mousedown: function($event) {
                                            $event.stopPropagation()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [_c(VIcon, [_vm._v("mdi-pencil")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1216563975
                        )
                      },
                      [_c("span", [_vm._v("Edit Menu Set Name")])]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.expanded
        ? _c(
            "div",
            [
              _c(
                "loading-wrapper",
                { attrs: { loading: !_vm.menu.groups, size: 30, mt: "2vh" } },
                [
                  _vm.menu.groups
                    ? _c(
                        "draggable",
                        {
                          attrs: { sort: "false" },
                          on: { end: _vm.updateSorting },
                          model: {
                            value: _vm.menu.groups,
                            callback: function($$v) {
                              _vm.$set(_vm.menu, "groups", $$v)
                            },
                            expression: "menu.groups"
                          }
                        },
                        _vm._l(_vm.menu.groups, function(category, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("category", {
                                key: category.id || index,
                                attrs: {
                                  name: category.label.en,
                                  items: category.items,
                                  meta: category.meta || {},
                                  active: _vm.checkActiveCategory(index),
                                  menuid: _vm.value.id || _vm.value.label.en,
                                  properties: category.is,
                                  categories: _vm.menu.groups.map(function(e) {
                                    return e.label.en
                                  }),
                                  sort: "false"
                                },
                                on: {
                                  "update:name": function($event) {
                                    return _vm.$set(
                                      category.label,
                                      "en",
                                      $event
                                    )
                                  },
                                  "category:disable": function($event) {
                                    return _vm.disableCategory(index, $event)
                                  },
                                  "category:hide": function($event) {
                                    return _vm.hideCategory(index, $event)
                                  },
                                  "category:delete": function($event) {
                                    return _vm.deleteCategory(index)
                                  },
                                  "category:duplicate": function($event) {
                                    return _vm.duplicateCategory(index)
                                  },
                                  "category:setSortNumber": function($event) {
                                    return _vm.setSortNumber(index, $event)
                                  },
                                  setCategory: function($event) {
                                    return _vm.$emit("setView", {
                                      category: index,
                                      menu: _vm.value.id || _vm.value.label.en
                                    })
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("loading-wrapper", {
                    attrs: { loading: _vm.isDuplicating, size: 30, mt: "2vh" }
                  })
                ],
                1
              ),
              _vm.menu.groups && _vm.menu.groups.length === 0
                ? _c("list-item", { attrs: { title: "No categories found" } })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("menu-name-dialog", {
        attrs: {
          open: _vm.openMenuDialog,
          menu: _vm.menu,
          parentMenu: _vm.parentMenu
        },
        on: {
          "update:open": function($event) {
            _vm.openMenuDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }