<template>
  <tr class="clickable">
    <td nowrap>
      <v-checkbox
        @click.native.stop
        :false-value="false"
        :true-value="true"
        hide-details
        v-model="itemSelectedProxy"
        :disabled="readOnlyMode"
      ></v-checkbox>
    </td>
    <td>
      <v-layout align-left>
        <v-flex v-if="!showChitEditTextBoxes">{{
          (item.meta && item.meta.sort_number) || ''
        }}</v-flex>
        <v-flex class="sort_number" v-if="showChitEditTextBoxes">
          <v-text-field
            type="number"
            min="0"
            v-model.number="sortNumberProxy"
            :rules="sortNumberRules"
            v-on:keydown.enter.stop.prevent="() => {}"
            :disabled="isDisabledForPLU"
            @click.native.stop
          />
        </v-flex>
        <v-spacer />
      </v-layout>
    </td>
    <td nowrap>
      <v-layout align-center>
        <v-flex>{{ item.label.en }}</v-flex>
        <v-spacer />
      </v-layout>
    </td>
    <td v-if="isLocal" nowrap>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-checkbox
              v-on:change="$emit('item:groupaction')"
              v-model="itemProxy.is.hidden"
              hide-details
              on-icon="mdi-eye-off"
              off-icon="mdi-eye"
              :color="hiddenColor"
              @click.native.stop
              :false-value="false"
              :true-value="true"
              :disabled="readOnlyMode || isDisabledForPLU"
              tabindex="-1"
            />
          </div>
        </template>
        <span v-if="isDisabledForPLU">Requires PLU</span>
        <span v-else>
          <span v-if="itemProxy.is.hidden">Hidden in App</span>
          <span v-else>Display in App</span>
        </span>
      </v-tooltip>
    </td>
    <td nowrap v-else>
      <span v-if="itemProxy.is">
        <v-checkbox
          v-model="itemProxy.is.disabled"
          hide-details
          color="secondary"
          @click.native.stop
          :false-value="true"
          :true-value="false"
          :disabled="readOnlyMode"
          tabindex="-1"
        />
      </span>
    </td>
    <td nowrap>{{ item.price.amount }}</td>
    <td nowrap v-if="showPluField">{{ pluValue }}</td>
    <td nowrap>{{ barcodeValue }}</td>
    <td nowrap>
      <span
        v-if="item.nutrition && item.nutrition.calories && item.nutrition.calories.amount >= 0"
        >{{ item.nutrition.calories.amount }}</span
      >
    </td>
    <!-- MEQ checkbox -->
    <td @click.stop v-if="isLocal && showMEQField" nowrap>
      <v-checkbox
        v-model="meqEligibleProxy"
        @click.native.stop
        :false-value="false"
        :true-value="true"
        :disabled="readOnlyMode"
        color="secondary"
        style="padding-left: 15%"
        hide-details
      />
    </td>
    <td @click.stop v-if="isLocal" nowrap>
      <v-checkbox
        v-model="outOfStockProxy"
        @click.native.stop
        :false-value="true"
        :true-value="false"
        :disabled="readOnlyMode || isDisabledForPLU"
        color="secondary"
        style="padding-left: 15%"
        hide-details
      />
    </td>
    <td @click.stop v-if="showPromoExemptField" nowrap>
      <span>
        <v-switch
          inset
          v-model="promoExemptProxy"
          hide-details
          @click.native.stop
          :false-value="false"
          :true-value="true"
          style="padding-left: 15%"
          color="green"
          v-bind:class="{ switchenabled: !promoExemptProxy }"
          :disabled="readOnlyMode || isSiteOperator"
        />
        <div style="padding-left: 25px">{{ promoExemptProxy ? 'Yes' : 'No' }}</div>
      </span>
    </td>
    <td v-else @click.stop nowrap>
      <v-btn
        small
        flat
        icon
        :disabled="readOnlyMode"
        @click.stop="$emit('item:copy')"
        v-if="!isLocal"
        tabindex="-1"
      >
        <v-icon small>mdi-content-copy</v-icon>
      </v-btn>
      <v-btn
        small
        flat
        icon
        :disabled="readOnlyMode"
        @click.stop="$emit('item:delete')"
        v-if="!isLocal"
        tabindex="-1"
      >
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapGetters } from 'vuex';
import rules from '@/rules';

export default {
  props: {
    item: Object,
    showChitEditTextBoxes: Boolean,
    selectedItems: Object,
  },
  data: () => ({
    sortNumberRules: [rules.min(1)],
  }),
  computed: {
    ...mapState('menus', ['showPluField', 'showPromoExemptField', 'showMEQField', 'readOnlyMode']),
    ...mapGetters('adminPanel', ['isSiteOperator']),
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('menus', ['isLocal']),
    itemProxy: {
      get() {
        return this.item;
      },
      set(v) {
        this.$emit('update:item', v);
      },
    },
    outOfStockProxy: {
      get() {
        if (this.itemProxy.is && this.itemProxy.is.out_of_stock) {
          return this.itemProxy.is.out_of_stock;
        }
        return false;
      },
      set(v) {
        this.$set(this.itemProxy.is, 'out_of_stock', v);
      },
    },
    meqEligibleProxy: {
      get() {
        return (this.itemProxy.is && this.itemProxy.is.meq_eligible) || false;
      },
      set(v) {
        this.$set(this.itemProxy.is, 'meq_eligible', v);
      },
    },
    promoExemptProxy: {
      get() {
        return (
          Array.isArray(this.itemProxy.amount_off_exclusions) &&
          this.itemProxy.amount_off_exclusions.includes('promo')
        );
      },
      set(v) {
        const cloneItem = cloneDeep(this.itemProxy);
        if (!Array.isArray(cloneItem.amount_off_exclusions)) {
          cloneItem.amount_off_exclusions = [];
        }
        if (v === true && !cloneItem.amount_off_exclusions.find((e) => e === 'promo')) {
          cloneItem.amount_off_exclusions.push('promo');
        }
        if (v === false) {
          cloneItem.amount_off_exclusions = cloneItem.amount_off_exclusions.filter(
            (e) => e !== 'promo',
          );
        }
        if (Array.isArray(cloneItem.options)) {
          cloneItem.options.forEach((modifierGroup) => {
            if (Array.isArray(modifierGroup.items)) {
              modifierGroup.items.forEach((modifier) => {
                if (!Array.isArray(modifier.amount_off_exclusions)) {
                  modifier.amount_off_exclusions = [];
                }
                if (v === true && !modifier.amount_off_exclusions.find((e) => e === 'promo')) {
                  modifier.amount_off_exclusions.push('promo');
                }
                if (v === false) {
                  modifier.amount_off_exclusions = modifier.amount_off_exclusions.filter(
                    (e) => e !== 'promo',
                  );
                }
              });
            }
          });
        }
        this.$set(this.itemProxy, 'amount_off_exclusions', cloneItem.amount_off_exclusions);
        this.$set(this.itemProxy, 'options', cloneItem.options);
      },
    },
    hiddenColor() {
      if (this.isDisabledForPLU) return 'gray';
      if (this.itemProxy.is.hidden) {
        return 'red';
      }
      return 'green';
    },
    isDisabledForPLU() {
      if (!this.isSiteOperator || (this.isSiteOperator && !this.showPluField)) return false;
      if (
        this.showPluField &&
        this.item.meta &&
        this.item.meta.plu !== undefined &&
        this.item.meta.plu !== null &&
        this.item.meta.plu.toString().length > 0
      ) {
        return false;
      }
      return true;
    },
    sortNumberProxy: {
      get() {
        return this.itemProxy.meta.sort_number;
      },
      set(value) {
        this.$set(this.itemProxy.meta, 'sort_number', parseInt(value, 10) || undefined);
      },
    },
    itemSelectedProxy: {
      get() {
        return !!this.selectedItems[this.item.id] || false;
      },
      set(markSelected) {
        const found = this.selectedItems[this.item.id];
        if (markSelected && !found) {
          const newObj = { ...this.selectedItems };
          newObj[this.item.id] = this.item;
          this.$emit('update:selectedItems', newObj);
        }
        if (!markSelected && found) {
          const newObj = { ...this.selectedItems };
          delete newObj[this.item.id];
          this.$emit('update:selectedItems', newObj);
        }
      },
    },
    pluValue() {
      return (this.item.meta && this.item.meta.plu) || '';
    },
    barcodeValue() {
      return (
        (this.item.meta &&
          Array.isArray(this.item.meta.barcodes) &&
          this.item.meta.barcodes.join(',')) ||
        ''
      );
    },
  },
};
</script>

<style>
.v-icon .mdi .mdi-eye .theme--light {
  color: green;
}
.flex.sort_number {
  max-width: 11rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1rem;
  margin-left: auto;
}
</style>
